import { translate } from '../../../global/translation';
import { SpeedType, type Speed } from '../../../global/types';
import colors from '../../../theme/colors';

const optimalSpeedLimit = 8;
export const correctSpeedLimitColor = colors.success[500];

const isSpeedThroughWater = (speed: Speed): boolean => speed && speed.type === SpeedType.SpeedThroughWater;

const isSpeedMissing = (speed: Speed): boolean => !speed?.value || speed.value === 0;

const isAboveSpeedLimit = (speed: Speed): boolean => {
  return speed.value > optimalSpeedLimit;
};

export const formatSpeed = (speed: Speed): string => (isSpeedMissing(speed) ? '0.0' : speed?.value.toFixed(1));

export const getSpeedText = (speed: Speed): string =>
  isSpeedThroughWater(speed) ? translate('STW') : translate('SOG');

export const getTextForSpeedToolTip = (speed: Speed): string => {
  if (isSpeedMissing(speed)) {
    return translate('SPEED_LOG_MISSING');
  }
  if (isSpeedThroughWater(speed)) {
    return translate('SPEED_LOG_STW');
  }
  return translate('SPEED_LOG_DEFAULT');
};

export const getSpeedColor = (speed: Speed): string => {
  if (isAboveSpeedLimit(speed)) {
    if (speed.value < 9) {
      return colors.warning[400];
    }
    if (speed.value < 10) {
      return colors.error[500];
    }
    return colors.error[600];
  }

  return correctSpeedLimitColor;
};
