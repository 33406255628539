import { type AccountInfo, type IPublicClientApplication } from '@azure/msal-browser';
import { first } from 'lodash';
import { scopes } from '../global/utils/authUtils';
import { CONFIG_CODES } from '../global/types';
import { getConfig } from '../global/utils/getConfig';
import type STATUS_CODES from '../global/statusCodes';

export class ApiClient {
  private readonly apiHost: string = getConfig(CONFIG_CODES.REACT_APP_API_HOST);

  private account: AccountInfo | null = null;

  constructor(private readonly msalInstance: IPublicClientApplication) {}

  static createInstance(msalInstance: IPublicClientApplication): ApiClient {
    return new ApiClient(msalInstance);
  }

  static createAuthenticatedInstance(msalInstance: IPublicClientApplication): ApiClient {
    const firstActiveAccount = first(msalInstance.getAllAccounts());

    return new ApiClient(msalInstance).setAccount(firstActiveAccount);
  }

  public setAccount(account: AccountInfo): this {
    this.account = account;
    return this;
  }

  public getAccount(): AccountInfo | null {
    return this.account;
  }

  // eslint-disable-next-line class-methods-use-this
  public urlWithGetParams(url: string, params: object): string {
    return Object.entries(params).reduce(
      (prev, next, index) => `${prev}${index ? '&' : ''}${next[0]}=${next[1]}`,
      `${url}?`
    );
  }

  public async call<T = any>(endpoint: string, options: RequestInit = {}): Promise<{ data: T; status: STATUS_CODES }> {
    const headers: HeadersInit = {
      accept: 'application/json',
      ...(options?.headers ?? {}),
    };

    if (this.account) {
      const token = await this.acquireAccessToken();
      // eslint-disable-next-line @typescript-eslint/dot-notation
      headers['Authorization'] = `Bearer ${token}`;
    }

    // Strip leading slashes from endpoint variable
    endpoint = endpoint.replace(/^\/+/, '');

    const response = await fetch(`${this.apiHost}/${endpoint}`, {
      ...options,
      headers,
    });
    const data: T | null = await this.getResponseData<T>(response);

    return {
      data,
      status: response.status,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  private async getResponseData<T>(response: Response): Promise<T | null> {
    if (response.status === 204 || response.status === 404) {
      return null;
    }
    return response.json();
  }

  private async acquireAccessToken(): Promise<string> {
    if (!this.account) {
      throw new Error('No Account is Authenticated with MSAL');
    }

    const response = await this.msalInstance.acquireTokenSilent({
      scopes,
      account: this.account,
    });

    return response?.accessToken;
  }
}
