import { useNavigate } from 'react-router-dom';
import { useDebouncing } from './useDebouncing';
import { type NavigationParams } from '../global/types';
import { Timestamp } from '../pages/Playback/utils/timestamp';

export const useShallowNavigate = (): ((params: NavigationParams) => void) => {
  const navigate = useNavigate();

  const buildQueryString = (params: NavigationParams): string => {
    const queryStringParams = new URLSearchParams();

    if (params.mmsi) {
      queryStringParams.append('mmsi', params.mmsi);
    }

    if (params.timestamp?.isValid() ?? false) {
      queryStringParams.append('timestamp', params.timestamp.toSeconds().toString());
    }

    const queryString = queryStringParams.toString();

    return queryString ? `?${queryString}` : '';
  };

  const shouldChangeUrl = (url: string): boolean => {
    return url !== window.location.href;
  };

  const navigateDebounced = useDebouncing((url: string) => {
    navigate(url, { replace: true });
  }, Timestamp.fromMilliseconds(500));

  return (params: NavigationParams): void => {
    if (!params?.port) {
      return;
    }

    const url = `/${params.port}${buildQueryString(params)}`;

    if (!shouldChangeUrl(url)) {
      return;
    }

    navigateDebounced(url);
  };
};
