import { Grid, GridItem, Text, IconButton } from '@chakra-ui/react';
import { translate } from '../../../../../../global/translation';
import { ReactComponent as CollapseIcon } from '../../../../../../static/svg/Collapse.svg';

interface Props {
  iconAction: () => void;
}

const QuickAccessHeaderDefault: React.FC<Props> = ({ iconAction }) => {
  return (
    <Grid templateColumns="1fr 1fr">
      <GridItem colSpan={1}>
        <Text as="b" className="quick-access-panel-header">
          {translate('QUICK_ACCESS')}
        </Text>
      </GridItem>
      <GridItem colSpan={1} className="icon-collapse">
        <IconButton
          className="icon-collapse-btn"
          variant="icon"
          onClick={iconAction}
          icon={<CollapseIcon />}
          aria-label={translate('COLLAPSE_ARIA_DESCRIPTION')}
        />
      </GridItem>
    </Grid>
  );
};

export default QuickAccessHeaderDefault;
