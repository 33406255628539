import moment, { type Moment, type DurationInputArg2 } from 'moment/moment';
import { type DateRange } from '../types';

export const getMomentsFromRange = (dateRange: DateRange, type: DurationInputArg2): Moment[] => {
  const { from, to } = dateRange;
  const fromMoment = moment(from);
  const toMoment = moment(to);
  const diff = toMoment.diff(fromMoment, type);
  const range = [] as Moment[];

  for (let i = 0; i <= diff; i++) {
    range.push(moment(from).add(i, type));
  }

  return range;
};
