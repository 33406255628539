const translations = {
  SIGN_IN: 'Sign in',
  PORT_MONITOR: 'Port Monitor',
  JOB: 'Job',
  TUGS: 'Tugs',
  TUG: 'Tug',
  TRIP: 'Trip',
  PORT: 'Port',
  MOBILISATION: 'Mobilisation',
  DEMOBILISATION: 'Demobilisation',
  TRIP_DATE: 'Local Date & Time',
  TRIP_TYPE: 'Trip Type',
  TRIP_FUEL_EFFICIENCY: 'Eco Rating',
  TRIP_SPEED_EFFICIENCY: 'Speed Analysis',
  TRIP_ASSISTED_VESSEL: 'Assisted Vessel',
  TRIP_ACTIVITY_TIMELINE: 'Activity Timeline',
  EXPAND_ALL: 'Expand All',
  KNOTS_SHORT: 'Kn',
  REPLAY: 'Replay',
  OPERATION: 'Operation',
  MMSI: 'MMSI',
  HEADING: 'Heading',
  LIVE_VIEW: 'Live View',
  PLAYBACK: 'Playback',
  TRIP_TYPE_ALL: 'All trip types',
  BERTHING: 'Berthing',
  REPOSITIONING: 'Repositioning',
  SAILING: 'Sailing',
  SHIFTING: 'Shifting',
  RESTING: 'Resting',
  UNCERTAIN: 'Uncertain job',
  DETAILED_VIEW: 'Detailed View',
  LAST_POSITION_RECEIVED: 'Last Position Received',
  TAP_VESSEL_HINT: 'Tap a vessel to view its information',
  PLACEHOLDER_VESSEL_NAME: 'Search for vessels',
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  OLDER: 'Older',
  SIGNAL_LOST: 'Signal Lost',
  UNCERTAIN_ACTIVITY: 'Uncertain',
  REPOSITION_ACTIVITY: 'Reposition',
  SAILING_ACTIVITY: 'Sailing',
  SHIFTING_ACTIVITY: 'Shifting',
  BERTHING_ACTIVITY: 'Berthing',
  DEMOBILIZATION_ACTIVITY: 'Demobilization',
  MOBILIZATION_ACTIVITY: 'Mobilization',
  REDEPLOYMENT_ACTIVITY: 'Redeployment',
  RESTING_ACTIVITY: 'Resting',
  WAITING_ACTIVITY: 'Waiting',
  LOADING_ACTIVITY: 'Loading',
  RESET_BTN: 'Reset Filters',
  NO_TRIPS: 'No trips found',
  NO_MATCH_ON_SEARCH: 'Your search did not match any trips. Please try again with a different query.',
  START: 'Start',
  END: 'End',
  STW: 'STW',
  SOG: 'SOG',
  UNKNOWN: 'Unknown',
  ALL_TRIPS: 'All trips',
  SVITZER: 'Svitzer',
  OTHER: 'Other',
  HEADING_FRONTPAGE: 'Welcome to Port Monitor',
  SUB_HEADING_FRONTPAGE: 'Here is an overview of past trips & jobs that you can replay.',
  VESSEL_NAME_SPEED_MAP_LABEL: '{0}\n{1} Kn',
  SPEED_SWITCH: 'Speed switch',

  // Tooltips
  SPEED_LOG_STW: "The vessel's speed through water",
  SPEED_LOG_DEFAULT: "The vessel's speed over ground",
  SPEED_LOG_MISSING: 'We have not yet calculated speed through water for this vessel',
  TOOLTIP_STW: 'Speed through water',
  TOOLTIP_SOG: 'Speed over ground',
  TOOLTIP_LIVE_VIEW_BUTTON_DISABLED: 'Select an operation in order to see the live view',
  TOOLTIP_LIVE_VIEW_FOR_OPERATION_NOT_AVAILABLE: 'Live view is currently not available for selected operation',
  TIDAL_SET_AND_RATE: 'Tidal Set & Rate',
  JUST_NOW: 'Just now',
  MINUTES_AGO: 'm ago',
  TOOLTIP_DIRECTION_SPEED: '{0}° x {1} Kn',
  REPLAY_ASSISTED_VESSEL: 'Replay assisted vessel',
  REPLAY_TUG: 'Replay tug',
  PLAYBACK_UNAVAILABLE: 'Playback unavailable',
  ECO_RATING: 'Eco Rating',
  TARGET_FUEL_BURN: 'Target Fuel Burn',
  TARGET_FUEL_BURN_VALUE: '{0}L',
  ESTIMATED_FUEL_BURN: 'Estimated Fuel Burn',
  ESTIMATED_FUEL_BURN_VALUE: '~{0}L',
  SPEED_ANALYSIS: 'Speed Analysis',
  TRANSIT_BELOW_THRESHOLD: '{0}% transit time below 8 kn',
  PROPER_SPEED_RANGES: '1-8 kn',
  ABOVE_THRESHOLD: '8+ kn',
  UNAVAILABLE: 'Unavailable',
  TRIP_REQUESTED_TIME: 'Trip Requested Time',
  CREW_LOGGED_ACTIVITY: 'Crew Logged: {0}',
  ECO_EFFICIENCY_UNAVAILABLE_HEADING: 'Missing analysis',
  ECO_EFFICIENCY_UNAVAILABLE_JOB_TEXT:
    "This tug job wasn't detected by our system, so we cannot provide an eco rating. You can still replay the job.",
  ECO_EFFICIENCY_UNAVAILABLE_TRIP_TEXT:
    'This trip contains one or more undetected jobs, so we cannot provide an eco rating. You can still replay the trip and the jobs.',
  NAUTICAL_CHART_HEADING: 'Provided by Open Sea Map',
  NAUTICAL_CHART_TEXT:
    'OpenSeaMap data is community-sourced; please verify with official charts for navigational safety',
  ESTIMATED_CO2_SURPLUS_TOOLTIP:
    'A very rough estimate of the surplus CO2 emissions based on a comparison to travelling at 8 knots.',
  TIME_AND_SPEED_TOOLTIP_TEXT: '{0}  ·  {1} Kn',
  TRIPS_HISTORY_TOGGLE: 'Toggle trips history',
  ECO_RATING_TOOLTIP_HEADER: 'What is the Eco Rating?',
  ECO_RATING_TOOLTIP_TEXT:
    'Measures fuel efficiency during mobilisation and demobilisation. The optimal speed is 8 knots; spending more time above this lowers the rating.',
  ECO_RATING_TOOLTIP_DISMISS: 'Dismiss',

  // Global error handler
  MODAL_GLOBAL_ERROR_HEADER: 'Unexpected error',
  MODAL_GLOBAL_ERROR_BODY: 'Please try and refresh the page',
  MODAL_GLOBAL_ERROR_BTN: 'Refresh',

  // Incorrect Information
  MODAL_BTN: 'See something wrong?',
  MODAL_HEADING: 'Tell us what went wrong',
  MODAL_SUB_HEADING: 'Select all that apply.',
  MODAL_TEXTAREA: 'Please describe your observation or issue in detail',
  MODAL_TEXTAREA_DESCRIPTION: 'Description',
  CONFIRM_HEADING: 'Thank you!',
  CONFIRM_BTN: 'Dismiss',
  THEME_1: 'Wrong vessel details',
  THEME_2: 'Job mix-up',
  THEME_3: 'Time errors',
  THEME_4: 'Data gaps',
  THEME_5: 'Speed Issues',
  THEME_6: 'Bugs',
  THEME_7: 'Other',

  // Share link
  MODAL_SHARE_HEADING_PLAYBACK: 'Share replay',
  MODAL_SHARE_HEADING_LIVEVIEW: 'Share live view',
  MODAL_SHARE_SUB_HEADING_PLAYBACK:
    'Want to share a replay with a colleague? Copy the link below and send it directly to them.',
  MODAL_SHARE_SUB_HEADING_LIVEVIEW:
    'Want to share a live view with a colleague? Copy the link below and send it directly to them.',
  MODAL_SHARE_BODY_LABEL: 'Share link',
  MODAL_SHARE_BODY_HELPERTEXT: 'Please note external parties cannot access this link.',
  MODAL_SHARE_BTN: 'Copy link',
  MODAL_SHARE_RADIO01: 'Share from job start time',
  MODAL_SHARE_RADIO02: 'Share from selected time ',
  MODAL_SHARE_RADIO_LIVEVIEW: 'Share live view',
  TOASTER_TITLE: 'Link copied to clipboard',
  MODAL_SHARE_BTN_ARIA: 'Share link',

  // ConfirmFeedbackModal
  CONFIRMFEEDBACKCARD_HEADING: 'Thank you!',
  CONFIRMFEEDBACKCARD_TEXT:
    'We value your input and will actively incorporate your feedback into Port Monitor to enhance its features and user experience.',
  CONFIRMFEEDBACKCARD_BTN: 'Dismiss',

  // RateAppModal
  RATEAPPMODAL_HEADING: 'How likely are you to recommend Port Monitor to a colleague?',
  NOT_LIKELY: '0 - Not likely',
  VERY_LIKELY: '10 - Very Likely',

  // RateAppCommentModal
  RATEAPPCOMMENTMODAL_00_HEADING: 'We are sorry to hear that',
  RATEAPPCOMMENTMODAL_01_HEADING: 'Thank you!',
  RATEAPPCOMMENTMODAL_02_HEADING: 'Happy to hear!',

  RATEAPPCOMMENTMODAL_00_SUB_HEADING: 'What could we do to improve?',
  RATEAPPCOMMENTMODAL_01_SUB_HEADING: 'Is there anything we can do to improve?',
  RATEAPPCOMMENTMODAL_02_SUB_HEADING: 'What do you like the most about Port Monitor?',
  RATEAPPCOMMENTMODAL_TEXTAREA: 'Thoughts here...',
  RATEAPPCOMMENTMODAL_BTN: 'Submit',

  // Months
  JANUARY: 'January',
  JANUARY_SHORT: 'Jan',
  FEBRUARY: 'February',
  FEBRUARY_SHORT: 'Feb',
  MARCH: 'March',
  MARCH_SHORT: 'Mar',
  APRIL: 'April',
  APRIL_SHORT: 'Apr',
  MAY: 'May',
  MAY_SHORT: 'May',
  JUNE: 'June',
  JUNE_SHORT: 'Jun',
  JULY: 'July',
  JULY_SHORT: 'Jul',
  AUGUST: 'August',
  AUGUST_SHORT: 'Aug',
  SEPTEMBER: 'September',
  SEPTEMBER_SHORT: 'Sep',
  OCTOBER: 'October',
  OCTOBER_SHORT: 'Oct',
  NOVEMBER: 'November',
  NOVEMBER_SHORT: 'Nov',
  DECEMBER: 'December',
  DECEMBER_SHORT: 'Dec',

  // Map
  MAP_ERROR: 'Oops! Something went wrong!',
  MAP_ERROR_OCCURED: 'Please select another trip or try again later.',
  MAP_GO_BACK: 'Go back',
  MAP_FETCH_DATA: 'Fetching data...',
  MAP_NO_DATA: 'It appears there is no data for this period.',

  // Date picker
  DATE_PICKER_CLEAR: 'Clear',
  DATE_PICKER_YESTERDAY: 'Yesterday',
  DATE_PICKER_LAST7: 'Last 7 days',
  DATE_PICKER_LAST30: 'Last 30 days',
  DATE_PICKER_ALL_TIME: 'All time',
  DAY_ABBREVIATION: 'd',

  // Chart settings
  CHART_LAYERS: 'Chart Layers',
  CHART_SETTINGS: 'Chart settings',
  DEFAULT_LAYER: 'Default',
  SATELLITE_LAYER: 'Satellite',
  NAUTICAL_LAYER: 'Detailed',
  CHART_DETAILS: 'Chart Details',
  VESSEL_TRACKS: 'Vessel Tracks',
  VESSEL_TRACKS_DESC: 'Shows vessel tracks for the selected vessel',
  VESSEL_NAMES: 'Vessel Names',
  VESSEL_NAMES_DESC: 'Always show vessel name labels on the chart',
  QUIVER_PLOT: 'Tidal data',
  QUIVER_PLOT_DESC: 'Shows a tidal quiver plot on the chart  \n',
  NAUTICAL_PLOT: 'Nautical',
  NAUTICAL_PLOT_DESC:
    'Show nautical features overlaid on chart. CAUTION: OpenSeaMap data is community sourced. Please verify with official charts for navigational safety',

  // Vessel search
  JUMP_TO: 'Jump to',
  NO_VESSEL_FOUND: 'No vessel found',
  TRY_SEARCH_ANOTHER_VESSEL: 'Try searching for a another vessel name or MMSI',
  START_TYPING: 'Start typing to get results',
  TRY_SEARCH_VESSEL: 'Try searching for a vessel name or MMSI',
  INPUT_PLACEHOLDER: 'Search for a vessel',

  // Quick access panel
  QUICK_ACCESS: 'Quick access',
  CLOSE: 'Close',
  BACK: 'Back',
  PICK_TIME: 'Pick a time',
  GO: 'Go',
  PICK_TIME_DESCRIPTION: 'Jump to any time at this location and replay events.',
  COLLAPSE_ARIA_DESCRIPTION: 'Collapse and expand',
  REPLAY_1H_AGO: 'Replay 1h ago',
  REPLAY_2H_AGO: 'Replay 2h ago',
  REPLAY_8H_AGO: 'Replay 8h ago',
  RECENTER_ARIA_DESCRIPTION: 'Recenter vessel',

  // Trips panel
  CLEAR_SEARCH: 'Clear search',
  CLEAR_ALL: 'Clear all',
  FILTERS: 'Filters',
  TRIPS_HISTORY: 'Trips History',
  NO_VESSEL: 'No vessel',

  // World view
  WORLD_VIEW: 'World View',
  SELECT_YOUR_OPERATION: 'Select your operation',

  // Bar Chart Date Picker
  CO2_SURPLUS: 'CO2 Surplus',
  WEEKLY_ECO_RATING: 'Weekly Eco Rating',
  ESTIMATED_CO2_SURPLUS: 'Est. Surplus CO2',
  TONNE_ABBREVIATION: 'T',
  DASH: '—',
  DAY_ECO_RATING: 'Day Eco Rating',
  FOURTEEN_DAY_ECO_RATING: '14-Day Eco Rating',
  PREVIOUS: 'Previous',
  NEXT: 'Next',

  // Scrubber
  PLAY: 'Play',
  PAUSE: 'Pause',
  VESSEL: 'Vessel',
  LOCAL_TIME: 'Local Time',
  MMSI_VALUE: 'MMSI: {0}',

  // Quiver plot
  INFORMATION_ABOUT_LOCATION: 'Information about this location',
  TIDAL_DIAMOND: 'Tidal Diamond',
  TIME_TO_HIGH_WATER: 'Time to High Water',
  TIME_TO_HIGH_WATER_VALUE: '{0}h',
};

export type TranslationKey = keyof typeof translations;

export const translate = (key: TranslationKey): string => {
  return translations[key];
};

export const translateAndFormat = (key: TranslationKey, ...values: string[]): string => {
  return FormatString(translations[key], ...values);
};

export function FormatString(str: string, ...values: string[]): string {
  for (let i = 0; i < values.length; i++) {
    str = str.replace(`{${i}}`, values[i]);
  }
  return str;
}
