import moment from 'moment/moment';
import { maxBy } from 'lodash';
import colors from '../../../../../theme/colors';
import { type DateRange } from '../../../../../global/types';
import { getMomentsFromRange } from '../../../../../global/utils/date-range-utils';
import {
  barChartDateFormat,
  barChartEcoZoneColorMap,
  co2SurplusRatioMultiplier,
  defaultCo2SurplusValue,
  defaultEcoRatingValue,
} from '../constants';
import { type BarChartData } from '../types';
import { type DailyEcoRating } from '../../../../Trips/types';
import { mapEcoScoreToEcoZone } from '../../../../Trips/utils/mapEcoScoreToEcoZone';

export const getEcoRatingColor = (value: number): string => {
  if (!value) {
    return colors.gray[600];
  }

  return barChartEcoZoneColorMap[mapEcoScoreToEcoZone(value)];
};

export const formatDateRange = (dateRange: DateRange): string =>
  `${moment.utc(dateRange.from).format(barChartDateFormat)}-${moment.utc(dateRange.to).format(barChartDateFormat)}`;

export const mapEcoRatingsToBarChartData = (ecoRatings: DailyEcoRating[]): BarChartData[] => {
  const maxCo2Surplus = maxBy(ecoRatings, e => e.estimatedCo2Surplus)?.estimatedCo2Surplus;
  return ecoRatings.map(x => {
    const isPastDate = moment.utc(x.day).isBefore(moment.utc(), 'day');
    const co2SurplusMaxRatio = x.estimatedCo2Surplus / maxCo2Surplus;

    return {
      id: x.day,
      ecoRating: isPastDate ? x.ecoRating || defaultEcoRatingValue : 0,
      estimatedCo2Surplus:
        isPastDate && x.estimatedCo2Surplus
          ? -co2SurplusMaxRatio * co2SurplusRatioMultiplier || defaultCo2SurplusValue
          : 0,
      day: x.day,
      isEmpty: !x.ecoRating,
    } as BarChartData;
  });
};

export const getPlaceholderBarChartData = (range: DateRange): DailyEcoRating[] => {
  const dates = getMomentsFromRange(range, 'day');
  return dates.map(day => {
    return { day: day.format(barChartDateFormat), ecoRating: null, estimatedCo2Surplus: null };
  });
};

export const getDateRangeFromDate = (date: Date, daysInRange: number): DateRange => {
  const to = (date ? moment.utc(date) : moment.utc().subtract(1, 'day')).endOf('day');

  const from = to
    .clone()
    .subtract(daysInRange - 1, 'days')
    .startOf('day');

  return {
    from: from.toDate(),
    to: to.toDate(),
  };
};
