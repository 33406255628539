import { useState, type FC, useMemo, useCallback } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  RadioGroup,
  Input,
  IconButton,
  Stack,
  Radio,
  FormControl,
  FormLabel,
  FormHelperText,
  Tooltip,
} from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react-use-disclosure';
import { useParams } from 'react-router';
import { ReactComponent as ShareGreenIcon } from '../../../../../../static/svg/ShareGreenIcon.svg';
import { ReactComponent as ShareIcon } from '../../../../../../static/svg/ShareIcon.svg';
import { type TranslationKey, translate } from '../../../../../../global/translation';
import { useToaster } from '../../../../../../hooks/useToaster';
import { type Timestamp } from '../../../../utils/timestamp';
import {
  ToasterVariant,
  Event,
  ShareLinkOperation,
  CopyLinkOperation,
  type Page,
} from '../../../../../../global/types';
import { getEventFactory } from '../../../../../../global/utils/get-event-factory';
import { useAppInsights } from '../../../../../../hooks/useAppInsights';
import { Mode } from '../../../../types';
import { buildUrl } from '../../../../../../global/utils/navigation-utils';
import { useAppContext } from '../../../../../../global/components/AppContextProvider';

const DEFAULT_VALUE = 0;
const TIME_SPECIFIC_VALUE = 1;
const TIME_FORMAT = 'h:mm A';

interface Props {
  timestamp: Timestamp;
  startTimestamp: Timestamp;
  targetVesselMmsi: string;
  page: Page;
  timezone: string;
}

const ShareLink: FC<Props> = ({ timestamp, startTimestamp, targetVesselMmsi, page, timezone }): JSX.Element => {
  const { mode } = useAppContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [radioBtnValue, setRadioBtn] = useState(DEFAULT_VALUE);
  const { port } = useParams();
  const appInsights = useAppInsights();

  const showToast = useToaster();
  const shareTime = useMemo(
    () => (mode !== Mode.Liveview ? timestamp.toLocationDateTimeOffset(timezone).format(TIME_FORMAT) : null),
    [timestamp, timezone, mode]
  );

  const url = useMemo(() => {
    if (mode === Mode.Liveview) {
      return encodeURI(
        `${window.location.origin}${buildUrl({
          port,
          mmsi: targetVesselMmsi,
        })}`
      );
    }

    if (radioBtnValue === TIME_SPECIFIC_VALUE) {
      return encodeURI(
        `${window.location.origin}${buildUrl({
          port,
          mmsi: targetVesselMmsi,
          timestamp,
        })}`
      );
    }

    if (!startTimestamp) {
      return '';
    }

    return encodeURI(
      `${window.location.origin}${buildUrl({
        port,
        mmsi: targetVesselMmsi,
        timestamp: startTimestamp,
      })}`
    );
  }, [radioBtnValue, port, startTimestamp, targetVesselMmsi, timestamp]);

  const onOpenModal = useCallback((): void => {
    onOpen();
    appInsights.trackAnalyticsEvent(getEventFactory(Event.ShareLink)(page, ShareLinkOperation.OpenLink));
  }, [onOpen, appInsights]);

  const onCloseModal = useCallback((): void => {
    onClose();
    appInsights.trackAnalyticsEvent(getEventFactory(Event.ShareLink)(page, ShareLinkOperation.CloseLink));
  }, [onClose, appInsights]);

  const onCopyLink = useCallback((): void => {
    void navigator.clipboard.writeText(url);
    onCloseModal();
    showToast({
      title: translate('TOASTER_TITLE'),
      variant: ToasterVariant.Success,
    });

    appInsights.trackAnalyticsEvent(
      getEventFactory(Event.CopyLink)(
        page,
        radioBtnValue === DEFAULT_VALUE ? CopyLinkOperation.CopyDefaultUrl : CopyLinkOperation.CopyTimeSpecificUrl,
        url
      )
    );
  }, [url, onCloseModal, showToast, appInsights, radioBtnValue]);

  const translateKeys = useMemo<{ heading: TranslationKey; subheading: TranslationKey }>(() => {
    return {
      heading: mode === Mode.Liveview ? 'MODAL_SHARE_HEADING_LIVEVIEW' : 'MODAL_SHARE_HEADING_PLAYBACK',
      subheading: mode === Mode.Liveview ? 'MODAL_SHARE_SUB_HEADING_LIVEVIEW' : 'MODAL_SHARE_SUB_HEADING_PLAYBACK',
    };
  }, [mode]);

  return (
    <>
      <Tooltip placement="bottom-end" label={translate('MODAL_SHARE_BTN_ARIA')}>
        <IconButton
          variant="secondary"
          onClick={onOpenModal}
          icon={<ShareIcon />}
          aria-label={translate('MODAL_SHARE_BTN_ARIA')}
        />
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ShareGreenIcon className="modal-icon" />
          <ModalCloseButton className="modal-close-btn" />
          <ModalHeader paddingBottom={0}>{translate(translateKeys.heading)}</ModalHeader>
          <ModalBody>
            <Text>{translate(translateKeys.subheading)}</Text>
            <br />
            <FormControl>
              <FormLabel>{translate('MODAL_SHARE_BODY_LABEL')}</FormLabel>
              <Input value={url} isReadOnly />
              <FormHelperText>{translate('MODAL_SHARE_BODY_HELPERTEXT')}</FormHelperText>
            </FormControl>
            {mode !== Mode.Liveview && (
              <>
                <br />
                <RadioGroup defaultValue={DEFAULT_VALUE.toString()}>
                  <Stack>
                    <Radio
                      onChange={() => {
                        setRadioBtn(DEFAULT_VALUE);
                      }}
                      value={DEFAULT_VALUE.toString()}
                    >
                      {translate('MODAL_SHARE_RADIO01')}
                    </Radio>
                    <Radio
                      onChange={() => {
                        setRadioBtn(TIME_SPECIFIC_VALUE);
                      }}
                      value={TIME_SPECIFIC_VALUE.toString()}
                    >
                      {translate('MODAL_SHARE_RADIO02')}
                      <Text as="span" fontWeight="bold">
                        {shareTime}
                      </Text>
                    </Radio>
                  </Stack>
                </RadioGroup>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onCopyLink}>{translate('MODAL_SHARE_BTN')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ShareLink;
