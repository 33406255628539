import { Layer, Source } from 'react-map-gl';
import { useMemo } from 'react';
import { shipClusterCountLayer, shipClusterLayer, unclusteredShipLayer } from '../layers-config';
import { getShipClusters } from '../utils/layer-utils';
import { layerSources, vesselsClusterMaxZoomLevel } from '../constants';
import { type VesselPosition, type VesselViewModel } from '../../../types';
import { type MmsiKey } from '../../../../../global/types';

interface Props {
  zoomLevel: number;
  vesselPositions: VesselPosition[];
  vesselData: Record<MmsiKey, VesselViewModel>;
}

const ClusterLayer = ({ vesselPositions, vesselData, zoomLevel }: Props): JSX.Element => {
  const features = useMemo(() => {
    return getShipClusters(vesselPositions, vesselData, zoomLevel, vesselsClusterMaxZoomLevel);
  }, [vesselPositions, vesselData, zoomLevel]);

  return (
    <Source
      id={layerSources.vesselClusters}
      type="geojson"
      data={{
        type: 'FeatureCollection',
        features,
      }}
      generateId
      cluster
      clusterMaxZoom={vesselsClusterMaxZoomLevel}
      clusterRadius={50}
    >
      <Layer {...shipClusterLayer} />
      <Layer {...shipClusterCountLayer} />
      <Layer {...unclusteredShipLayer} />
    </Source>
  );
};

export default ClusterLayer;
