import {
  Text,
  Textarea,
  Button,
  Card,
  CardHeader,
  CardBody,
  Heading,
  CloseButton,
  CardFooter,
  Flex,
  Box,
  Spacer,
} from '@chakra-ui/react';
import { useState, type FC } from 'react';
import { getRateText } from './utils/rate-app-utils';
import { translate } from '../translation';

interface RateAppCommentCardProps {
  isOpen: boolean;
  rate: number;
  onClose: () => void;
  onSubmit: (comment: string) => void;
}

const RateAppCommentCard: FC<RateAppCommentCardProps> = ({ isOpen, rate, onClose, onSubmit }) => {
  const [textArea, setTextArea] = useState('');

  const onCloseModal = (): void => {
    setTextArea('');
    onClose();
  };

  const onSubmitModal = (): void => {
    onSubmit(textArea);
    setTextArea('');
  };

  return (
    isOpen && (
      <div className="rate-app-container rate-app-map-container">
        <Card className="rate-app-card ">
          <CardHeader className="rate-app-card-header">
            <Flex direction="row" width="100%" alignItems="center">
              <Box width="10%" />
              <Box width="80%">
                <Heading size="md">{getRateText(rate).header}</Heading>
              </Box>
              <Spacer />
              <Box width="10%">
                <CloseButton size="md" onClick={onCloseModal} />
              </Box>
            </Flex>
          </CardHeader>
          <CardBody>
            <Text float="left" className="rate-app-comment-modal-sub-header">
              {getRateText(rate).subHeader}
            </Text>
            <Textarea
              placeholder={translate('RATEAPPCOMMENTMODAL_TEXTAREA')}
              value={textArea}
              onChange={e => {
                setTextArea(e.target.value);
              }}
              aria-label="description"
              size="md"
            />
          </CardBody>
          <CardFooter>
            <Button variant="primary" isDisabled={textArea.length <= 0} onClick={onSubmitModal} width="100%">
              {translate('RATEAPPCOMMENTMODAL_BTN')}
            </Button>
          </CardFooter>
        </Card>
      </div>
    )
  );
};

export default RateAppCommentCard;
