import { Button } from '@chakra-ui/react';
import { useState } from 'react';
import cx from 'classnames';

interface ThemeButtonProps {
  text: string;
  onButtonClick: (text: string) => void;
  isModalOpen: boolean;
}
export const ThemeButton: React.FC<ThemeButtonProps> = ({ text, onButtonClick, isModalOpen }) => {
  const [isSelected, setIsSelected] = useState(false);

  const handleClick = (): void => {
    setIsSelected(!isSelected);
    onButtonClick(text);
  };

  const buttonClass = cx('theme-button', {
    'theme-button-selected': isSelected && isModalOpen,
  });

  return (
    <Button className={buttonClass} onClick={handleClick}>
      {text}
    </Button>
  );
};
