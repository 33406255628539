import { type FC } from 'react';
import { IconButton } from '@chakra-ui/react';
import { ReactComponent as MenuIcon } from '../../../static/svg/Menu.svg';
import { translate } from '../../../global/translation';

interface Props {
  toggleTripsPanel: () => void;
}

const TripsHistoryToggle: FC<Props> = ({ toggleTripsPanel }) => {
  return (
    <IconButton
      left="8px"
      variant="secondary"
      className="trips-history-toggle"
      onClick={toggleTripsPanel}
      icon={<MenuIcon />}
      aria-label={translate('TRIPS_HISTORY_TOGGLE')}
    />
  );
};

export default TripsHistoryToggle;
