import { useEffect, useState } from 'react';
import { type EcoRatingTooltip } from '../types';
import localStorageService from '../../../global/services/localStorageService';
import { Timestamp } from '../utils/timestamp';

interface TooltipState {
  isOpen: boolean;
  isDismissible: boolean;
}

export const ecoRatingTooltipDismissedKey = 'ecoRatingTooltip_dismissed';

export const useEcoRatingTooltip = (isTripsPanelOpen: boolean): EcoRatingTooltip => {
  const userDismissed = localStorageService.getItem<boolean>(ecoRatingTooltipDismissedKey);
  const [state, setState] = useState<TooltipState>({
    isOpen: false,
    isDismissible: !userDismissed,
  });

  const handleOpenDismissible = (): void => {
    if (!isTripsPanelOpen) {
      return;
    }

    setState({ isDismissible: true, isOpen: true });
  };

  const handleClose = (): void => {
    setState(state => {
      return { ...state, isOpen: false };
    });
  };

  const handleHover = (): void => {
    if (!isTripsPanelOpen || state.isDismissible) {
      return;
    }

    setState({ isDismissible: false, isOpen: true });
  };

  const handleHoverOut = (): void => {
    if (!isTripsPanelOpen || state.isDismissible) {
      return;
    }

    setState({ isDismissible: false, isOpen: false });
  };

  const handleTap = (): void => {
    if (!isTripsPanelOpen || state.isDismissible) {
      return;
    }

    setState(state => {
      return { isDismissible: false, isOpen: !state.isOpen };
    });
  };

  const handleDismiss = (): void => {
    setState({ isDismissible: false, isOpen: false });
    localStorageService.setItem(ecoRatingTooltipDismissedKey, true);
  };

  useEffect(() => {
    if (!isTripsPanelOpen) {
      return;
    }

    let timeoutId: NodeJS.Timeout;
    if (!userDismissed && !timeoutId) {
      timeoutId = setTimeout(() => {
        handleOpenDismissible();
      }, Timestamp.fromSeconds(2).toMilliseconds());
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [userDismissed, isTripsPanelOpen]);

  return {
    isOpen: state.isOpen,
    isDismissible: state.isDismissible,
    onHover: handleHover,
    onHoverOut: handleHoverOut,
    onTap: handleTap,
    onDismiss: handleDismiss,
    onClose: handleClose,
  };
};
