import { useEffect } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  ModalCloseButton,
} from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';
import { translate } from '../translation';
import { useAppInsights } from '../../hooks/useAppInsights';
import { getEventFactory } from '../utils/get-event-factory';
import { Event, type Page } from '../types';
import type React from 'react';

interface Props {
  errorMessage: string;
  page: Page;
}

const GlobalExceptionHandlerModal: React.FC<Props> = ({ errorMessage, page }) => {
  const appInsights = useAppInsights();

  useEffect(() => {
    if (!appInsights) {
      return;
    }

    const event = getEventFactory(Event.Exception)(page, window.location.href, errorMessage);
    appInsights.trackAnalyticsEvent(event);
  }, [appInsights, errorMessage]);

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const handleReload = (): void => {
    window.location.reload();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="global-error-handler-modal">
        <WarningIcon w={8} h={8} color="red.500" className="modal-icon" />
        <ModalCloseButton className="modal-close-button" />
        <ModalHeader>{translate('MODAL_GLOBAL_ERROR_HEADER')}</ModalHeader>
        <ModalBody>
          <Text className="text-md-regular">{translate('MODAL_GLOBAL_ERROR_BODY')}</Text>
        </ModalBody>
        <ModalFooter width="100%">
          <Button variant="primary" onClick={handleReload}>
            {translate('MODAL_GLOBAL_ERROR_BTN')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GlobalExceptionHandlerModal;
