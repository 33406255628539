import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import colors from '../colors';

const outline = defineStyle({
  background: colors.base.white,
  border: `1px solid ${colors.gray[500]}`,
  color: colors.gray[500],
  borderRadius: '8px',
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  _disabled: {
    background: colors.gray[50],
    opacity: 'unset',
  },
  _invalid: {
    border: `1px solid ${colors.error[300]}`,
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    borderColor: 'unset',
  },
});

export const textareaTheme = defineStyleConfig({
  variants: {
    outline,
  },
  defaultProps: {
    variant: 'outline',
  },
});
