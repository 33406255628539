import React, { type FC } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { InteractionType } from '@azure/msal-browser';
import { AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import SplashScreen from './SplashScreen';
import { useSettingsQuery } from './Playback/hooks/useSettingsQuery';
import { useGenericQuery } from './Trips/hooks/useGenericQuery';
import App from './Playback/App';
import { useRouteTracking } from '../hooks/useRouteTracking';
import { useAuthenticatedUserTelemetry } from '../hooks/useAuthenticatedUserTelemetry';
import { ActionType } from '../global/state/reducer';
import GlobalExceptionHandler from '../global/components/GlobalExceptionHandler';

const ProtectedRoutes: FC = () => {
  // This hook automatically triggers the Login Flow if the user is not authenticated
  useMsalAuthentication(InteractionType.Redirect);

  return (
    <AuthenticatedTemplate>
      <Outlet />
    </AuthenticatedTemplate>
  );
};

const RoutesComponent = (): JSX.Element => {
  useRouteTracking();
  useAuthenticatedUserTelemetry();
  useSettingsQuery();
  useGenericQuery({
    actionType: ActionType.SET_OPERATIONS,
    endpoint: `/api/operations/`,
    queryKey: 'usePortsMapCoordinates',
  });

  return (
    <GlobalExceptionHandler>
      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route
            path="/:port"
            element={
              <React.Suspense fallback={<SplashScreen />}>
                <App />
              </React.Suspense>
            }
          />
          <Route
            path="*"
            element={
              <React.Suspense fallback={<SplashScreen />}>
                <App />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </GlobalExceptionHandler>
  );
};

export default RoutesComponent;
