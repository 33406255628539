import { type FC, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { operationLayer } from '../layers-config';
import { layerSources } from '../constants';
import { type OperationViewModel } from '../types';
import { getOperationFeatures } from '../utils/layer-utils';

interface Props {
  operations: OperationViewModel[];
  currentOperation: string;
}

const OperationsLayer: FC<Props> = ({ operations, currentOperation }) => {
  const features = useMemo(() => getOperationFeatures(operations, currentOperation), [operations, currentOperation]);

  return (
    <Source
      id={layerSources.operations}
      type="geojson"
      data={{
        type: 'FeatureCollection',
        features,
      }}
    >
      <Layer {...operationLayer} />
    </Source>
  );
};

export default OperationsLayer;
