import { useEffect } from 'react';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { first } from 'lodash';
import { useFetchSecret } from './useFetchSecret';
import { InitializeTelemetryForAuthenticatedUser } from '../AppInsights';
import { secretNames } from '../global/constants';

export const useAuthenticatedUserTelemetry = (): void => {
  const { accounts } = useMsal();
  const account = useAccount(first(accounts));
  const isAuthenticated = useIsAuthenticated();
  const instrumentationKey = useFetchSecret(secretNames.appInsightsInstrumentationKey);

  useEffect(() => {
    if (isAuthenticated && account && instrumentationKey) {
      InitializeTelemetryForAuthenticatedUser(account, instrumentationKey);
    }
  }, [isAuthenticated, account, instrumentationKey]);
};
