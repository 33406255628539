import styles from '../global/global-color-styles.scss';

const colors: {
  base: {
    white: string;
    black: string;
  };
  gray: Record<string, string>;
  primary: Record<string, string>;
  error: Record<string, string>;
  warning: Record<string, string>;
  success: Record<string, string>;
  violet: Record<string, string>;
  blueDark: Record<string, string>;
  purple: Record<string, string>;
  yellow: Record<string, string>;
  pink: Record<string, string>;
  orangeDark: Record<string, string>;
} = {
  base: {
    white: styles.baseWhite,
    black: styles.baseBlack,
  },
  gray: {
    25: styles.gray025,
    50: styles.gray050,
    100: styles.gray100,
    200: styles.gray200,
    300: styles.gray300,
    400: styles.gray400,
    500: styles.gray500,
    600: styles.gray600,
    700: styles.gray700,
    800: styles.gray800,
    900: styles.gray900,
  },
  primary: {
    25: styles.primary025,
    50: styles.primary050,
    100: styles.primary100,
    200: styles.primary200,
    300: styles.primary300,
    400: styles.primary400,
    500: styles.primary500,
    600: styles.primary600,
    700: styles.primary700,
    800: styles.primary800,
    900: styles.primary900,
  },
  error: {
    25: styles.error025,
    50: styles.error050,
    100: styles.error100,
    200: styles.error200,
    300: styles.error300,
    400: styles.error400,
    500: styles.error500,
    600: styles.error600,
    700: styles.error700,
    800: styles.error800,
    900: styles.error900,
  },
  warning: {
    25: styles.warning025,
    50: styles.warning050,
    100: styles.warning100,
    200: styles.warning200,
    300: styles.warning300,
    400: styles.warning400,
    500: styles.warning500,
    600: styles.warning600,
    700: styles.warning700,
    800: styles.warning800,
    900: styles.warning900,
  },
  success: {
    25: styles.success025,
    50: styles.success050,
    100: styles.success100,
    200: styles.success200,
    300: styles.success300,
    400: styles.success400,
    500: styles.success500,
    600: styles.success600,
    700: styles.success700,
    800: styles.success800,
    900: styles.success900,
  },
  violet: {
    25: styles.violet025,
    50: styles.violet050,
    100: styles.violet100,
    200: styles.violet200,
    300: styles.violet300,
    400: styles.violet400,
    500: styles.violet500,
    600: styles.violet600,
    700: styles.violet700,
    800: styles.violet800,
    900: styles.violet900,
  },
  blueDark: {
    25: styles.blueDark025,
    50: styles.blueDark050,
    100: styles.blueDark100,
    200: styles.blueDark200,
    300: styles.blueDark300,
    400: styles.blueDark400,
    500: styles.blueDark500,
    600: styles.blueDark600,
    700: styles.blueDark700,
    800: styles.blueDark800,
    900: styles.blueDark900,
  },
  purple: {
    25: styles.purple025,
    50: styles.purple050,
    100: styles.purple100,
    200: styles.purple200,
    300: styles.purple300,
    400: styles.purple400,
    500: styles.purple500,
    600: styles.purple600,
    700: styles.purple700,
    800: styles.purple800,
    900: styles.purple900,
  },
  yellow: {
    25: styles.yellow025,
    50: styles.yellow050,
    100: styles.yellow100,
    200: styles.yellow200,
    300: styles.yellow300,
    400: styles.yellow400,
    500: styles.yellow500,
    600: styles.yellow600,
    700: styles.yellow700,
    800: styles.yellow800,
    900: styles.yellow900,
  },
  pink: {
    25: styles.pink025,
    50: styles.pink050,
    100: styles.pink100,
    200: styles.pink200,
    300: styles.pink300,
    400: styles.pink400,
    500: styles.pink500,
    600: styles.pink600,
    700: styles.pink700,
    800: styles.pink800,
    900: styles.pink900,
  },
  orangeDark: {
    25: styles.orangeDark025,
    50: styles.orangeDark050,
    100: styles.orangeDark100,
    200: styles.orangeDark200,
    300: styles.orangeDark300,
    400: styles.orangeDark400,
    500: styles.orangeDark500,
    600: styles.orangeDark600,
    700: styles.orangeDark700,
    800: styles.orangeDark800,
    900: styles.orangeDark900,
  },
};

export default colors;
