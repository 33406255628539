import { useEffect, useMemo, useState } from 'react';
import { type QueryKey, useQueryClient } from 'react-query';
import { flatten, minBy, sortBy } from 'lodash';
import { useScrubberData } from './useScrubberData';
import {
  type PositionedActivity,
  type VesselTracksViewModel,
  type VesselTrackViewModel,
} from '../components/Map/types';
import { type PlaybackViewModel } from '../types';
import { Timestamp } from '../utils/timestamp';
import { areQueryKeysEqual, isPlaybackQueryKey } from '../utils/data-fetching-utils';
import { isTimestampWithinRange } from '../utils/timestamp-range-utils';
import { ActivityType, type TimestampRange } from '../../../global/types';
import type { QueryFilters } from 'react-query/types/core/utils';

const VesselTrackLimitInHours = 2;

export const usePlaybackVesselTracksData = (
  mmsi: string,
  timestamp: Timestamp,
  enabled: boolean,
  shouldUpdateTracks: boolean
): VesselTracksViewModel => {
  const [vesselTracks, setVesselTracks] = useState<VesselTrackViewModel[]>([]);
  const [aggregatedKeys, setAggregatedKeys] = useState<QueryKey[]>([]);
  const { activities } = useScrubberData(mmsi);
  const queryClient = useQueryClient();
  const fetchedData = queryClient.getQueriesData<PlaybackViewModel>({
    predicate: q => isPlaybackQueryKey(q.queryKey),
    fetching: false,
    stale: false,
  } as QueryFilters);
  const [pastTracks, setPastTracks] = useState<VesselTrackViewModel[]>([]);
  const [futureTracks, setFutureTracks] = useState<VesselTrackViewModel[]>([]);

  const trackLimitRange =
    !!timestamp &&
    ({
      from: timestamp.subtract(Timestamp.fromHours(VesselTrackLimitInHours)),
      to: timestamp.add(Timestamp.fromHours(VesselTrackLimitInHours)),
    } as TimestampRange);

  const updateVesselTracks = (
    existingTracks: VesselTrackViewModel[],
    viewModel: PlaybackViewModel
  ): VesselTrackViewModel[] => {
    const newTracks = flatten(Object.values(viewModel.vesselPositions))
      .filter(p => p.mmsi === mmsi)
      .map(p => {
        return {
          location: p.location,
          timestamp: p.timestamp,
          speed: p.speed,
          mmsi: p.mmsi,
        } as VesselTrackViewModel;
      });

    return sortBy(existingTracks.concat(newTracks), x => x.timestamp);
  };

  const getPastTracks = (): VesselTrackViewModel[] => {
    return vesselTracks?.filter(
      t => t.timestamp >= trackLimitRange.from.toSeconds() && t.timestamp <= timestamp.toSeconds()
    );
  };
  const getFutureTracks = (): VesselTrackViewModel[] => {
    return vesselTracks?.filter(
      t =>
        t.timestamp >= timestamp.subtract(Timestamp.fromMinutes(1)).toSeconds() &&
        t.timestamp <= trackLimitRange.to.toSeconds()
    );
  };

  useEffect(() => {
    if (enabled) {
      fetchedData
        .filter(([key]) => !aggregatedKeys.some(k => areQueryKeysEqual(k, key)))
        .forEach(([key, viewModel]) => {
          setVesselTracks(tracks => updateVesselTracks(tracks, viewModel));
          setAggregatedKeys(keys => [...keys, key]);
        });
    }
  }, [fetchedData]);

  const positionedActivities = useMemo(() => {
    if (vesselTracks.length && (pastTracks.length || futureTracks.length)) {
      return activities
        ?.filter(
          a =>
            isTimestampWithinRange(Timestamp.fromTimestampModel(a.timestampRange.from), trackLimitRange) &&
            a.type !== ActivityType.Loading &&
            a.type !== ActivityType.Resting
        )
        .map(activity => {
          const position = minBy(vesselTracks, t =>
            Math.abs(
              Timestamp.fromMilliseconds(activity.timestampRange.from.valueInMilliseconds)
                .subtract(Timestamp.fromSeconds(t.timestamp))
                .toSeconds()
            )
          );

          return {
            activityId: activity.activityId,
            mmsi: position.mmsi,
            timestampRange: activity.timestampRange,
            type: activity.type,
            isSpeedLimited: activity.isSpeedLimited,
            startLocation: position.location,
            speed: position.speed,
            timestamp: position.timestamp,
          } as PositionedActivity;
        });
    }
  }, [activities, pastTracks, futureTracks]);

  useEffect(() => {
    if (!shouldUpdateTracks && !!timestamp) {
      setPastTracks(getPastTracks());
      setFutureTracks(getFutureTracks());
    }
  }, [shouldUpdateTracks, timestamp, enabled, mmsi, aggregatedKeys]);

  useEffect(() => {
    setVesselTracks([]);
    setAggregatedKeys([]);
  }, [mmsi]);

  return useMemo(() => {
    return enabled
      ? { pastTracks, futureTracks, activities: positionedActivities }
      : { pastTracks: [], futureTracks: [], activities: [] };
  }, [pastTracks, futureTracks, positionedActivities]);
};
