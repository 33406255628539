import { type FC } from 'react';
import { Button } from '@chakra-ui/react';
import { type TranslationKey, translate } from '../../../../../../global/translation';

export interface Props {
  labelKey: TranslationKey;
  icon: React.ReactElement;
  onClick: () => void;
}

const ActionButton: FC<Props> = ({ labelKey, icon, onClick }): JSX.Element => {
  return (
    <Button leftIcon={icon} onClick={onClick} variant="secondary">
      {translate(labelKey)}
    </Button>
  );
};

export default ActionButton;
