import { type FunctionComponent } from 'react';
import { animated } from '@react-spring/web';
import { type AxisTickProps } from '@nivo/axes/dist/types/types';
import colors from '../../../../../theme/colors';

const BarChartAxisRightTicks: FunctionComponent<AxisTickProps<any>> = ({
  animatedProps,
  textBaseline,
  textAnchor,
  value,
}) => {
  return (
    <animated.g transform={animatedProps.transform} opacity={animatedProps.opacity}>
      <animated.text
        className="bottom-axis-label text-xs-regular"
        dominantBaseline={textBaseline}
        textAnchor={textAnchor}
        transform={animatedProps.textTransform}
        fill={colors.gray[600]}
      >
        {value}
      </animated.text>
    </animated.g>
  );
};

export default BarChartAxisRightTicks;
