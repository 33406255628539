import { useMemo } from 'react';
import { type FC } from 'react';
import { Image, useImage } from '@chakra-ui/react';
import { type Job } from '../../../../Trips/types';
import Tug from '../../../../../static/img/tug.png';
import { type VesselViewModel } from '../../../types';
import { CONFIG_CODES, type MmsiKey } from '../../../../../global/types';
import { getConfig } from '../../../../../global/utils/getConfig';

interface Props {
  job: Job;
  vesselData: Record<MmsiKey, VesselViewModel>;
}

const JobCardVesselImage: FC<Props> = ({ job, vesselData }) => {
  const vessel = vesselData[job.vesselMmsi];
  const imo = vessel ? vessel.imo : null;

  const imageUrl = useMemo(() => {
    const apiHost = getConfig(CONFIG_CODES.REACT_APP_API_HOST);
    return imo ? `${apiHost}/images/${imo}.jpg` : null;
  }, [imo]);

  const status = useImage({ src: imageUrl, onError: () => {} });

  const isImageLoaded = useMemo(() => status === 'loaded', [status]);

  return <>{imageUrl && isImageLoaded ? <img alt="" src={imageUrl} /> : <Image src={Tug} />}</>;
};

export default JobCardVesselImage;
