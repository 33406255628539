import { useQuery } from 'react-query';
import moment from 'moment/moment';
import { type UseQueryResult } from 'react-query/types/react/types';
import { type DailyEcoRating, type Filters, type TripsEcoRatingViewModel } from '../../Trips/types';
import { type DateRange } from '../../../global/types';
import { convertFilterToQuery } from '../../Trips/utils/convertFilterToQuery';
import { ApiClient } from '../../../api/api-client';
import { getAuthClient } from '../../../global/utils/authUtils';
import STATUS from '../../../global/statusCodes';
import { Timestamp } from '../utils/timestamp';
import { TripsEcoRatingsQueryKeyName } from '../utils/data-fetching-utils';
import { getMomentsFromRange } from '../../../global/utils/date-range-utils';
import { barChartDateFormat } from '../components/BarChartDatePicker/constants';
import { formatDateRange } from '../components/BarChartDatePicker/utils/bar-chart-utils';
import { type OperationViewModel } from '../components/Map/types';
import { getLocalTimestampFromUtcDate } from '../../../global/utils/date-utils';

export const useFetchTripsEcoRatings = (
  filters: Filters,
  dateRange: DateRange,
  operation: OperationViewModel
): UseQueryResult<TripsEcoRatingViewModel, Error> => {
  const query = {
    ...filters,
    startDate: getLocalTimestampFromUtcDate(dateRange.from, operation?.timezone).toDate().toISOString(),
    endDate: getLocalTimestampFromUtcDate(dateRange.to, operation?.timezone).toDate().toISOString(),
  };
  const queryString = convertFilterToQuery(query);

  const formatEcoRatingsByDay = (ecoRatings: DailyEcoRating[], dateRange: DateRange): DailyEcoRating[] => {
    const dates = getMomentsFromRange(dateRange, 'day');
    return dates.map(day => {
      const rating = ecoRatings.find(e => moment(e.day).isSame(day, 'day'));

      return { ...rating, day: day.format(barChartDateFormat) };
    });
  };

  const callEndpoint = async (): Promise<TripsEcoRatingViewModel> => {
    const response = await ApiClient.createAuthenticatedInstance(getAuthClient()).call(
      `/trips/eco-rating?${queryString}`
    );
    return response.status === STATUS.OK
      ? { ...response.data, dailyRatings: formatEcoRatingsByDay(response.data.dailyRatings, dateRange) }
      : [];
  };

  return useQuery<TripsEcoRatingViewModel, Error>(
    [TripsEcoRatingsQueryKeyName, formatDateRange(dateRange), queryString],
    callEndpoint,
    {
      staleTime: Timestamp.fromMinutes(10).toMilliseconds(),
    }
  );
};
