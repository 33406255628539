import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { type AnalyticsEvent } from './types';

export class AppInsightsPlugin extends ReactPlugin {
  trackAnalyticsEvent(analyticsEvent: AnalyticsEvent): void {
    const { name, parent, page, ...otherProperties } = analyticsEvent;
    this.trackEvent({
      name,
      properties: {
        parent,
        page,
        ...otherProperties,
      },
    });
  }
}
