import React, { type ReactNode } from 'react';
import GlobalExceptionHandlerSimpleModal from './GlobalExceptionHandlerSimpleModal';

interface Props {
  children: ReactNode;
}

interface GlobalErrorHandlerState {
  hasError: boolean;
  errorInfo: any;
  error: Error;
}

class GlobalExceptionHandler extends React.Component<Props, GlobalErrorHandlerState> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, errorInfo: null, error: null };
  }

  static getDerivedStateFromError(error: Error): GlobalErrorHandlerState {
    return { hasError: true, errorInfo: null, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ errorInfo, error });
    // eslint-disable-next-line no-console
    console.error('Error caught in Global Error Handler:', error, errorInfo);
  }

  render(): ReactNode {
    const { hasError, errorInfo, error } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <GlobalExceptionHandlerSimpleModal errorMessage={errorInfo} error={error} />;
    }

    return children;
  }
}

export default GlobalExceptionHandler;
