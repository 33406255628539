import { type FC } from 'react';
import { type TooltipProps } from './types';
import { type VesselTrackFeatureProperties } from '../../types';
import { type Speed } from '../../../../../../global/types';
import { Timestamp } from '../../../../utils/timestamp';
import { translateAndFormat } from '../../../../../../global/translation';

export const VesselTrackTooltip: FC<TooltipProps<VesselTrackFeatureProperties> & { timezone: string }> = ({
  properties,
  timezone,
}) => {
  const { timestamp, speed: speedString } = properties;
  const speed: Speed = JSON.parse(speedString.toString());
  const offset = Timestamp.fromSeconds(timestamp).toLocationDateTimeOffset(timezone);
  const date = offset.format('hh:mm A');

  return (
    <div className="popup-container">
      <p>{translateAndFormat('TIME_AND_SPEED_TOOLTIP_TEXT', date, (speed?.value ?? 0).toFixed(1))}</p>
    </div>
  );
};
