import { EcoZone } from '../../../Trips/types';
import colors from '../../../../theme/colors';

export const ecoRatingKey = 'ecoRating';
export const co2SurplusKey = 'estimatedCo2Surplus';

export const dayAbbreviations = {
  0: 'S',
  1: 'M',
  2: 'T',
  3: 'W',
  4: 'T',
  5: 'F',
  6: 'S',
};

export const barChartDateFormat = 'YYYY-MM-DD';
export const defaultBarChartMaxValue = 50;
export const barChartAdjustmentValue = 20;
export const defaultEcoRatingValue = 50;
export const defaultCo2SurplusValue = -30;
export const co2SurplusRatioMultiplier = 50;
export const slidingDelayMs = 50;
export const swipeDurationMs = 500;

export const defaultBarChartRangeSize = 7;
export const twoWeeksBarChartRangeSize = 14;

export const barChartEcoZoneColorMap = {
  [EcoZone.good]: colors.success[400],
  [EcoZone.neutral]: colors.warning[400],
  [EcoZone.bad]: colors.error[400],
};
