import {
  type FunctionComponent,
  useLayoutEffect,
  useMemo,
  useState,
  type MouseEvent as ReactMouseEvent,
  type TouchEvent,
} from 'react';
import { Box } from '@chakra-ui/react';
import cx from 'classnames';
import ScrubberActivity from './ScrubberActivity';
import SpeedChart from './SpeedChart';
import HelmEventMarker from './HelmEventMarker';
import { getTimelineRange } from './utils/scrubber-timeline-utils';
import { useHelmEventMarkersData } from '../../hooks/useHelmEventMarkersData';
import { type Timestamp } from '../../utils/timestamp';
import { ReactComponent as Needle } from '../../../../static/svg/Needle.svg';
import { sidePanelMapCenterOffsetPixels } from '../Map/constants';
import { type VesselViewModel, type ScrubberViewModel } from '../../types';
import { type TimestampRange } from '../../../../global/types';

interface Props {
  secondInPixels: number;
  currentTimestamp: Timestamp;
  isTripsPanelOpen: boolean;
  data: ScrubberViewModel;
  isDraggingScrubber: boolean;
  timezone: string;
  vessel: VesselViewModel;
  handleDragStart: (e: any) => void;
  handleDragEnd: (e: ReactMouseEvent | TouchEvent) => void;
  handleMove: (e: ReactMouseEvent | TouchEvent) => void;
}

const needleSvgWidthInPixels = 10;

const ScrubberTimeline: FunctionComponent<Props> = ({
  currentTimestamp,
  secondInPixels,
  isTripsPanelOpen,
  data,
  isDraggingScrubber,
  timezone,
  vessel,
  handleDragEnd,
  handleDragStart,
  handleMove,
}) => {
  const [timelineRange, setTimelineRange] = useState<TimestampRange>(
    getTimelineRange(currentTimestamp, secondInPixels)
  );

  useLayoutEffect(() => {
    if (!isDraggingScrubber) {
      setTimelineRange(getTimelineRange(currentTimestamp, secondInPixels));
    }
  }, [currentTimestamp.valueInMilliseconds, isDraggingScrubber, secondInPixels]);

  const needlePosition = useMemo(() => {
    const tripsPanelWidth = isTripsPanelOpen ? sidePanelMapCenterOffsetPixels : 0;
    return (window.innerWidth - tripsPanelWidth) / 2;
  }, [window.innerWidth, isTripsPanelOpen]);

  const offset = useMemo<number>(() => {
    const distanceFromLeftSide = currentTimestamp.subtract(timelineRange.from).toSeconds() * secondInPixels;

    return -(distanceFromLeftSide - needlePosition - needleSvgWidthInPixels / 2);
  }, [currentTimestamp.valueInMilliseconds, needlePosition, timelineRange]);

  const width = useMemo(() => {
    const timelineStart = timelineRange.from.toSeconds();
    const timelineEnd = timelineRange.to.toSeconds();

    return (timelineEnd - timelineStart) * secondInPixels;
  }, [timelineRange]);

  const helmEventsMarkers = useHelmEventMarkersData(data.helmEvents, secondInPixels, timelineRange);

  const markersComponents = useMemo(() => {
    return helmEventsMarkers.map(marker => (
      <HelmEventMarker key={marker.id} marker={marker} timezone={timezone} vessel={vessel} />
    ));
  }, [helmEventsMarkers]);

  const timelineContainerClasses = cx('timeline-container', {
    dragging: isDraggingScrubber,
  });

  return (
    <Box
      className={timelineContainerClasses}
      onMouseDown={handleDragStart}
      onMouseUp={handleDragEnd}
      onMouseLeave={handleDragEnd}
      onMouseMove={handleMove}
      onTouchStart={handleDragStart}
      onTouchEnd={handleDragEnd}
      onTouchMove={handleMove}
    >
      <Needle className="scrubber-needle" style={{ left: needlePosition }} />
      <Box className="scrubber-tread-container" width={`${width}px`} left={`${offset}px`}>
        <ScrubberActivity activities={data.activities} secondInPixels={secondInPixels} timelineRange={timelineRange} />
        <SpeedChart activities={data.activities} timelineRange={timelineRange} timezone={timezone} />
        <div className="helm-event-markers-container">{markersComponents}</div>
      </Box>
    </Box>
  );
};

export default ScrubberTimeline;
