import { type FC, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import {
  targetVesselIconLayer,
  targetVesselOutlineLayer,
  targetVesselPointLayer,
  targetVesselShapeLayer,
  targetVesselShapeLayer3D,
  targetVesselTextLayer,
} from '../layers-config';
import { getVesselFeatures } from '../utils/layer-utils';
import { type VesselPosition, type VesselViewModel } from '../../../types';
import { type MmsiKey } from '../../../../../global/types';
import { layerSources } from '../constants';
import { useMapContext } from '../../../../../global/state/GlobalProvider';

interface Props {
  zoomLevel: number;
  vesselPositions: VesselPosition[];
  vesselData: Record<MmsiKey, VesselViewModel>;
  targetVesselCoordinates: number[];
  targetVesselMmsi: MmsiKey;
}

const TargetVesselLayer: FC<Props> = ({
  zoomLevel,
  vesselPositions,
  vesselData,
  targetVesselCoordinates,
  targetVesselMmsi,
}) => {
  const { settings } = useMapContext();
  const features = useMemo(() => {
    if (!targetVesselMmsi) {
      return [];
    }

    const position = vesselPositions.find(p => p.mmsi === targetVesselMmsi);

    if (!position) {
      return [];
    }

    return getVesselFeatures(position, vesselData, true, settings, zoomLevel);
  }, [vesselPositions, zoomLevel, vesselData, targetVesselCoordinates]);

  return (
    <Source
      id={layerSources.targetVessel}
      type="geojson"
      data={{
        type: 'FeatureCollection',
        features,
      }}
      lineMetrics
    >
      <Layer {...targetVesselIconLayer} />
      <Layer {...targetVesselShapeLayer} />
      <Layer {...targetVesselShapeLayer3D} />
      <Layer {...targetVesselPointLayer} />
      <Layer {...targetVesselOutlineLayer} />
      {settings?.showVesselNames ? <Layer {...targetVesselTextLayer} /> : null}
    </Source>
  );
};

export default TargetVesselLayer;
