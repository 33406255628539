import { useQuery, type UseQueryResult } from 'react-query';
import { type Mode, type PlaybackViewModel } from '../types';

import { type Timestamp } from '../utils/timestamp';
import { getQueryOptions } from '../utils/data-fetching-utils';

export const useFetchPlaybackData = (
  timestamp: Timestamp,
  port: string,
  mode: Mode
): UseQueryResult<PlaybackViewModel> => {
  const options = getQueryOptions(port, timestamp, mode);

  return useQuery<PlaybackViewModel, Error>(options);
};
