import { type FC } from 'react';
import {
  MenuOptionGroup,
  MenuList,
  MenuItemOption,
  Menu,
  MenuButton,
  Text,
  Button,
  UnorderedList,
  Flex,
  Box,
} from '@chakra-ui/react';
import { useTripsContext } from '../../../global/state/GlobalProvider';
import { ReactComponent as Circle } from '../../../static/svg/Circle.svg';
import { OperationLiveStatus } from '../types';
import colors from '../../../theme/colors';
import { translate } from '../../../global/translation';

export interface Props {
  operation: string;
  operationStatus: OperationLiveStatus;
  isLoading: boolean;
  isWorldView: boolean;
  onOperationSelect: (value: string) => void;
  isTripsPanelOpened: boolean;
}

const OperationSelector: FC<Props> = ({
  operation,
  operationStatus,
  isLoading,
  isWorldView,
  onOperationSelect,
  isTripsPanelOpened,
}) => {
  const { operations } = useTripsContext();

  const statusIconMap = {
    [OperationLiveStatus.Live]: <Circle color={colors.success[500]} />,
    [OperationLiveStatus.Delayed]: <Circle color={colors.warning[500]} />,
    [OperationLiveStatus.Unavailable]: <Circle color={colors.error[500]} />,
  };

  return (
    <Box className="operation-selector" left={isTripsPanelOpened || isWorldView ? '8px' : '56px'}>
      <Menu closeOnSelect>
        <MenuButton as={Button} className="menu-button">
          <Flex alignItems="center">
            {isLoading ? <Circle color={colors.gray[400]} /> : statusIconMap[operationStatus]}
            <Text textOverflow="ellipsis">{isWorldView ? translate('WORLD_VIEW') : operation}</Text>
          </Flex>
        </MenuButton>
        <MenuList minWidth="240px" className="chakra-menu__menu-list filter-menu-operation custom-scroll-bar">
          <MenuOptionGroup type="radio" title="Operation" value={operation} as={UnorderedList}>
            {operations.map(p => (
              <MenuItemOption
                key={p.name}
                value={p.name}
                onClick={() => {
                  onOperationSelect(p.key);
                }}
                as="li"
                className="filter-menu-list-item"
              >
                {p.name}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default OperationSelector;
