import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { QueryClient, QueryClientProvider } from 'react-query';
import Routes from './pages/Routes';
import { reactPlugin } from './AppInsights';
import theme from './theme/theme';
import { GlobalProvider } from './global/state/GlobalProvider';
import './styles/style-bundle.scss';
import { AppContextProvider } from './global/components/AppContextProvider';
import AuthProvider from './global/components/AuthProvider';

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <AppContextProvider>
      <AuthProvider>
        <AppInsightsContext.Provider value={reactPlugin}>
          <QueryClientProvider client={queryClient}>
            <ChakraProvider theme={theme}>
              <GlobalProvider>
                <Router>
                  <Routes />
                </Router>
              </GlobalProvider>
            </ChakraProvider>
          </QueryClientProvider>
        </AppInsightsContext.Provider>
      </AuthProvider>
    </AppContextProvider>
  </React.StrictMode>
);
