import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useQueryParams = (): Record<string, string> => {
  const { search } = useLocation();
  return useMemo(() => {
    const queryParams = new URLSearchParams(search);
    return Object.fromEntries(queryParams.entries());
  }, [search]);
};

export default useQueryParams;
