export enum ActionType {
  SLIDE_PREVIOUS,
  SLIDE_NEXT,
  STOP_SLIDING,
}

export enum SlideDirection {
  PREVIOUS,
  NEXT,
}

interface CarouselState {
  sliding: boolean;
  direction: SlideDirection;
}

interface CarouselAction {
  type: ActionType;
}

export const initialCarouselState: CarouselState = { sliding: false, direction: SlideDirection.PREVIOUS };

export const carouselReducer = (state: CarouselState, action: CarouselAction): CarouselState => {
  switch (action.type) {
    case ActionType.SLIDE_PREVIOUS:
      return {
        ...state,
        direction: SlideDirection.PREVIOUS,
        sliding: true,
      };
    case ActionType.SLIDE_NEXT:
      return {
        ...state,
        direction: SlideDirection.NEXT,
        sliding: true,
      };
    case ActionType.STOP_SLIDING:
      return { ...state, sliding: false };
    default:
      return state;
  }
};
