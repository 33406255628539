import { type UseQueryResult, useQuery } from 'react-query';
import { ApiClient } from '../../../api/api-client';
import { getAuthClient } from '../../../global/utils/authUtils';
import STATUS from '../../../global/statusCodes';
import { type OperationViewModel } from '../components/Map/types';

export const useOperationData = (operation: string): UseQueryResult<OperationViewModel, Error> => {
  const getOperationData = async (): Promise<OperationViewModel> => {
    const response = await ApiClient.createAuthenticatedInstance(getAuthClient()).call<OperationViewModel>(
      `api/operations/${operation}`
    );
    if (response && response.status === STATUS.OK) {
      const { data } = response;
      return data;
    }

    return null;
  };

  const result = useQuery<OperationViewModel, Error>(['operationData', operation], getOperationData, {
    staleTime: Infinity,
    enabled: !!operation,
  });

  return result;
};
