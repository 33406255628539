import { useQueries, useQueryClient, type UseQueryResult } from 'react-query';
import { useEffect } from 'react';
import { type Timestamp } from '../utils/timestamp';
import { type Mode, type PlaybackViewModel } from '../types';
import { getPrefetchTimePairs, type TimePair, getQueryOptions } from '../utils/data-fetching-utils';

export const usePrefetchPlaybackData = (
  timestamp: Timestamp,
  port: string,
  currentQueryResult: UseQueryResult<PlaybackViewModel>,
  mode: Mode
): void => {
  const queryClient = useQueryClient();
  const timePairs: TimePair[] = getPrefetchTimePairs(timestamp);

  const prefetchData = async (prefetchTimestamp: Timestamp): Promise<void> => {
    const queryOptions = getQueryOptions(port, prefetchTimestamp, mode);
    await queryClient.prefetchQuery(queryOptions.queryKey, queryOptions.queryFn, queryOptions);
  };

  const prefetchDataSequentially = (): void => {
    const promises: Array<() => Promise<void>> = [];
    timePairs.forEach(pair => {
      promises.push(async (): Promise<void> => {
        await Promise.all([prefetchData(pair.pastTimestamp), prefetchData(pair.futureTimestamp)]);
      });
    });

    void promises.reduce(async (promise, promiseFn) => {
      return promise.then(async () => promiseFn());
    }, Promise.resolve());
  };

  useQueries(
    timePairs.flatMap(pair => {
      const options = { enabled: false };
      return [
        getQueryOptions(port, pair.pastTimestamp, mode, options),
        getQueryOptions(port, pair.futureTimestamp, mode, options),
      ];
    })
  );

  useEffect(() => {
    if (currentQueryResult.isSuccess) {
      prefetchDataSequentially();
    }
  }, [currentQueryResult.isSuccess, currentQueryResult.data]);
};
