import { type FC, type MouseEvent, createElement, useCallback, useMemo } from 'react';
import { animated, to } from '@react-spring/web';
import { type BarTooltipProps, type BarDatum, type BarItemProps } from '@nivo/bar/dist/types/types';
import { useTooltip } from '@nivo/tooltip';
import { getEcoRatingColor } from '../utils/bar-chart-utils';
import { co2SurplusKey, ecoRatingKey } from '../constants';
import colors from '../../../../../theme/colors';
import { type Bar, type BarData } from '../types';
import { useDetectMobileScreenWidth } from '../../../../../hooks/useDetectMobileScreenWidth';

interface Props extends BarItemProps<BarDatum> {
  selectedDate: string;
}

interface TooltipProps {
  bar: Bar;
  data: BarData;
  tooltip: React.FC<BarTooltipProps<any>>;
}

const Tooltip = ({ bar, data, tooltip }: TooltipProps): React.FunctionComponentElement<BarTooltipProps<BarDatum>> =>
  createElement(tooltip, { ...bar, ...data });

export const BarItem: FC<Props> = ({
  bar: { data, ...bar },
  style: { borderColor, height, transform, width },
  borderRadius,
  borderWidth,
  isInteractive,
  onClick,
  isFocusable,
  ariaLabel,
  ariaLabelledBy,
  ariaDescribedBy,
  selectedDate,
  onMouseEnter,
  onMouseLeave,
  tooltip,
}) => {
  const { showTooltipFromEvent, hideTooltip } = useTooltip();
  const tooltipInstance = useMemo(() => <Tooltip bar={bar} data={data} tooltip={tooltip} />, [tooltip, bar, data]);
  const isMobileView = useDetectMobileScreenWidth();

  const canToggleTooltip = useMemo(() => !isMobileView && isInteractive, [isMobileView, isInteractive]);

  const handleTooltip = useCallback(
    (event: MouseEvent<SVGRectElement>) => {
      showTooltipFromEvent(tooltipInstance, event);
    },
    [showTooltipFromEvent, tooltip]
  );

  const handleMouseEnter = useCallback(
    (event: MouseEvent<SVGRectElement>) => {
      onMouseEnter?.(data, event);
      showTooltipFromEvent(tooltipInstance, event);
    },
    [data, onMouseEnter, showTooltipFromEvent, tooltip]
  );

  const handleMouseLeave = useCallback(
    (event: MouseEvent<SVGRectElement>) => {
      onMouseLeave?.(data, event);
      hideTooltip();
    },
    [data, hideTooltip, onMouseLeave]
  );
  const handleClick = useCallback(
    (event: MouseEvent<SVGRectElement>) => {
      onClick?.({ color: bar.color, ...data }, event);
    },
    [bar, data, onClick]
  );

  const getColor = (): string => {
    const defaultColor = colors.gray[700];
    if (data.data.isEmpty) {
      return defaultColor;
    }

    return data.id === ecoRatingKey ? getEcoRatingColor(data.value) : defaultColor;
  };

  const getOpacity = (): number => {
    if (selectedDate) {
      if (data.data.day === selectedDate) {
        return 1;
      }
      return 0.3;
    }

    if (data.data.isEmpty) {
      return 0.5;
    }

    return 1;
  };

  const getClipPathUrl = (): string => {
    if (data.id === co2SurplusKey) {
      return `url(#bottom-${bar.key})`;
    }

    return `url(#top-${bar.key})`;
  };

  return (
    <animated.g transform={transform}>
      <defs>
        <clipPath id={`top-${bar.key}`}>
          <rect x="0" y="0" width={bar.width} height={bar.height + borderRadius} rx={borderRadius} ry={borderRadius} />
          <rect x="0" y={bar.height} width={bar.width} height={bar.height} />
        </clipPath>
        <clipPath id={`bottom-${bar.key}`}>
          <rect x="0" y="0" width={bar.width} height={borderRadius} />
          <rect x="0" y="0" width={bar.width} height={bar.height} rx={borderRadius} ry={borderRadius} />
        </clipPath>
      </defs>

      <animated.rect
        clipPath={getClipPathUrl()}
        width={to(width, value => Math.max(value, 0))}
        height={to(height, value => Math.max(value, 0))}
        fill={getColor()}
        opacity={getOpacity()}
        strokeWidth={borderWidth}
        stroke={borderColor}
        focusable={isFocusable}
        tabIndex={isFocusable ? 0 : undefined}
        aria-label={ariaLabel ? ariaLabel(data) : undefined}
        aria-labelledby={ariaLabelledBy ? ariaLabelledBy(data) : undefined}
        aria-describedby={ariaDescribedBy ? ariaDescribedBy(data) : undefined}
        onClick={isInteractive ? handleClick : undefined}
        onMouseEnter={canToggleTooltip ? handleMouseEnter : undefined}
        onMouseMove={canToggleTooltip ? handleTooltip : undefined}
        onMouseLeave={canToggleTooltip ? handleMouseLeave : undefined}
        cursor="pointer"
        rx={borderRadius}
        ry={borderRadius}
      />
    </animated.g>
  );
};
