import { useEffect, useState } from 'react';
import { type MmsiKey } from '../../../global/types';
import type { VesselViewModel } from '../types';

export const useTargetVessel = (
  targetVesselMmsi: string,
  vesselData: Record<MmsiKey, VesselViewModel>
): VesselViewModel => {
  const [targetVessel, setTargetVessel] = useState<VesselViewModel>(null);

  useEffect(() => {
    if (!targetVesselMmsi) {
      setTargetVessel(null);
    }

    const newTargetVessel = vesselData[targetVesselMmsi];
    if (newTargetVessel) {
      setTargetVessel(vesselData[targetVesselMmsi]);
    }
  }, [targetVesselMmsi, vesselData]);

  return targetVessel;
};
