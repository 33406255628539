import { type BarTooltipProps } from '@nivo/bar';
import moment from 'moment';

const BarTooltip: React.FC<BarTooltipProps<any>> = ({ data }) => {
  return (
    <div className="bar-tooltip">
      <p>{moment(data.day).format('DD MMMM')}</p>
    </div>
  );
};

export default BarTooltip;
