import _ from 'lodash';
import { Timestamp } from './timestamp';
import { isUnderOrdersActivity } from './isUnderOrdersActivity';
import { Source, type Trip } from '../../Trips/types';
import { type ActivityType } from '../../../global/types';

export const getStartTimeFromTrip = (trip: Trip): Timestamp => {
  const activities = trip.jobs.filter(j => j.source !== Source.Helm).flatMap(job => job.activities);
  const underOrdersActivities = activities
    .filter(activity => isUnderOrdersActivity(activity.activity as ActivityType))
    .sort(activity => activity.timestampStart);
  const firstActivityTimestamp = _.first(underOrdersActivities)?.timestampStart;

  return Timestamp.fromSeconds(firstActivityTimestamp ?? trip.timestampStart);
};
