import { type Filters } from '../types';

export const convertFilterToQuery = (filters: Filters): string => {
  const filterParameters: string[] = [];
  Object.entries(filters).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      (value as string[]).forEach(triptype => filterParameters.push(`${key}=${triptype}`));
      return true;
    }
    if (value) {
      filterParameters.push(`${String(key)}=${String(value)}`);
    }
  });
  return filterParameters.join('&');
};
