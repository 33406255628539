import { Spinner } from '@chakra-ui/react';
import { ReactComponent as SvitzerIconTransparent } from '../static/svg/SvitzerIconTransparent.svg';

const SplashScreen = (): JSX.Element => {
  return (
    <div className="splash-screen">
      <div className="center-icon">
        <SvitzerIconTransparent />
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
      </div>
    </div>
  );
};

export default SplashScreen;
