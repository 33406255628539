import { type FC } from 'react';
import { IconButton } from '@chakra-ui/react';
import { ReactComponent as RecenterVesselIconActive } from '../../../../static/svg/RecenterVesselActive.svg';
import { ReactComponent as RecenterVesselIconInactive } from '../../../../static/svg/RecenterVesselInactive.svg';
import { translate } from '../../../../global/translation';
import { type VesselViewModel } from '../../types';

interface Props {
  vessel: VesselViewModel;
  onTargetLock: (mmsi: string) => void;
  isLockedOnTarget: boolean;
}

const RecenterButton: FC<Props> = ({ vessel, isLockedOnTarget, onTargetLock }) => {
  return (
    <div>
      <IconButton
        variant={isLockedOnTarget ? 'primary' : 'secondary'}
        onClick={() => {
          onTargetLock(isLockedOnTarget ? null : vessel?.mmsi);
        }}
        icon={isLockedOnTarget ? <RecenterVesselIconActive /> : <RecenterVesselIconInactive />}
        aria-label={translate('RECENTER_ARIA_DESCRIPTION')}
      />
    </div>
  );
};

export default RecenterButton;
