import { type FunctionComponent, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { type PositionedActivity } from '../types';
import { layerSources } from '../constants';
import { innerCircleLayer, outerCircleBorderLayer, outerCircleLayer } from '../layers-config';
import { getActivityMarkerFeatures } from '../utils/layer-utils';
import { useMapContext } from '../../../../../global/state/GlobalProvider';

interface Props {
  activities: PositionedActivity[];
}

const ActivityMarkersLayer: FunctionComponent<Props> = ({ activities }) => {
  const { settings } = useMapContext();
  const features = useMemo(() => {
    return getActivityMarkerFeatures(activities, settings.layer);
  }, [activities, settings.layer]);

  return (
    <Source
      id={layerSources.activityMarkers}
      type="geojson"
      data={{
        type: 'FeatureCollection',
        features,
      }}
    >
      <Layer {...outerCircleBorderLayer} />
      <Layer {...outerCircleLayer} />
      <Layer {...innerCircleLayer} />
    </Source>
  );
};

export default ActivityMarkersLayer;
