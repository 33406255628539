import { type FunctionComponent, useCallback, useMemo } from 'react';
import { Box, Button, Flex, HStack, Text } from '@chakra-ui/react';
import { find } from 'lodash';
import moment from 'moment/moment';
import ProgressStatus from './ProgressStatus';
import { translate } from '../../../../../global/translation';
import { getEcoRatingColor } from '../utils/bar-chart-utils';
import { barChartDateFormat, defaultBarChartRangeSize, twoWeeksBarChartRangeSize } from '../constants';
import { type TripsEcoRatingViewModel } from '../../../../Trips/types';
import { ReactComponent as InfoIcon } from '../../../../../static/svg/InfoCircle.svg';
import localStorageService from '../../../../../global/services/localStorageService';
import { barChartRangeSizeStorageKey } from '../../../../Trips/utils/initial-trips-state';
import TooltipResolver from '../../../../../global/components/TooltipResolver/TooltipResolver';
import { type EcoRatingTooltip } from '../../../types';

interface Props {
  tripsEcoRating: TripsEcoRatingViewModel;
  selectedDate: Date;
  rangeSize: number;
  setDaysInRange: (rangeSize: number) => void;
  ecoRatingTooltip: EcoRatingTooltip;
}

const BarChartHeader: FunctionComponent<Props> = ({
  tripsEcoRating,
  selectedDate,
  rangeSize,
  setDaysInRange,
  ecoRatingTooltip,
}) => {
  const isDefaultRange = rangeSize === defaultBarChartRangeSize;

  const ecoRatingHeader = useMemo(() => {
    if (selectedDate) {
      return translate('DAY_ECO_RATING');
    }
    return isDefaultRange ? translate('WEEKLY_ECO_RATING') : translate('FOURTEEN_DAY_ECO_RATING');
  }, [selectedDate, rangeSize]);

  const metrics: { ecoRating: number; estimatedCo2Surplus: number; progress: number } = useMemo(() => {
    const dayRating = find(
      tripsEcoRating?.dailyRatings,
      r => r.day === moment(selectedDate).format(barChartDateFormat)
    );
    if (selectedDate) {
      return {
        ecoRating: dayRating?.ecoRating,
        estimatedCo2Surplus: dayRating?.estimatedCo2Surplus,
        progress: dayRating?.progress,
      };
    }
    return {
      ecoRating: tripsEcoRating?.ecoRating,
      estimatedCo2Surplus: tripsEcoRating?.estimatedCo2Surplus,
      progress: tripsEcoRating?.progress,
    };
  }, [selectedDate, tripsEcoRating]);

  const handleRangeSizeChange = useCallback(() => {
    const newValue = isDefaultRange ? twoWeeksBarChartRangeSize : defaultBarChartRangeSize;
    setDaysInRange(newValue);
    localStorageService.setItem(barChartRangeSizeStorageKey, newValue);
  }, [isDefaultRange]);

  const { onHover, onHoverOut, onTap } = ecoRatingTooltip;

  return (
    <Flex className="bar-chart-header-container" justifyContent="space-between">
      <Box onMouseEnter={onHover} onMouseLeave={onHoverOut} onTouchEnd={onTap} onBlur={onTap}>
        <Text className="text-xs-regular eco-ratings-label">
          {ecoRatingHeader}
          <InfoIcon />
        </Text>
        <HStack className="eco-rating-container">
          <Text className="text-xxl-regular" style={{ color: getEcoRatingColor(metrics.ecoRating) }}>
            {metrics.ecoRating || translate('DASH')}
          </Text>
          <ProgressStatus progress={metrics.progress} />
        </HStack>
      </Box>
      <TooltipResolver label={translate('ESTIMATED_CO2_SURPLUS_TOOLTIP')} placement="top">
        <Box>
          <Text className="text-xs-regular eco-ratings-label">
            {translate('ESTIMATED_CO2_SURPLUS')}
            <InfoIcon />
          </Text>
          <Text className="text-xxl-regular co2-surplus-text">
            {metrics.estimatedCo2Surplus
              ? `${metrics.estimatedCo2Surplus} ${translate('TONNE_ABBREVIATION')}`
              : translate('DASH')}
          </Text>
        </Box>
      </TooltipResolver>
      <Button className="text-sm-semibold" variant="secondary" width="50px" onClick={handleRangeSizeChange}>
        {`${rangeSize}${translate('DAY_ABBREVIATION')}`}
      </Button>
    </Flex>
  );
};

export default BarChartHeader;
