import { type FC, memo, useMemo } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { Box } from '@chakra-ui/react';
import SpeedChartAxisBottomTick from './SpeedChartAxisBottomTick';
import { generateLineDataForActivities, generateSpeedingGradients } from './utils/speed-chart-utils';
import { speedChartMaxValue, speedChartMinValue, svgDefs } from './constants';
import { type Timestamp } from '../../utils/timestamp';
import { type ActivityViewModel } from '../../types';
import { type Range } from '../../../../global/types';
import colors from '../../../../theme/colors';

interface Props {
  activities: ActivityViewModel[];
  timelineRange: Range<Timestamp>;
  timezone: string;
}

const SpeedChart: FC<Props> = memo(({ activities, timelineRange, timezone }) => {
  const data = useMemo(() => {
    return generateLineDataForActivities(activities);
  }, [activities]);

  const { fill, defs } = useMemo(() => generateSpeedingGradients(data), [data]);

  return (
    <Box className="speed-chart-container">
      <ResponsiveLine
        data={data}
        isInteractive={false}
        enablePoints={false}
        enableArea
        areaOpacity={1}
        margin={{ top: 2, bottom: 36 }}
        defs={svgDefs.concat(defs)}
        fill={fill}
        curve="monotoneX"
        colors={data.map(d => d.color)}
        enableSlices={false}
        crosshairType="right"
        axisBottom={{
          tickSize: 0,
          tickPadding: 22,
          tickValues: 'every 30 minutes',
          renderTick: props => <SpeedChartAxisBottomTick tickProps={props} timezone={timezone} />,
        }}
        xScale={{
          type: 'time',
          precision: 'second',
          min: timelineRange.from.toDate(),
          max: timelineRange.to.toDate(),
          format: '%H:%M',
          useUTC: true,
        }}
        xFormat="time:%H:%M"
        yScale={{ type: 'linear', min: speedChartMinValue, max: speedChartMaxValue }}
        animate={false}
        enableGridX={false}
        gridYValues={[0, 2, 4, 6, 8, 10, 12]}
        theme={{
          grid: {
            line: {
              stroke: colors.gray[100],
            },
          },
        }}
      />
    </Box>
  );
});

export default SpeedChart;
