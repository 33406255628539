import { type FunctionComponent } from 'react';
import { animated } from '@react-spring/web';
import moment from 'moment/moment';
import { type AxisTickProps } from '@nivo/axes/dist/types/types';
import { dayAbbreviations } from '../constants';

const BarChartAxisBottomTicks: FunctionComponent<AxisTickProps<any>> = ({
  animatedProps,
  lineX,
  lineY,
  textBaseline,
  textAnchor,
  value,
  textX,
  textY,
}) => {
  return (
    <animated.g transform={animatedProps.transform} opacity={animatedProps.opacity}>
      <line x1={0} x2={lineX} y1={0} y2={lineY} />
      <animated.text
        className="bottom-axis-label text-xs-regular"
        dominantBaseline={textBaseline}
        textAnchor={textAnchor}
        transform={animatedProps.textTransform}
      >
        {moment(value).date()}
      </animated.text>
      <animated.text
        className="bottom-axis-label text-xs-regular"
        dominantBaseline={textBaseline}
        textAnchor={textAnchor}
        transform={`translate(${textX},${textY + 15})`}
      >
        {dayAbbreviations[moment(value).day()]}
      </animated.text>
    </animated.g>
  );
};

export default BarChartAxisBottomTicks;
