import { Card, CardHeader, Text, CardBody, Box, CloseButton, Flex, Spacer } from '@chakra-ui/react';
import { type FC } from 'react';
import RateBtn from './RateBtn';
import { ratings } from './utils/rate-app-utils';
import { ReactComponent as AnnouncementImg } from '../../static/svg/Announcement.svg';
import { translate } from '../translation';

interface RateAppModalProps {
  isOpen: boolean;
  onClose: () => void;
  onRateClick: (rate: number) => void;
}

const RateCard: FC<RateAppModalProps> = ({ onClose, onRateClick, isOpen }) => {
  return (
    isOpen && (
      <div className="rate-app-container rate-app-map-container">
        <Card className="rate-app-card">
          <CardHeader className="rate-app-card-header">
            <Flex direction="row" width="100%" alignItems="center">
              <Box>
                <AnnouncementImg className="rate-app-icon" />
              </Box>
              <Spacer />
              <Box>
                <CloseButton className="card-close-btn" onClick={onClose} />
              </Box>
            </Flex>
          </CardHeader>
          <CardBody className="rate-app-value-modal-content">
            <Text>{translate('RATEAPPMODAL_HEADING')}</Text>
            <div className="rate-app-value-modal-body-btn">
              {ratings.map((x, i) => (
                <RateBtn
                  key={`rate-btn-${i}`}
                  rate={x}
                  onRateClick={rate => {
                    onRateClick(rate);
                  }}
                />
              ))}
            </div>
            <div className="rate-app-value-modal-body-likely">
              <div>{translate('NOT_LIKELY')}</div>
              <div>{translate('VERY_LIKELY')}</div>
            </div>
          </CardBody>
        </Card>
      </div>
    )
  );
};

export default RateCard;
