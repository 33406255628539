import { alertAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import colors from '../colors';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(alertAnatomy.keys);

const customSuccess = definePartsStyle({
  container: {
    borderRadius: '8px',
    bg: colors.primary[600],
    color: colors.base.white,
    border: `1px solid ${colors.primary[600]}`,
  },
  title: {
    fontSize: '14px',
    fontWeight: '500',
  },
});

const customWarning = definePartsStyle({
  container: {
    borderRadius: '8px',
    bg: colors.warning[600],
    color: colors.base.white,
    border: `1px solid ${colors.warning[600]}`,
  },
  title: {
    fontSize: '14px',
    fontWeight: '500',
  },
});

const customError = definePartsStyle({
  container: {
    borderRadius: '8px',
    bg: colors.error[600],
    color: colors.base.white,
    border: `1px solid ${colors.error[600]}`,
  },
  title: {
    fontSize: '14px',
    fontWeight: '500',
  },
});

const customLoading = definePartsStyle({
  container: {
    borderRadius: '8px',
    bg: colors.gray[600],
    color: colors.base.white,
    border: `1px solid ${colors.gray[600]}`,
  },
  title: {
    fontSize: '14px',
    fontWeight: '500',
  },
});

export const alertTheme = defineMultiStyleConfig({
  variants: { customSuccess, customWarning, customError, customLoading },
});
