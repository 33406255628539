import { type FC, useState, useEffect } from 'react';
import { useAppInsights } from '../../../../../hooks/useAppInsights';
import { useDispatch, useTripsContext } from '../../../../../global/state/GlobalProvider';
import { Event, Feature, FilteredVesselEvent, type Page } from '../../../../../global/types';
import { ActionType } from '../../../../../global/state/reducer';
import { getEventFactory } from '../../../../../global/utils/get-event-factory';
import InputSearch from '../../../../../global/components/InputSearch';
import { translate } from '../../../../../global/translation';
import { defaultFilters } from '../../../../Trips/utils/initial-trips-state';

interface Props {
  page: Page;
}

const VesselNameSearchBar: FC<Props> = ({ page }) => {
  const appInsights = useAppInsights();
  const { filters, vesselNames } = useTripsContext();
  const tripsDispatch = useDispatch();
  const [currentSearch, setCurrentSearch] = useState(filters.vesselName);
  const [searchResults, setSearchResults] = useState<string[]>([]);

  useEffect(() => {
    if (!filters.vesselName) {
      setCurrentSearch(defaultFilters.vesselName);
      setSearchResults([]);
    }
  }, [filters]);

  const inputChangeHandler = (newValue: string): void => {
    if (!newValue) {
      setCurrentSearch(newValue);
      setSearchResults([]);
      search(newValue);
      return;
    }
    const prefixSearch: string[] = [];
    if (newValue.length >= 3) {
      vesselNames.forEach(vesselName => {
        if (vesselName.toLowerCase().includes(newValue.toLowerCase())) {
          prefixSearch.push(vesselName);
        }
      });
      const sortedResults = prefixSearch.slice().sort((a, b) => a.localeCompare(b));
      setSearchResults(sortedResults);
    }
    setCurrentSearch(newValue);
  };

  const suggestionClickHandler = (value: string): void => {
    search(value);
  };

  const clearSearchValue = (): void => {
    setCurrentSearch(defaultFilters.vesselName);
    search(null);
  };

  const search = (value: string): void => {
    setCurrentSearch(value);
    setSearchResults([]);
    const newFilter = {
      ...filters,
      vesselName: value,
    };
    tripsDispatch({ type: ActionType.UPDATE_FILTER, payload: newFilter });
    appInsights.trackAnalyticsEvent(
      getEventFactory(Event.FilteredVessel)(
        Feature.TripsHistoryPanel,
        page,
        filters,
        newFilter,
        FilteredVesselEvent.MouseSelection
      )
    );
  };

  return (
    <InputSearch
      className="vessel-name-search-container"
      searchValue={currentSearch}
      options={searchResults}
      onQueryChange={inputChangeHandler}
      clearSearchValue={clearSearchValue}
      onResultSelect={suggestionClickHandler}
      placeholder={translate('PLACEHOLDER_VESSEL_NAME')}
    />
  );
};

export default VesselNameSearchBar;
