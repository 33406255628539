import { Button, Text, Card, CardBody, CardFooter } from '@chakra-ui/react';
import { type FC } from 'react';
import cx from 'classnames';
import { ReactComponent as InspirationImg } from '../../static/svg/Inspiration.svg';
import { translate } from '../translation';
import { useDetectMobileScreenWidth } from '../../hooks/useDetectMobileScreenWidth';
import { useScreenSize } from '../../hooks/useScreenSize';

export enum ConfirmCardSource {
  NpsSurvey,
  SeeSomethingWrongReport,
}

interface ConfirmFeedbackCardProps {
  isOpen: boolean;
  source: ConfirmCardSource;
  onClose: () => void;
}

const ConfirmFeedbackCard: FC<ConfirmFeedbackCardProps> = ({ isOpen, source, onClose }) => {
  const isMobileView = useDetectMobileScreenWidth();
  const screenSize = useScreenSize();

  const containerClass = cx('confirm-feedback-container', {
    'rate-app-map-container': source === ConfirmCardSource.NpsSurvey,
    'see-something-wrong-container': source === ConfirmCardSource.SeeSomethingWrongReport,
  });
  return (
    isOpen && (
      <Card width={isMobileView ? screenSize.width - 48 : 448} position="fixed" className={containerClass}>
        <CardBody>
          <InspirationImg />
          <div className="heading text-header">{translate('CONFIRMFEEDBACKCARD_HEADING')}</div>
          <Text textAlign="center" className="text-sm-regular">
            {translate('CONFIRMFEEDBACKCARD_TEXT')}
          </Text>
        </CardBody>
        <CardFooter>
          <Button variant="secondary" className="modal-footer-btn" onClick={onClose}>
            {translate('CONFIRMFEEDBACKCARD_BTN')}
          </Button>
        </CardFooter>
      </Card>
    )
  );
};

export default ConfirmFeedbackCard;
