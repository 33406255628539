import { type FC, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import {
  vesselIconLayer,
  vesselPointLayer,
  vesselShapeLayer,
  vesselShapeLayer3D,
  vesselTextLayer,
} from '../layers-config';
import { getVesselsFeatures } from '../utils/layer-utils';
import { type VesselPosition, type VesselViewModel } from '../../../types';
import { type MmsiKey } from '../../../../../global/types';
import { layerSources } from '../constants';
import { useMapContext } from '../../../../../global/state/GlobalProvider';

interface Props {
  zoomLevel: number;
  vesselPositions: VesselPosition[];
  vesselData: Record<MmsiKey, VesselViewModel>;
  targetVesselCoordinates: number[];
  targetVesselMmsi: MmsiKey;
}

const VesselsLayer: FC<Props> = ({
  zoomLevel,
  vesselPositions,
  vesselData,
  targetVesselCoordinates,
  targetVesselMmsi,
}) => {
  const { settings } = useMapContext();
  const features = useMemo(() => {
    const positions = targetVesselMmsi ? vesselPositions.filter(p => p.mmsi !== targetVesselMmsi) : vesselPositions;
    return getVesselsFeatures(positions, vesselData, settings, zoomLevel);
  }, [vesselPositions, zoomLevel, vesselData, targetVesselCoordinates]);

  return (
    <Source
      id={layerSources.vessels}
      type="geojson"
      data={{
        type: 'FeatureCollection',
        features,
      }}
      lineMetrics
    >
      <Layer {...vesselIconLayer} />
      <Layer {...vesselShapeLayer} />
      <Layer {...vesselShapeLayer3D} />
      <Layer {...vesselPointLayer} />
      {settings?.showVesselNames ? <Layer {...vesselTextLayer} /> : null}
    </Source>
  );
};

export default VesselsLayer;
