import { type MouseEvent } from 'react';
import { type NavigationParams, Page } from '../types';

export const navigateOrOpenInNewTab = (
  params: NavigationParams,
  event: MouseEvent,
  navigate: (params: NavigationParams) => void
): void => {
  const url = buildUrl(params);
  if (event.ctrlKey) {
    window.open(`${window.location.origin}${url}`, '_blank');
  } else {
    navigate(params);
  }
};

export const indicatePageTypeByUrl = (url: string): Page => {
  if (url.includes('timestamp')) {
    return Page.Playback;
  }

  return Page.Liveview;
};

export const buildUrl = (params: NavigationParams): string => {
  return `/${params.port}${buildQueryString(params)}`;
};

const buildQueryString = (params: NavigationParams): string => {
  const queryStringParams = new URLSearchParams();

  if (params.mmsi) {
    queryStringParams.append('mmsi', params.mmsi);
  }

  if (params.timestamp?.isValid() ?? false) {
    queryStringParams.append('timestamp', params.timestamp.toSeconds().toString());
  }

  const queryString = queryStringParams.toString();

  return queryString ? `?${queryString}` : '';
};
