import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import colors from '../colors';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys);

const thumbBaseStyle = {
  bg: colors.base.white,
  boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.10)',
};

const trackBaseStyle = {
  bg: colors.gray[100],
  _checked: {
    bg: colors.primary[600],
    _hover: {
      bg: colors.primary[700],
    },
    _disabled: {
      bg: colors.gray[100],
      opacity: 'unset',
    },
  },
  _hover: {
    bg: colors.gray[200],
  },
  _disabled: {
    bg: colors.gray[100],
    opacity: 'unset',
    _hover: {
      bg: colors.gray[100],
    },
  },
};

const baseStyle = definePartsStyle({
  container: {},
  thumb: thumbBaseStyle,
  track: trackBaseStyle,
});

export const switchTheme = defineMultiStyleConfig({
  baseStyle,
});
