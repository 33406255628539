import { type FC, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { futureVesselTrackLayer, futureVesselTrackMarkerLayer } from '../layers-config';
import { getFutureVesselTrackFeatures, getVesselTrackLayerWithGradient } from '../utils/layer-utils';
import { layerSources } from '../constants';
import { type PositionedActivity, type VesselTrackViewModel } from '../types';

interface Props {
  vesselTracks: VesselTrackViewModel[];
  activities: PositionedActivity[];
}

const FutureVesselTracksLayer: FC<Props> = ({ vesselTracks, activities }) => {
  const [features, vesselTrackLayer] = useMemo(
    () => [
      getFutureVesselTrackFeatures(vesselTracks),
      getVesselTrackLayerWithGradient(futureVesselTrackLayer, vesselTracks, activities),
    ],
    [vesselTracks]
  );

  return (
    <Source
      id={layerSources.futureVesselTrack}
      type="geojson"
      data={{
        type: 'FeatureCollection',
        features,
      }}
      lineMetrics
    >
      <>
        <Layer {...vesselTrackLayer} />
        <Layer {...futureVesselTrackMarkerLayer} />
      </>
    </Source>
  );
};

export default FutureVesselTracksLayer;
