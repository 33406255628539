import { useEffect } from 'react';
import { translate } from '../translation';
import { useAppInsights } from '../../hooks/useAppInsights';
import { getEventFactory } from '../utils/get-event-factory';
import { Event } from '../types';
import { indicatePageTypeByUrl } from '../utils/navigation-utils';
import type React from 'react';

interface Props {
  errorMessage: string;
  error: Error;
}

const GlobalExceptionHandlerSimpleModal: React.FC<Props> = ({ errorMessage, error }) => {
  const appInsights = useAppInsights();

  useEffect(() => {
    if (!appInsights) {
      return;
    }

    const url = window.location.href;
    const page = indicatePageTypeByUrl(url);
    const event = getEventFactory(Event.Exception)(page, url, errorMessage, error);
    appInsights.trackAnalyticsEvent(event);
  }, [appInsights, error, errorMessage]);

  const handleReload = (): void => {
    window.location.reload();
  };

  return (
    <div className="global-error-handler-simple-modal">
      <h2 className="text-lg-semibold">{translate('MODAL_GLOBAL_ERROR_HEADER')}</h2>
      <p className="text-md-regular">{translate('MODAL_GLOBAL_ERROR_BODY')}</p>
      <button type="button" onClick={handleReload} className="text-md-medium">
        {translate('MODAL_GLOBAL_ERROR_BTN')}
      </button>
    </div>
  );
};

export default GlobalExceptionHandlerSimpleModal;
