import { useEffect, useMemo } from 'react';
import { type QueryKey, useQuery, useQueryClient } from 'react-query';
import { Timestamp } from '../utils/timestamp';
import { ApiClient } from '../../../api/api-client';
import { getAuthClient } from '../../../global/utils/authUtils';
import { type LiveDataViewModel } from '../types';
import STATUS from '../../../global/statusCodes';
import { Mode } from '../types';

export const LiveviewDataQueryKeyName = 'liveData';

export const usePollLiveData = (operation: string, mode: Mode): LiveDataViewModel => {
  const queryClient = useQueryClient();
  const queryKey: QueryKey = useMemo(() => [LiveviewDataQueryKeyName, operation], [operation]);
  const fetchLiveData = async (): Promise<LiveDataViewModel> => {
    const response = await ApiClient.createAuthenticatedInstance(getAuthClient()).call<LiveDataViewModel>(
      `/api/liveview/${operation}`
    );

    if (response && response.status === STATUS.OK) {
      const { data } = response;

      return data;
    }

    return null;
  };

  useEffect(() => {
    if (mode !== Mode.Liveview || !operation) {
      return;
    }

    const intervalId = setInterval(() => {
      void queryClient.invalidateQueries(queryKey);
    }, Timestamp.fromSeconds(10).valueInMilliseconds);

    return () => {
      clearInterval(intervalId);
    };
  }, [mode, operation]);

  const { data } = useQuery<LiveDataViewModel>(queryKey, fetchLiveData, {
    staleTime: 0,
    cacheTime: 0,
    enabled: mode === Mode.Liveview && !!operation,
  });

  return data;
};
