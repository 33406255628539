import { useQuery } from 'react-query';
import { useEffect } from 'react';
import { ApiClient } from '../../../api/api-client';
import STATUS from '../../../global/statusCodes';
import { getAuthClient } from '../../../global/utils/authUtils';
import { type Settings } from '../components/Map/types';
import { ActionType } from '../../../global/state/reducer';
import { useDispatch } from '../../../global/state/GlobalProvider';

export const useSettingsQuery = (): void => {
  const mapDispatch = useDispatch();

  const callEndpoint = async (): Promise<Settings> => {
    const response = await ApiClient.createAuthenticatedInstance(getAuthClient()).call<Settings>('/api/settings');

    if (response && response.status === STATUS.OK && response.data) {
      return response.data;
    }

    return null;
  };

  const { data, isLoading } = useQuery('useSettings', callEndpoint, { staleTime: Infinity });

  useEffect(() => {
    if (!isLoading) {
      mapDispatch({ type: ActionType.UPDATE_SETTINGS, payload: data });
    }
  }, [data, isLoading]);
};
