import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { last, orderBy } from 'lodash';
import { LiveviewDataQueryKeyName } from './usePollLiveData';
import { ApiClient } from '../../../api/api-client';
import { getAuthClient } from '../../../global/utils/authUtils';
import { type VesselTrackViewModel } from '../components/Map/types';
import { type VesselPosition } from '../types';
import STATUS from '../../../global/statusCodes';
import { Timestamp } from '../utils/timestamp';

const VesselTrackLimitInHours = 2;

export const useLiveVesselTracksData = (
  mmsi: string,
  operation: string,
  currentPosition: VesselPosition,
  enabled: boolean
): VesselTrackViewModel[] => {
  const [vesselTracks, setVesselTracks] = useState<VesselTrackViewModel[]>([]);

  const fetchLiveData = async (): Promise<VesselTrackViewModel[]> => {
    const response = await ApiClient.createAuthenticatedInstance(getAuthClient()).call<VesselTrackViewModel[]>(
      `/api/liveview/tracks/${operation}/${mmsi}`
    );

    if (response && response.status === STATUS.OK) {
      const { data } = response;

      return data;
    }

    return null;
  };

  const shouldQuery = !!mmsi && !!currentPosition && enabled;

  const { data } = useQuery<VesselTrackViewModel[]>(
    [LiveviewDataQueryKeyName, 'tracks', operation, mmsi],
    fetchLiveData,
    {
      staleTime: Timestamp.fromMinutes(10).toMilliseconds(),
      enabled: shouldQuery,
    }
  );

  useEffect(() => {
    setVesselTracks(
      orderBy(data, x => x.timestamp).filter(
        t =>
          t.timestamp >= Timestamp.now().subtract(Timestamp.fromHours(VesselTrackLimitInHours)).toSeconds() &&
          t.timestamp <= currentPosition.timestamp
      )
    );
  }, [data]);

  useEffect(() => {
    if (vesselTracks?.length && currentPosition && last(vesselTracks)?.timestamp !== currentPosition?.timestamp) {
      setVesselTracks(tracks => {
        tracks.push(currentPosition);
        return [...tracks];
      });
    }
  }, [currentPosition]);

  return useMemo(() => {
    if (!vesselTracks?.length || !enabled) {
      return [];
    }

    return vesselTracks;
  }, [vesselTracks, enabled]);
};
