import { useCallback } from 'react';
import { TripsPanelState } from '../../Trips/types';
import { useDispatch, useTripsContext } from '../../../global/state/GlobalProvider';
import { ActionType } from '../../../global/state/reducer';
import { getEventFactory } from '../../../global/utils/get-event-factory';
import { Event, type Page } from '../../../global/types';
import { useAppInsights } from '../../../hooks/useAppInsights';

interface TripsPanelStateManager {
  open: () => void;
  close: () => void;
  toggle: () => void;
  closeToReopen: () => void;
}

export const useTripsPanelStateManager = (
  page: Page,
  isMobileView: boolean,
  operationName: string
): TripsPanelStateManager => {
  const dispatch = useDispatch();
  const appInsights = useAppInsights();
  const { tripsPanelState } = useTripsContext();

  const updateTripsPanelState = (state: TripsPanelState): void => {
    dispatch({
      type: ActionType.SET_TRIPS_PANEL_STATE,
      payload: {
        tripsPanelState: state,
      },
    });
  };

  const closeTripsPanel = useCallback(() => {
    updateTripsPanelState(TripsPanelState.Closed);
  }, [isMobileView]);

  const toggleTripsPanel = useCallback(() => {
    updateTripsPanelState(tripsPanelState === TripsPanelState.Open ? TripsPanelState.Closed : TripsPanelState.Open);
  }, [isMobileView, tripsPanelState]);

  const handleTripsPanelClose = useCallback((): void => {
    const event = getEventFactory(Event.TripsHistoryPanelClosed)(page, operationName);
    appInsights.trackAnalyticsEvent(event);
    closeTripsPanel();
  }, [page, operationName]);

  const handleTripsPanelToggle = useCallback((): void => {
    const eventName =
      tripsPanelState === TripsPanelState.Open || tripsPanelState === TripsPanelState.Reopen
        ? Event.TripsHistoryPanelOpened
        : Event.TripsHistoryPanelClosed;

    const event = getEventFactory(eventName)(page, operationName);
    appInsights.trackAnalyticsEvent(event);
    toggleTripsPanel();
  }, [tripsPanelState, page, operationName]);

  const handleTripsPanelOpen = (): void => {
    updateTripsPanelState(TripsPanelState.Open);
  };

  return {
    close: handleTripsPanelClose,
    open: handleTripsPanelOpen,
    toggle: handleTripsPanelToggle,
    closeToReopen: () => {
      updateTripsPanelState(TripsPanelState.Reopen);
    },
  };
};
