import { ApiClient } from '../../../../../api/api-client';
import { type Action, ActionType } from '../../../../../global/state/reducer';
import { getAuthClient } from '../../../../../global/utils/authUtils';
import { type Settings } from '../types';

export const updateSettings = (settings: Settings, dispatch: React.Dispatch<Action>): void => {
  dispatch({ type: ActionType.UPDATE_SETTINGS, payload: settings });

  const client = ApiClient.createAuthenticatedInstance(getAuthClient());
  void client.call('api/settings', {
    method: 'PUT',
    body: JSON.stringify(settings),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
