import { useMemo } from 'react';
import { usePlaybackVesselTracksData } from './usePlaybackVesselTracksData';
import { useLiveVesselTracksData } from './useLiveVesselTracksData';
import { type VesselTracksViewModel } from '../components/Map/types';
import { type LiveDataViewModel, Mode, type VesselViewModel } from '../types';
import { useMapContext } from '../../../global/state/GlobalProvider';
import { type Timestamp } from '../utils/timestamp';
import { useAppContext } from '../../../global/components/AppContextProvider';

export const useTargetVesselTracks = (
  targetVessel: VesselViewModel,
  currentTimestamp: Timestamp,
  isDraggingScrubber: boolean,
  operationKey: string,
  liveData: LiveDataViewModel
): VesselTracksViewModel => {
  const { settings } = useMapContext();
  const { mode } = useAppContext();
  const playbackTargetVesselTracks = usePlaybackVesselTracksData(
    targetVessel?.mmsi,
    currentTimestamp,
    settings?.showVesselTracks && mode === Mode.Playback,
    isDraggingScrubber
  );

  const targetVesselPosition = useMemo(() => {
    if (targetVessel && liveData?.vesselPositions.some(p => p.mmsi === targetVessel.mmsi)) {
      return liveData?.vesselPositions.find(p => p.mmsi === targetVessel.mmsi);
    }
    return null;
  }, [targetVessel, targetVessel?.mmsi, liveData]);

  const liveviewTargetVesselTracks = useLiveVesselTracksData(
    targetVessel?.mmsi,
    operationKey,
    targetVesselPosition,
    settings?.showVesselTracks && mode === Mode.Liveview
  );

  return useMemo(() => {
    return mode === Mode.Playback
      ? playbackTargetVesselTracks
      : {
          futureTracks: null,
          pastTracks: liveviewTargetVesselTracks,
          activities: [],
        };
  }, [mode, playbackTargetVesselTracks, liveviewTargetVesselTracks]);
};
