import { Box, Text } from '@chakra-ui/react';
import { type FC } from 'react';
import { translate } from '../../../../../global/translation';

interface Props {
  isDismissible: boolean;
  onDismiss: () => void;
}

const EcoRatingTooltip: FC<Props> = ({ isDismissible, onDismiss }) => {
  return (
    <Box>
      <Text className="text-xs-semibold eco-rating-tooltip-text">{translate('ECO_RATING_TOOLTIP_HEADER')}</Text>
      <Text className="text-xs-medium eco-rating-tooltip-text">{translate('ECO_RATING_TOOLTIP_TEXT')}</Text>
      {!!isDismissible && (
        <Text className="text-sm-semibold eco-rating-tooltip-dismiss" onClick={onDismiss}>
          {translate('ECO_RATING_TOOLTIP_DISMISS')}
        </Text>
      )}
    </Box>
  );
};

export default EcoRatingTooltip;
