import { Button, ButtonGroup } from '@chakra-ui/react';
import { type FC } from 'react';
import cx from 'classnames';
import { ActionType } from '../../../../../global/state/reducer';
import { getEventFactory } from '../../../../../global/utils/get-event-factory';
import { Event, Feature, type Page } from '../../../../../global/types';
import { JobTypes } from '../../../../Trips/types';
import { translate } from '../../../../../global/translation';
import { useDispatch, useTripsContext } from '../../../../../global/state/GlobalProvider';
import { useAppInsights } from '../../../../../hooks/useAppInsights';

interface Props {
  page: Page;
}

const JobTypeSelector: FC<Props> = ({ page }) => {
  const { filters } = useTripsContext();
  const tripsDispatch = useDispatch();
  const appInsights = useAppInsights();
  const applyFilterHandler = (value: JobTypes): void => {
    const newFilter = {
      ...filters,
      jobType: value,
    };
    tripsDispatch({
      type: ActionType.UPDATE_FILTER,
      payload: newFilter,
    });
    appInsights.trackAnalyticsEvent(
      getEventFactory(Event.FilteredCompetitors)(Feature.TripsHistoryPanel, page, filters, newFilter)
    );
  };
  return (
    <ButtonGroup spacing="0" isAttached className="job-type-selector">
      <Button
        variant="secondary"
        width="100%"
        className={cx({ 'selected-secondary-btn': filters.jobType === JobTypes.all })}
        onClick={() => {
          applyFilterHandler(JobTypes.all);
        }}
      >
        {translate('ALL_TRIPS')}
      </Button>
      <Button
        variant="secondary"
        width="100%"
        className={cx({ 'selected-secondary-btn': filters.jobType === JobTypes.svitzerTrip })}
        onClick={() => {
          applyFilterHandler(JobTypes.svitzerTrip);
        }}
      >
        {translate('SVITZER')}
      </Button>
      <Button
        variant="secondary"
        width="100%"
        className={cx({ 'selected-secondary-btn': filters.jobType === JobTypes.competitorTrip })}
        onClick={() => {
          applyFilterHandler(JobTypes.competitorTrip);
        }}
      >
        {translate('OTHER')}
      </Button>
    </ButtonGroup>
  );
};

export default JobTypeSelector;
