import { first } from 'lodash';
import { type QueryKey } from 'react-query';
import { type UseQueryOptions } from 'react-query/types/react/types';
import { Timestamp } from './timestamp';
import { Mode, type PlaybackViewModel } from '../types';
import { ApiClient } from '../../../api/api-client';
import { getAuthClient } from '../../../global/utils/authUtils';
import STATUS from '../../../global/statusCodes';
import { getClosestDivisibleNumber } from '../../../global/utils/number-utils';

export const PlaybackFetchInterval: Timestamp = Timestamp.fromMinutes(30);
export const PrefetchBufferSize: Timestamp = Timestamp.fromHours(3);
export const PlaybackDataQueryKeyName = 'playbackData';
export const TripsEcoRatingsQueryKeyName = 'trips-eco-ratings';

export interface TimePair {
  pastTimestamp: Timestamp;
  futureTimestamp: Timestamp;
}

export const getQueryOptions = (
  port: string,
  timestamp: Timestamp,
  mode: Mode,
  customOptions?: UseQueryOptions<PlaybackViewModel>
): UseQueryOptions<PlaybackViewModel> => {
  if (!(timestamp?.isValid() ?? false)) {
    return null;
  }

  const [from, to] = getFetchRange(timestamp);
  const { enabled, ...options } = customOptions ?? { enabled: true };
  return {
    queryKey: getPlaybackDataQueryKey(port, Timestamp.fromSeconds(from)),
    queryFn: getFetchPlaybackDataFunction(from, to, port),
    staleTime: Infinity,
    cacheTime: Timestamp.fromMinutes(15).toMilliseconds(),
    enabled: enabled && mode === Mode.Playback && !!timestamp,
    ...options,
  };
};

export const getPlaybackDataQueryKey = (port: string, timestamp: Timestamp): string[] => [
  PlaybackDataQueryKeyName,
  port,
  timestamp.toDateKey(),
];

export const getFetchPlaybackDataFunction = (
  from: number,
  to: number,
  port: string
): (() => Promise<PlaybackViewModel>) => {
  return async (): Promise<PlaybackViewModel> => {
    const response = await ApiClient.createAuthenticatedInstance(getAuthClient()).call<PlaybackViewModel>(
      `api/playback/${port}/${from}/${to}`
    );
    if (response && response.status === STATUS.OK) {
      const { data } = response;
      return data;
    }

    return null;
  };
};

export const getFetchRange = (timestamp: Timestamp): [number, number] => {
  const intervalInSeconds = PlaybackFetchInterval.toSeconds();
  const result = getClosestDivisibleNumber(timestamp.toSeconds(), intervalInSeconds);

  return result > timestamp.toSeconds() ? [result - intervalInSeconds, result] : [result, result + intervalInSeconds];
};

export const getPrefetchTimePairs = (initialTimestamp: Timestamp): TimePair[] => {
  if (!initialTimestamp) {
    return [];
  }

  const currentTimestamp = Timestamp.fromSeconds(first(getFetchRange(initialTimestamp)));
  const minTimestamp = currentTimestamp.subtract(PrefetchBufferSize);
  const maxTimestamp = currentTimestamp.add(PrefetchBufferSize).add(PlaybackFetchInterval);
  let pastTimestamp = currentTimestamp;
  let futureTimestamp = currentTimestamp;

  const timePairs = [];

  while (
    pastTimestamp.valueInMilliseconds > minTimestamp.valueInMilliseconds &&
    futureTimestamp.valueInMilliseconds < maxTimestamp.valueInMilliseconds
  ) {
    pastTimestamp = pastTimestamp.subtract(PlaybackFetchInterval);
    futureTimestamp = futureTimestamp.add(PlaybackFetchInterval);

    timePairs.push({ pastTimestamp, futureTimestamp });
  }

  return timePairs;
};

export const isPlaybackQueryKey = (queryKey: QueryKey): boolean => queryKeyContains(queryKey, PlaybackDataQueryKeyName);

export const isTripsEcoRatings = (queryKey: QueryKey): boolean =>
  queryKeyContains(queryKey, TripsEcoRatingsQueryKeyName);

const queryKeyContains = (queryKey: QueryKey, value: string): boolean => {
  if (typeof queryKey === 'string') {
    return false;
  }

  return (queryKey as unknown[]).some(part => part === value);
};

export const areQueryKeysEqual = (a: QueryKey, b: QueryKey): boolean => {
  if (typeof a !== typeof b) {
    return false;
  }

  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }

  return true;
};
