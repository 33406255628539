import { extendTheme } from '@chakra-ui/react';
import colors from './colors';
import { buttonTheme } from './components/buttonTheme';
import { selectTheme } from './components/selectTheme';
import { menuTheme } from './components/menuTheme';
import { inputTheme } from './components/inputTheme';
import { textareaTheme } from './components/textareaTheme';
import { switchTheme } from './components/switchTheme';
import { alertTheme } from './components/alertTheme';
import { tooltipTheme } from './components/tooltipTheme';

export const theme = extendTheme({
  styles: {
    global: () => ({
      body: {
        fontFamily: 'Poppins',
      },
    }),
  },
  components: {
    Select: selectTheme,
    Button: buttonTheme,
    Menu: menuTheme,
    Input: inputTheme,
    Textarea: textareaTheme,
    Switch: switchTheme,
    Alert: alertTheme,
    Tooltip: tooltipTheme,
  },
  colors,
});
export default theme;
