import { first, last, sortBy, uniq, uniqBy } from 'lodash';
import { type QueryKey, useIsFetching, useQueryClient } from 'react-query';
import { useEffect, useMemo, useRef, useState } from 'react';
import { type QueryFilters } from 'react-query/types/core/utils';
import {
  type HelmEvent,
  type ActivityViewModel,
  type PlaybackViewModel,
  type ScrubberViewModel,
  type SpeedChartEntry,
} from '../types';
import { ActivityType, type MmsiKey, type TimestampModel } from '../../../global/types';
import { Timestamp } from '../utils/timestamp';
import { PlaybackFetchInterval, isPlaybackQueryKey, getFetchRange } from '../utils/data-fetching-utils';

export const useScrubberData = (mmsi: string): ScrubberViewModel => {
  const [scrubberData, setScrubberData] = useState<Record<MmsiKey, ScrubberViewModel>>({});
  const queryClient = useQueryClient();
  const aggregatedKeysRef = useRef<string[]>([]);

  const fetchingQueriesCount = useIsFetching({
    predicate: q => isPlaybackQueryKey(q.queryKey),
    fetching: true,
  } as QueryFilters);

  useEffect(() => {
    const fetchedQueries = queryClient.getQueriesData<PlaybackViewModel>({
      predicate: q => isPlaybackQueryKey(q.queryKey),
      fetching: false,
      stale: false,
    } as QueryFilters);

    fetchedQueries.forEach(([queryKey, playbackData]) => {
      const dateKey = last(queryKey) as string;
      const keyExists = aggregatedKeysRef.current.some(k => k === dateKey);
      if (playbackData && !keyExists) {
        setScrubberData(data => updateScrubberData(data, playbackData));
        aggregatedKeysRef.current.push(dateKey);
      }
    });
  }, [fetchingQueriesCount]);

  return useMemo<ScrubberViewModel>(() => {
    const data: ScrubberViewModel = scrubberData[mmsi] ?? { activities: [], helmEvents: [] };
    const loadingQueries = queryClient.getQueriesData<PlaybackViewModel>({
      predicate: q => isPlaybackQueryKey(q.queryKey),
      fetching: true,
    } as QueryFilters);
    const loadingActivities = getLoadingActivities(loadingQueries);
    const sortedActivities = sortBy(
      [...data.activities, ...loadingActivities],
      a => a.timestampRange.from.valueInMilliseconds
    );
    const activitiesWithSignalLost = fillSignalLostGaps(sortedActivities);
    const mergedAndSortedActivities = mergeAllActivities(activitiesWithSignalLost);

    return {
      activities: mergedAndSortedActivities,
      helmEvents: filterHelmEvents(data.helmEvents),
    };
  }, [scrubberData, mmsi, fetchingQueriesCount]);
};

const updateScrubberData = (
  previousData: Record<MmsiKey, ScrubberViewModel>,
  playbackModel: PlaybackViewModel
): Record<MmsiKey, ScrubberViewModel> => {
  const newData: Record<MmsiKey, ScrubberViewModel> = { ...previousData };
  const mmsis = uniq([...Object.keys(previousData), ...Object.keys(playbackModel.scrubberData)]);
  if (playbackModel?.scrubberData && typeof playbackModel.scrubberData === 'object') {
    mmsis.forEach(mmsi => {
      const existingActivities = previousData[mmsi]?.activities;
      const existingHelmEvents = previousData[mmsi]?.helmEvents;
      const newActivities = playbackModel.scrubberData[mmsi]?.activities ?? [
        generateSignalLostActivity(playbackModel.timestampRange.from, playbackModel.timestampRange.to),
      ];
      const newHelmEvents = playbackModel.scrubberData[mmsi]?.helmEvents;
      newData[mmsi] = {
        activities: aggregateCollection(existingActivities, newActivities),
        helmEvents: aggregateCollection(existingHelmEvents, newHelmEvents),
      };
    });
  }

  return newData;
};

const getLoadingActivities = (queries: Array<[QueryKey, PlaybackViewModel]>): ActivityViewModel[] => {
  const loadingActivities = [];
  queries.forEach(([queryKey, data]) => {
    if (!data) {
      const dateKey = last(queryKey) as string;
      const fetchRange = getFetchRange(Timestamp.fromString(dateKey));

      const activity = generateLoadingActivity(
        Timestamp.fromSeconds(fetchRange[0]),
        Timestamp.fromSeconds(fetchRange[1])
      );
      loadingActivities.push(activity);
    }
  });

  return loadingActivities;
};

const fillSignalLostGaps = (activities: ActivityViewModel[]): ActivityViewModel[] => {
  const finalActivities = [...activities];
  for (let i = 0; i < activities.length - 1; i++) {
    const endOfCurrentActivity = activities[i].timestampRange.to;
    const startOfNextActivity = activities[i + 1].timestampRange.from;

    if (hasGap(endOfCurrentActivity.valueInMilliseconds, startOfNextActivity.valueInMilliseconds)) {
      const gap = generateSignalLostActivity(endOfCurrentActivity, startOfNextActivity);
      finalActivities.push(gap);
    }
  }

  return finalActivities;
};

const filterHelmEvents = (helmEvents: HelmEvent[]): HelmEvent[] => {
  return uniqBy(helmEvents, e => e.id);
};

const hasGap = (from: number, to: number): boolean => {
  const diffInMs = Timestamp.fromMilliseconds(Math.abs(from - to));
  const diffInMin = Math.round(diffInMs.toMinutes());

  return diffInMin > 1 && diffInMin < PlaybackFetchInterval.toMinutes();
};

const getActivityId = (type: ActivityType, startTime: Timestamp): string =>
  `${type.toString()}-${startTime.toDateKey()}`;

const generateSignalLostActivity = (current: TimestampModel, next: TimestampModel): ActivityViewModel => {
  return {
    activityId: getActivityId(ActivityType.SignalLost, Timestamp.fromTimestampModel(current)),
    type: ActivityType.SignalLost,
    timestampRange: {
      from: current,
      to: next,
    },
    isSpeedLimited: false,
    isTugActivity: false,
    speedChartData: [generateSpeedChartEntry(current, null), generateSpeedChartEntry(next, null)],
  };
};

const generateLoadingActivity = (current: TimestampModel, next: TimestampModel): ActivityViewModel => {
  return {
    activityId: getActivityId(ActivityType.Loading, Timestamp.fromTimestampModel(current)),
    type: ActivityType.Loading,
    timestampRange: {
      from: current,
      to: next,
    },
    isSpeedLimited: false,
    isTugActivity: false,
    speedChartData: [generateSpeedChartEntry(current, null), generateSpeedChartEntry(next, null)],
  };
};

const generateSpeedChartEntry = (timestamp: TimestampModel, speed?: number): SpeedChartEntry => {
  return {
    speed,
    timestamp: Timestamp.fromMilliseconds(timestamp.valueInMilliseconds).toSeconds(),
  };
};

const aggregateCollection = <T>(existingData: T[], newData: T[]): T[] => {
  return [...(newData ?? []), ...(existingData ?? [])];
};

const mergeAllActivities = (allActivities: ActivityViewModel[]): ActivityViewModel[] => {
  const usedActivities: ActivityViewModel[] = [];
  const result: ActivityViewModel[] = [];

  allActivities.forEach(activity => {
    if (usedActivities.includes(activity)) {
      return;
    }

    const mergeResult = mergeIntoSingleActivity(activity, allActivities, usedActivities);
    usedActivities.splice(0);
    usedActivities.push(...mergeResult.usedActivities);
    result.push(mergeResult.activity);
  });

  return result;
};

const mergeIntoSingleActivity = (
  activity: ActivityViewModel,
  allActivities: ActivityViewModel[],
  usedActivities: ActivityViewModel[]
): { activity: ActivityViewModel; usedActivities: ActivityViewModel[] } => {
  const matchingActivities = allActivities.filter(matchCandidate =>
    isMatchingActivity(activity, matchCandidate, usedActivities)
  );

  if (matchingActivities.length > 0) {
    const mergedSpeedChartData = sortBy(
      uniqBy([...activity.speedChartData, ...matchingActivities.flatMap(a => a.speedChartData)], a => a.timestamp),
      s => s.timestamp
    );
    const from = Timestamp.fromSeconds(first(mergedSpeedChartData).timestamp);
    const to = Timestamp.fromSeconds(last(mergedSpeedChartData).timestamp);
    const mergedActivity: ActivityViewModel = {
      ...activity,
      timestampRange: { from, to },
      speedChartData: mergedSpeedChartData,
    };

    return mergeIntoSingleActivity(mergedActivity, allActivities, [...usedActivities, ...matchingActivities]);
  }
  return {
    activity,
    usedActivities: [activity, ...usedActivities],
  };
};

const isMatchingActivity = (
  activity: ActivityViewModel,
  matchCandidate: ActivityViewModel,
  excludedActivities: ActivityViewModel[]
): boolean => {
  return (
    matchCandidate.type === activity.type &&
    (matchCandidate.timestampRange.to.valueInMilliseconds === activity.timestampRange.from.valueInMilliseconds ||
      matchCandidate.timestampRange.from.valueInMilliseconds === activity.timestampRange.to.valueInMilliseconds) &&
    !excludedActivities.includes(matchCandidate)
  );
};
