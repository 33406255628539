/* eslint-disable class-methods-use-this */
import moment, { type Moment } from 'moment-timezone';
import { type TimestampModel } from '../../../global/types';
import { type ClockData } from '../types';
import type DateTimeOffset from './dateTimeOffset';

export class Timestamp implements TimestampModel {
  public static MS_TO_S_RATIO = 1000;

  public static S_TO_M_RATIO = 60;

  public static M_TO_H_RATIO = 60;

  private constructor(public readonly valueInMilliseconds: number) {}

  static now(): Timestamp {
    return new Timestamp(Date.now());
  }

  static fromMilliseconds(milliseconds: number): Timestamp {
    return new Timestamp(milliseconds);
  }

  static fromSeconds(seconds: number): Timestamp {
    return new Timestamp(seconds * Timestamp.MS_TO_S_RATIO);
  }

  static fromMinutes(minutes: number): Timestamp {
    return Timestamp.fromSeconds(minutes * Timestamp.S_TO_M_RATIO);
  }

  static fromHours(hours: number): Timestamp {
    return Timestamp.fromMinutes(hours * Timestamp.M_TO_H_RATIO);
  }

  static fromDate(date: Date): Timestamp {
    return new Timestamp(date.getTime());
  }

  static fromString(dateString: string): Timestamp {
    return new Timestamp(moment.utc(dateString).valueOf());
  }

  static fromTimestampModel(model: TimestampModel): Timestamp {
    return new Timestamp(model.valueInMilliseconds);
  }

  toMilliseconds(): number {
    return this.valueInMilliseconds;
  }

  toSeconds(): number {
    return Math.floor(this.valueInMilliseconds / Timestamp.MS_TO_S_RATIO);
  }

  toMinutes(): number {
    return Math.floor(this.toSeconds() / Timestamp.S_TO_M_RATIO);
  }

  toHours(): number {
    return Math.floor(this.toMinutes() / Timestamp.M_TO_H_RATIO);
  }

  toLocationDateTimeOffset(timezone: string): DateTimeOffset {
    const utcMoment = moment(this.valueInMilliseconds).tz(timezone);
    return this.toDateTimeOffset(utcMoment);
  }

  toBrowserDateTimeOffset(): DateTimeOffset {
    const browserMoment = moment(this.valueInMilliseconds).local(true);
    return this.toDateTimeOffset(browserMoment);
  }

  toDateKey(): string {
    const date = this.toDate();
    return !Number.isNaN(date.valueOf()) ? date.toISOString() : null;
  }

  getClockInformation(timezone: string): ClockData {
    if (!timezone) {
      return null;
    }

    const dateTimeOffset = this.toLocationDateTimeOffset(timezone);
    const { timeString } = dateTimeOffset;
    const offsetInHours = dateTimeOffset.offset.toHours();
    const UTClead = offsetInHours < 0 ? '-' : '+';
    const { abs, trunc } = Math;
    const localTimeZone = `UTC ${UTClead}${abs(trunc(offsetInHours)).toString()}`;
    return {
      time: timeString,
      timezone: localTimeZone,
      dateString: dateTimeOffset.format('MMM DD YYYY'),
    };
  }

  add(timestamp: Timestamp): Timestamp {
    return new Timestamp(this.valueInMilliseconds + timestamp.toMilliseconds());
  }

  subtract(timestamp: Timestamp): Timestamp {
    return new Timestamp(this.valueInMilliseconds - timestamp.toMilliseconds());
  }

  multiply(factor: number): Timestamp {
    return new Timestamp(this.valueInMilliseconds * factor);
  }

  isValid(): boolean {
    return !Number.isNaN(this.valueInMilliseconds);
  }

  toDate(): Date {
    return new Date(this.valueInMilliseconds);
  }

  private toDateTimeOffset(moment: Moment): DateTimeOffset {
    return {
      offset: Timestamp.fromMinutes(moment.utcOffset()),
      timeString: moment.format('HH:mm:ss'),
      dateString: moment.format('ddd MMM DD YYYY'),
      format: (format: string) => moment.format(format),
    };
  }
}
