import { type FC } from 'react';
import {
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Switch,
  Tooltip,
  VStack,
  Text,
  Box,
} from '@chakra-ui/react';
import ChartLayerSelector from './ChartLayerSelector';
import { ReactComponent as ChartsIcon } from '../../../../../../static/svg/Charts.svg';
import { translate } from '../../../../../../global/translation';
import { MapLayer } from '../../types';
import { updateSettings } from '../../utils/chart-settings-utils';
import { useDispatch, useMapContext } from '../../../../../../global/state/GlobalProvider';
import { useAppInsights } from '../../../../../../hooks/useAppInsights';
import { getEventFactory } from '../../../../../../global/utils/get-event-factory';
import { Event, type Page } from '../../../../../../global/types';

export interface Props {
  page: Page;
  operation: string;
}

const ChartSettings: FC<Props> = ({ page, operation }): JSX.Element => {
  const {
    settings,
    settings: { showVesselTracks, showVesselNames, showQuiverPlot, showNauticalMap },
  } = useMapContext();
  const dispatch = useDispatch();
  const appInsights = useAppInsights();

  const handleLayerSelection = (layer: MapLayer): void => {
    updateSettings({ ...settings, layer }, dispatch);
  };

  const handleVesselTracksToggle = (): void => {
    const eventType = !showVesselTracks ? Event.VesselTracksEnabled : Event.VesselTracksDisabled;
    const event = getEventFactory(eventType)(page, operation);
    appInsights.trackAnalyticsEvent(event);
    updateSettings({ ...settings, showVesselTracks: !showVesselTracks }, dispatch);
  };

  const handleVesselNamesToggle = (): void => {
    const eventType = showVesselNames ? Event.DisableVesselNames : Event.EnableVesselNames;
    const event = getEventFactory(eventType)(page, operation);
    appInsights.trackAnalyticsEvent(event);

    updateSettings({ ...settings, showVesselNames: !showVesselNames }, dispatch);
  };

  const handleQuiverPlotToggle = (): void => {
    const eventType = showQuiverPlot ? Event.DisableQuiverPlot : Event.EnableQuiverPlot;
    const event = getEventFactory(eventType)(page, operation);
    appInsights.trackAnalyticsEvent(event);

    updateSettings({ ...settings, showQuiverPlot: !showQuiverPlot }, dispatch);
  };

  const handleNauticalToggle = (): void => {
    const eventType = showNauticalMap ? Event.DisableNauticalMap : Event.EnableNauticalMap;
    const event = getEventFactory(eventType)(page, operation);
    appInsights.trackAnalyticsEvent(event);
    updateSettings({ ...settings, showNauticalMap: !showNauticalMap }, dispatch);
  };

  return (
    <Popover closeOnBlur>
      <Tooltip placement="bottom-start" label={translate('CHART_SETTINGS')}>
        <Box className="chart-settings-trigger-container">
          <PopoverTrigger>
            <IconButton
              variant="secondary"
              className="chart-settings-trigger"
              icon={<ChartsIcon />}
              aria-label={translate('CHART_SETTINGS')}
            />
          </PopoverTrigger>
        </Box>
      </Tooltip>

      <PopoverContent className="chart-settings">
        <PopoverBody>
          <Grid templateColumns="1fr 1fr 1fr 1fr 1fr 1fr">
            <GridItem colSpan={6}>
              <FormLabel className="settings-group-label">{translate('CHART_LAYERS')}</FormLabel>
            </GridItem>
            <GridItem colSpan={1} className="chart-selector-container">
              <ChartLayerSelector chartLayer={MapLayer.Default} onLayerClick={handleLayerSelection} />
            </GridItem>
            <GridItem colSpan={1} className="chart-selector-container">
              <ChartLayerSelector chartLayer={MapLayer.Satellite} onLayerClick={handleLayerSelection} />
            </GridItem>
            <Tooltip
              borderRadius="8px"
              padding="12px"
              placement="left"
              label={
                <Stack>
                  <Text>{translate('NAUTICAL_CHART_HEADING')}</Text>
                  <Text>{translate('NAUTICAL_CHART_TEXT')}</Text>
                </Stack>
              }
            >
              <GridItem colSpan={1} className="chart-selector-container">
                <ChartLayerSelector chartLayer={MapLayer.Nautical} onLayerClick={handleLayerSelection} />
              </GridItem>
            </Tooltip>

            <GridItem />
            <GridItem colSpan={6}>
              <FormLabel className="settings-group-label">{translate('CHART_DETAILS')}</FormLabel>
            </GridItem>
          </Grid>
          <Grid templateColumns="1fr 3fr">
            <GridItem className="chart-setting-container">
              <Switch className="chart-setting" onChange={handleVesselTracksToggle} isChecked={showVesselTracks} />
            </GridItem>
            <GridItem>
              <VStack className="chart-setting">
                <FormLabel className="setting-label">{translate('VESSEL_TRACKS')}</FormLabel>
                <FormLabel className="setting-description">{translate('VESSEL_TRACKS_DESC')}</FormLabel>
              </VStack>
            </GridItem>
            <GridItem className="chart-setting-container">
              <Switch className="chart-setting" onChange={handleVesselNamesToggle} isChecked={showVesselNames} />
            </GridItem>
            <GridItem>
              <VStack className="chart-setting">
                <FormLabel className="setting-label">{translate('VESSEL_NAMES')}</FormLabel>
                <FormLabel className="setting-description">{translate('VESSEL_NAMES_DESC')}</FormLabel>
              </VStack>
            </GridItem>
            <GridItem className="chart-setting-container">
              <Switch disabled className="chart-setting" onChange={handleQuiverPlotToggle} isChecked={showQuiverPlot} />
            </GridItem>
            <Tooltip placement="left" label="Coming soon">
              <GridItem>
                <VStack className="chart-setting" alignItems="start">
                  <FormLabel className="setting-label">{translate('QUIVER_PLOT')}</FormLabel>
                  <FormLabel className="setting-description">{translate('QUIVER_PLOT_DESC')}</FormLabel>
                </VStack>
              </GridItem>
            </Tooltip>
            <GridItem className="chart-setting-container">
              <Switch className="chart-setting" onChange={handleNauticalToggle} isChecked={showNauticalMap} />
            </GridItem>
            <GridItem>
              <VStack className="chart-setting">
                <FormLabel className="setting-label">{translate('NAUTICAL_PLOT')}</FormLabel>
                <FormLabel className="setting-description">{translate('NAUTICAL_PLOT_DESC')}</FormLabel>
              </VStack>
            </GridItem>
          </Grid>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ChartSettings;
