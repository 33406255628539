import { type FC, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { quiverPlotLayer } from '../layers-config';
import { getQuiverPlotFeatures } from '../utils/layer-utils';
import { layerSources } from '../constants';
import { type QuiverPlotArrow } from '../types';

interface Props {
  data: QuiverPlotArrow[];
}

const QuiverPlotLayer: FC<Props> = ({ data }) => {
  const features = useMemo(() => {
    return getQuiverPlotFeatures(data);
  }, [data]);

  return (
    <Source
      id={layerSources.quiverPlot}
      type="geojson"
      data={{
        type: 'FeatureCollection',
        features,
      }}
    >
      <Layer {...quiverPlotLayer} />
    </Source>
  );
};

export default QuiverPlotLayer;
