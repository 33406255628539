import { type FC } from 'react';
import { Layer, Source } from 'react-map-gl';
import { nauticalLayer } from '../layers-config';
import { layerSources } from '../constants';

const NauticalLayer: FC = () => {
  return (
    <Source
      id={layerSources.nautical}
      type="raster"
      tiles={['https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png']}
      tileSize={256}
    >
      <Layer {...nauticalLayer} />
    </Source>
  );
};

export default NauticalLayer;
