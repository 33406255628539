import { useMemo, useRef, type FC } from 'react';
import { Text, Tooltip, Stack } from '@chakra-ui/react';
import { HelmEventTypeMap, markerSizeInPixels } from './constants';
import { type VesselViewModel, type HelmEventMarkerData } from '../../types';
import { Timestamp } from '../../utils/timestamp';
import { Event } from '../../../../global/types';
import { useAppInsights } from '../../../../hooks/useAppInsights';
import { Timer } from '../../utils/timer';
import { getEventFactory } from '../../../../global/utils/get-event-factory';

interface Props {
  marker: HelmEventMarkerData;
  timezone: string;
  vessel: VesselViewModel;
}

const HelmEventMarker: FC<Props> = ({ marker, timezone, vessel }) => {
  const appInsights = useAppInsights();
  const timer = useRef(new Timer());
  const eventTimestamp = Timestamp.fromMilliseconds(marker.timestamp.valueInMilliseconds);

  const context = useMemo(() => {
    return HelmEventTypeMap[marker.type];
  }, [marker.type]);

  const canSendEvent = (): boolean => {
    return timer.current.elapsed.toSeconds() >= 2;
  };

  const handleOpen = (): void => {
    timer.current.reset();
    timer.current.start();
  };

  const handleClose = (): void => {
    timer.current.stop();

    if (canSendEvent()) {
      const aiEvent = getEventFactory(Event.TimelineMarkerHovered)(
        eventTimestamp,
        window.location.href,
        marker.mmsi,
        vessel.name
      );
      appInsights.trackAnalyticsEvent(aiEvent);
    }
    timer.current.reset();
  };

  const tooltipLabel = useMemo(() => {
    const dateTimeOffset = eventTimestamp.toLocationDateTimeOffset(timezone);
    return (
      <Stack className="scrubber-marker-tooltip">
        <Text>{context.tooltipHeaderFactory(marker)}</Text>
        <Text>{marker.assistedVesselName}</Text>
        <Text>{dateTimeOffset.format('HH:mm')}</Text>
      </Stack>
    );
  }, [marker, context]);

  return (
    <Tooltip label={tooltipLabel} placement="top" onOpen={handleOpen} onClose={handleClose}>
      <div className="helm-event-marker-container" style={{ left: `${marker.offset}px` }}>
        <context.icon key={marker.id} height={markerSizeInPixels} width={markerSizeInPixels} />
      </div>
    </Tooltip>
  );
};

export default HelmEventMarker;
