import { useMemo, type FC } from 'react';
import { FormLabel, HStack, Image, VStack } from '@chakra-ui/react';
import cx from 'classnames';
import { type TranslationKey, translate } from '../../../../../../global/translation';
import DefaultMap from '../../../../../../static/img/default-map.png';
import SatelliteMap from '../../../../../../static/img/satellite-map.png';
import NauticalMap from '../../../../../../static/img/nautical-map.png';
import { MapLayer } from '../../types';
import { useMapContext } from '../../../../../../global/state/GlobalProvider';

export interface Props {
  chartLayer: MapLayer;
  onLayerClick: (layer: MapLayer) => void;
}

const ChartLayerSelector: FC<Props> = ({ chartLayer, onLayerClick }): JSX.Element => {
  const { settings } = useMapContext();
  const classes = useMemo(
    () =>
      cx('chart-map-selector', {
        selected: settings?.layer === chartLayer,
      }),
    [settings?.layer]
  );

  const labelClasses = useMemo(
    () =>
      cx('chart-map-selector-label', {
        selected: settings?.layer === chartLayer,
      }),
    [settings?.layer]
  );

  const getLayerName = (): TranslationKey => {
    switch (chartLayer) {
      case MapLayer.Default:
        return 'DEFAULT_LAYER';
      case MapLayer.Satellite:
        return 'SATELLITE_LAYER';
      case MapLayer.Nautical:
        return 'NAUTICAL_LAYER';
      default:
        return 'DEFAULT_LAYER';
    }
  };

  const getLayerImage = (): string => {
    switch (chartLayer) {
      case MapLayer.Default:
        return DefaultMap;
      case MapLayer.Satellite:
        return SatelliteMap;
      case MapLayer.Nautical:
        return NauticalMap;
      default:
        return MapLayer.Default;
    }
  };

  return (
    <HStack
      className={classes}
      onClick={() => {
        onLayerClick(chartLayer);
      }}
    >
      <VStack>
        <Image className="chart-map-selector-icon" src={getLayerImage()} />
        <FormLabel className={labelClasses}>{translate(getLayerName())}</FormLabel>
      </VStack>
    </HStack>
  );
};

export default ChartLayerSelector;
