import { type FC, useMemo } from 'react';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { speeds } from './constants';
import { type Speed } from './types';
import { useAppInsights } from '../../../../hooks/useAppInsights';
import { getEventFactory } from '../../../../global/utils/get-event-factory';
import { Event } from '../../../../global/types';
import { type Timestamp } from '../../utils/timestamp';
import { type VesselViewModel } from '../../types';
import { translate } from '../../../../global/translation';

interface Props {
  currentSpeed: Speed;
  timestamp: Timestamp;
  vessel: VesselViewModel;
  setCurrentSpeed: (currentSpeed: Speed) => void;
}

export const SpeedSwitch: FC<Props> = ({ currentSpeed, timestamp, vessel, setCurrentSpeed }) => {
  const appInsights = useAppInsights();

  const onSpeedSwitchClick = (): void => {
    setCurrentSpeed(newSpeed);

    const event = getEventFactory(Event.TimelineSpeedChanged)(
      timestamp,
      window.location.href,
      vessel?.mmsi,
      vessel?.name,
      newSpeed.displayName
    );
    appInsights.trackAnalyticsEvent(event);
  };

  const newSpeed = useMemo((): Speed => {
    return speeds.find(s => s.displayName === currentSpeed.nextSpeed);
  }, [speeds, currentSpeed]);

  return (
    <Tooltip label={currentSpeed.displayName} isDisabled={false}>
      <IconButton
        aria-label={translate('SPEED_SWITCH')}
        variant="secondary"
        onClick={onSpeedSwitchClick}
        icon={<currentSpeed.icon />}
      />
    </Tooltip>
  );
};
