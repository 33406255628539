import { type MapboxGeoJSONFeature, type MapLayerMouseEvent, type LngLat } from 'mapbox-gl';
import { find, minBy } from 'lodash';
import { distance, point } from '@turf/turf';
import { hasLayer } from './layer-utils';
import { type Coordinates, type Location } from '../../../../../global/types';
import { type VesselPosition } from '../../../types';
import {
  futureVesselTrackMarkerLayer,
  pastVesselTrackMarkerLayer,
  targetVesselIconLayer,
  targetVesselShapeLayer,
  targetVesselShapeLayer3D,
  vesselIconLayer,
  vesselShapeLayer,
  vesselShapeLayer3D,
} from '../layers-config';
import { type TidalArrowFeatureProperties } from '../types';

const vesselLayers = [
  vesselShapeLayer.id,
  vesselIconLayer.id,
  vesselShapeLayer3D.id,
  targetVesselIconLayer.id,
  targetVesselShapeLayer.id,
  targetVesselShapeLayer3D.id,
];
const vesselTrackMarkersLayers = [pastVesselTrackMarkerLayer.id, futureVesselTrackMarkerLayer.id];

export const getCenter = (vesselPositions: VesselPosition[], targetVesselMmsi: string): LngLat => {
  const { lat, lon } = getVesselLocation(vesselPositions, targetVesselMmsi);
  return {
    lat,
    lng: lon,
  } as LngLat;
};

const getVesselLocation = (vesselPositions: VesselPosition[], targetVesselMmsi: string): Coordinates => {
  const {
    location: { coordinates },
  } = find(vesselPositions, p => p.mmsi === targetVesselMmsi);

  return coordinates;
};

export const getAvailableVesselLayers = (map: mapboxgl.Map): string[] => {
  return vesselLayers.filter(layer => hasLayer(map, layer));
};

export const getAvailableVesselTrackMarkerLayers = (map: mapboxgl.Map): string[] => {
  return vesselTrackMarkersLayers.filter(layer => hasLayer(map, layer));
};

export const getClosestQuiverPlotArrow = (
  quiverPlotArrows: MapboxGeoJSONFeature[],
  event: MapLayerMouseEvent
): TidalArrowFeatureProperties => {
  const arrowsWithDistanceToClickedPoint = quiverPlotArrows.map(arrow => {
    const locationString = (arrow.properties as TidalArrowFeatureProperties).location;
    const {
      coordinates: { lon: arrowLon, lat: arrowLat },
    } = JSON.parse(locationString.toString()) as Location;
    const { lng: clickLon, lat: clickLat } = event.lngLat;
    const distanceToArrow = distance(point([arrowLon, arrowLat]), point([clickLon, clickLat]));
    return { ...arrow, distanceToClickedPoint: distanceToArrow };
  });
  const closestArrow = minBy(arrowsWithDistanceToClickedPoint, a => a.distanceToClickedPoint);
  return closestArrow.properties as TidalArrowFeatureProperties;
};
