import { type FC } from 'react';
import { Button } from '@chakra-ui/react';
import cx from 'classnames';
import { ReactComponent as PlayIcon } from '../../../../static/svg/PlayIconWhite.svg';
import { ReactComponent as PauseIcon } from '../../../../static/svg/PauseIconWhite.svg';
import { useAppInsights } from '../../../../hooks/useAppInsights';
import { Event } from '../../../../global/types';
import { getEventFactory } from '../../../../global/utils/get-event-factory';
import { type Timestamp } from '../../utils/timestamp';
import { type VesselViewModel } from '../../types';
import { translate } from '../../../../global/translation';

interface Props {
  isPlaying: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  vessel: VesselViewModel;
  timestamp: Timestamp;
}

const PlayButton: FC<Props> = ({ isPlaying, setIsPlaying, vessel, timestamp }) => {
  const appInsights = useAppInsights();

  const togglePlay = (): void => {
    setIsPlaying(!isPlaying);
    const eventType = isPlaying ? Event.PlaybackPaused : Event.PlaybackStarted;
    const event = getEventFactory(eventType)(vessel?.mmsi, window.location.href, timestamp);
    appInsights.trackAnalyticsEvent(event);
  };

  return (
    <div>
      <Button
        aria-label={translate('PLAY')}
        leftIcon={isPlaying ? <PauseIcon /> : <PlayIcon />}
        className={cx('play-button', { playing: isPlaying })}
        onClick={togglePlay}
      >
        {translate(isPlaying ? 'PAUSE' : 'PLAY')}
      </Button>
    </div>
  );
};

export default PlayButton;
