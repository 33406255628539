import { useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { ToasterVariant } from '../global/types';

interface Props {
  title: string;
  variant: ToasterVariant;
  description?: string;
}

const variantToStatus: Record<ToasterVariant, 'success' | 'warning' | 'error' | 'loading'> = {
  [ToasterVariant.Success]: 'success',
  [ToasterVariant.Warning]: 'warning',
  [ToasterVariant.Error]: 'error',
  [ToasterVariant.Loading]: 'loading',
};

export function useToaster(): (toastState: Props) => void {
  const [state, setState] = useState<Props | null>(null);
  const toast = useToast();

  const showToast = (toastState: Props): void => {
    setState(toastState);
  };

  useEffect(() => {
    if (state) {
      const { title, variant, description } = state;

      toast({
        title,
        position: 'top',
        variant,
        description,
        status: variantToStatus[variant],
        duration: 3000,
        isClosable: true,
      });

      setState(null);
    }
  }, [state, toast]);

  return showToast;
}
