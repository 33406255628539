import { type FunctionComponent } from 'react';
import cx from 'classnames';
import { ReactComponent as ProgressNegative } from '../../../../../static/svg/ProgressNegative.svg';
import { ReactComponent as ProgressPositive } from '../../../../../static/svg/ProgressPositive.svg';

interface Props {
  progress: number;
}

const ProgressStatus: FunctionComponent<Props> = ({ progress }) => {
  const containerClasses = cx('progress-status-container', {
    positive: progress > 0,
    negative: progress < 0,
  });

  const valueClasses = cx('progress-value', 'text-sm-medium', {
    positive: progress > 0,
    negative: progress < 0,
  });

  const icon = progress > 0 ? <ProgressPositive /> : <ProgressNegative />;

  return (
    !!progress && (
      <div className={containerClasses}>
        <div className="progress-icon">{icon}</div>
        <div className={valueClasses}>{progress}</div>
      </div>
    )
  );
};

export default ProgressStatus;
