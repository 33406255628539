export class LocalStorageService {
  private readonly storage: Storage;

  constructor() {
    this.storage = window.localStorage;
  }

  public setItem(key: string, value: any): void {
    const serializedValue = JSON.stringify(value);
    this.storage.setItem(key, serializedValue);
  }

  public getItem<T>(key: string): T | null {
    const serializedValue = this.storage.getItem(key);
    if (serializedValue === null) {
      return null;
    }
    const deserializedValue = JSON.parse(serializedValue);
    return deserializedValue as T;
  }
}

const localStorageService = new LocalStorageService();
export default localStorageService;
