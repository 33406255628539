import { type Timestamp } from '../pages/Playback/utils/timestamp';

type VoidFunction = (...args: any[]) => void;

export const useDebouncing = (callback: VoidFunction, wait: Timestamp): VoidFunction => {
  let timeout: NodeJS.Timeout;
  return (args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      callback(args);
    }, wait.toMilliseconds());
  };
};
