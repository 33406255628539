/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useQuery } from 'react-query';
import { useEffect } from 'react';
import { ApiClient } from '../../../api/api-client';
import STATUS from '../../../global/statusCodes';
import { getAuthClient } from '../../../global/utils/authUtils';
import { useDispatch } from '../../../global/state/GlobalProvider';
import { type ActionType } from '../../../global/state/reducer';
import { Timestamp } from '../../Playback/utils/timestamp';

type QueryKey = string | Array<string | number>;

interface RequestOptions {
  actionType: ActionType;
  endpoint: string;
  queryKey: QueryKey;
}

export const useGenericQuery = (options: RequestOptions): void => {
  const tripsDispatch = useDispatch();

  const callEndpoint = async () => {
    const response = await ApiClient.createAuthenticatedInstance(getAuthClient()).call(options.endpoint);

    if (response && response.status === STATUS.OK && response.data) {
      return response.data;
    }

    return [];
  };

  const { data, isLoading } = useQuery(options.queryKey, callEndpoint, {
    staleTime: Timestamp.fromMinutes(10).toMilliseconds(),
  });

  useEffect(() => {
    if (!isLoading) {
      tripsDispatch({ type: options.actionType, payload: data });
    }
  }, [data, isLoading, options.actionType]);
};
