import { type FC, useRef, useMemo, useEffect } from 'react';
import cx from 'classnames';
import { Popover, PopoverBody, PopoverContent } from '@chakra-ui/react';
import PickTime from './PickTime';
import { ModalsEvent, Modal as ModalType, Modal } from './types';
import VesselSearch from './VesselSearch';
import Trigger from './Trigger';
import QuickAccessHeaderSelected from './QuickAccessHeaderSelected';
import QuickAccessHeaderDefault from './QuickAccessHeaderDefault';
import QuickAccessBody from './QuickAccessBody';
import { useModalsState } from './useModalsState';

import { Event, type Page } from '../../../../../../global/types';
import { Timestamp } from '../../../../utils/timestamp';
import { Mode, type VesselPosition, type VesselViewModel } from '../../../../types';
import { useAppInsights } from '../../../../../../hooks/useAppInsights';
import { getEventFactory } from '../../../../../../global/utils/get-event-factory';
import { formatSpeed, getSpeedText, getTextForSpeedToolTip } from '../../../../utils/speed-utils';
import { useAppContext } from '../../../../../../global/components/AppContextProvider';

export interface Props {
  operation: string;
  timestamp: Timestamp;
  vesselData: VesselViewModel[];
  selectedVesselMmsi: string;
  onTargetVesselChange: (mmsi: string) => void;
  toggleTripsPanel: () => void;
  targetVessel: VesselViewModel;
  vesselPositions: VesselPosition[];
  setTimestamp: (timestamp: Timestamp) => void;
  setStartTimestamp: (timestamp: Timestamp) => void;
  page: Page;
  targetVesselMmsi: string;
  isLockedOnTarget: boolean;
  timezone: string;
  dateString: string;
  startTimestamp: Timestamp;
}

type ToggleEvent =
  | Event.QuickAccessPanelOpened
  | Event.QuickAccessPanelClosed
  | Event.PickTimeOpened
  | Event.VesselSearchOpened;

const QuickAccessPanel: FC<Props> = ({
  operation,
  timestamp,
  vesselData,
  selectedVesselMmsi,
  onTargetVesselChange,
  toggleTripsPanel,
  targetVessel,
  vesselPositions,
  setTimestamp,
  setStartTimestamp,
  page,
  targetVesselMmsi,
  isLockedOnTarget,
  timezone,
  dateString,
  startTimestamp,
}): JSX.Element => {
  const { mode } = useAppContext();
  const initialModal = mode === Mode.Liveview ? Modal.QuickAccess : Modal.None;
  const { modalStates, dispatch } = useModalsState(initialModal);
  const appInsights = useAppInsights();
  const containerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (mode === Mode.Playback) {
      dispatch(ModalsEvent.CloseQuickAccess);
    }

    if (mode === Mode.Liveview) {
      dispatch(ModalsEvent.OpenQuickAccess);
    }
  }, [mode]);

  const targetVesselPosition = useMemo(() => {
    if (targetVessel && vesselPositions.some(p => p.mmsi === targetVessel.mmsi)) {
      return vesselPositions.find(p => p.mmsi === targetVessel.mmsi);
    }
    return null;
  }, [targetVessel, targetVessel?.mmsi, vesselPositions]);
  const speed = targetVesselPosition?.speed;
  const formattedSpeed = useMemo(() => formatSpeed(speed), [speed]);
  const speedType = useMemo(() => getSpeedText(speed), [speed]);
  const toolTipText = useMemo(() => getTextForSpeedToolTip(speed), [speed]);

  const handleToggle = (modalEvent: ModalsEvent, eventType: ToggleEvent): void => {
    const event = getEventFactory(eventType)(page, operation, Timestamp.now());
    appInsights.trackAnalyticsEvent(event);
    dispatch(modalEvent);
  };

  const handleQuickAccessOpen = (): void => {
    handleToggle(ModalsEvent.OpenQuickAccess, Event.QuickAccessPanelOpened);
  };

  const handleQuickAccessClose = (): void => {
    handleToggle(ModalsEvent.CloseQuickAccess, Event.QuickAccessPanelClosed);
  };

  const handlePickTimeOpen = (): void => {
    handleToggle(ModalsEvent.OpenPickTime, Event.PickTimeOpened);
  };

  const handleVesselSearchOpen = (): void => {
    handleToggle(ModalsEvent.OpenVesselSearch, Event.VesselSearchOpened);
  };

  const headerClasses = cx({
    'quick-access-header-panel-playback': mode === Mode.Playback,
    'quick-access-header-panel-live-view': mode === Mode.Liveview,
  });

  const contentClasses = cx('quick-access-panel', {
    'quick-access-content-panel-playback': mode === Mode.Playback,
    'quick-access-content-panel-live-view': mode === Mode.Liveview,
  });

  return (
    <div className="quick-access-panel-container" ref={containerRef}>
      <Popover closeOnBlur={false} isOpen={modalStates[ModalType.QuickAccess].isOpen} onClose={handleQuickAccessClose}>
        <Trigger
          selectedVesselMmsi={selectedVesselMmsi}
          targetVessel={targetVessel}
          formattedSpeed={formattedSpeed}
          speedType={speedType}
          handleQuickAccessOpen={handleQuickAccessOpen}
          hidden={modalStates[ModalType.QuickAccess].isOpen}
          toolTipText={toolTipText}
          className={headerClasses}
          targetVesselMmsi={targetVesselMmsi}
          isLockedOnTarget={isLockedOnTarget}
          onTargetVesselChange={onTargetVesselChange}
        />
        <PopoverContent className={contentClasses}>
          <PopoverBody>
            {selectedVesselMmsi && mode === Mode.Liveview ? (
              <>
                <QuickAccessHeaderSelected
                  targetVessel={targetVessel}
                  formattedSpeed={formattedSpeed}
                  speedType={speedType}
                  handleQuickOperation={handleQuickAccessClose}
                  className=""
                  toolTipText={toolTipText}
                  isCollapsed
                  targetVesselMmsi={targetVesselMmsi}
                  isLockedOnTarget={isLockedOnTarget}
                  onTargetLock={onTargetVesselChange}
                />
                <QuickAccessBody
                  handleVesselSearchOpen={handleVesselSearchOpen}
                  toggleTripsPanel={toggleTripsPanel}
                  handlePickTimeOpen={handlePickTimeOpen}
                  dateString={dateString}
                  page={page}
                  targetVessel={targetVessel}
                  startTimestamp={startTimestamp}
                  timestamp={timestamp}
                  timezone={timezone}
                />
              </>
            ) : (
              <>
                <QuickAccessHeaderDefault iconAction={handleQuickAccessClose} />
                <QuickAccessBody
                  handleVesselSearchOpen={handleVesselSearchOpen}
                  toggleTripsPanel={toggleTripsPanel}
                  handlePickTimeOpen={handlePickTimeOpen}
                  dateString={dateString}
                  page={page}
                  targetVessel={targetVessel}
                  startTimestamp={startTimestamp}
                  timestamp={timestamp}
                  timezone={timezone}
                />
              </>
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <PickTime
        modalState={modalStates[ModalType.PickTime]}
        operation={operation}
        selectedVesselMmsi={selectedVesselMmsi}
        dispatch={dispatch}
        locatedAtBottom={mode === Mode.Playback}
        selectedTimestamp={timestamp}
        setTimestamp={setTimestamp}
        setStartTimestamp={setStartTimestamp}
        page={page}
        timezone={timezone}
      />
      <VesselSearch
        operation={operation}
        page={page}
        timestamp={timestamp}
        vessels={vesselData}
        modalState={modalStates[ModalType.VesselSearch]}
        containerRef={containerRef}
        dispatch={dispatch}
        onTargetVesselChange={onTargetVesselChange}
      />
    </div>
  );
};

export default QuickAccessPanel;
