import { type FC, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { tidalDiamondLayer } from '../layers-config';
import { getTidalDiamondsFeatures } from '../utils/layer-utils';
import { layerSources } from '../constants';
import { type TidalDiamond } from '../types';

interface Props {
  data: TidalDiamond[];
  activeTidalDiamond: string;
}

const TidalDiamondLayer: FC<Props> = ({ data, activeTidalDiamond }) => {
  const features = useMemo(() => {
    return getTidalDiamondsFeatures(data, activeTidalDiamond);
  }, [data, activeTidalDiamond]);

  return (
    <Source
      id={layerSources.tidalDiamonds}
      type="geojson"
      data={{
        type: 'FeatureCollection',
        features,
      }}
    >
      <Layer {...tidalDiamondLayer} />
    </Source>
  );
};

export default TidalDiamondLayer;
