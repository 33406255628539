import { useEffect, useState, useRef, type FunctionComponent } from 'react';
import PlayButton from './PlayButton';
import ZoomButtons from './ZoomButtons';
import RecenterButton from './RecenterButton';
import { SpeedSwitch } from './SpeedSwitch';
import { speeds } from './constants';
import LiveViewButton from '../LiveViewButton';
import { Timestamp } from '../../utils/timestamp';
import type { VesselViewModel } from '../../types';

interface Props {
  isPlaying: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  vessel: VesselViewModel;
  timestamp: Timestamp;
  setTimestamp: (timestamp: Timestamp) => void;
  zoomLevel: number;
  setZoomLevel: (zoomLevel: number) => void;
  switchToLiveview: () => void;
  isLockedOnTarget: boolean;
  onTargetLock: (mmsi: string) => void;
}

const ScrubberControls: FunctionComponent<Props> = ({
  isPlaying,
  setIsPlaying,
  vessel,
  timestamp,
  setTimestamp,
  zoomLevel,
  setZoomLevel,
  switchToLiveview,
  isLockedOnTarget,
  onTargetLock,
}) => {
  const [currentSpeed, setCurrentSpeed] = useState(speeds[1]);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isPlaying) {
      if (timeout.current) {
        clearInterval(timeout.current);
      }
      timeout.current = setInterval(() => {
        const newTimestamp = timestamp.add(Timestamp.fromSeconds(currentSpeed.refreshInterval));
        setTimestamp(newTimestamp);
      }, currentSpeed.timeoutInMilliseconds);
    }

    return () => {
      if (timeout.current) {
        clearInterval(timeout.current);
        timeout.current = null;
      }
    };
  }, [isPlaying, currentSpeed, timestamp, setTimestamp]);

  return (
    <div className="scrubber-controls-container">
      <PlayButton isPlaying={isPlaying} setIsPlaying={setIsPlaying} timestamp={timestamp} vessel={vessel} />
      <SpeedSwitch
        currentSpeed={currentSpeed}
        timestamp={timestamp}
        vessel={vessel}
        setCurrentSpeed={setCurrentSpeed}
      />
      <RecenterButton vessel={vessel} isLockedOnTarget={isLockedOnTarget} onTargetLock={onTargetLock} />
      <ZoomButtons zoomLevel={zoomLevel} setZoomLevel={setZoomLevel} timestamp={timestamp} vessel={vessel} />
      <LiveViewButton vessel={vessel} timestamp={timestamp} switchToLiveview={switchToLiveview} />
    </div>
  );
};

export default ScrubberControls;
