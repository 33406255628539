import { find, first, last, orderBy } from 'lodash';
import { type ExpressionSpecification } from 'mapbox-gl';
import { distance, point } from '@turf/turf';
import { type Feature, type Point } from 'geojson';
import { type VesselTrackViewModel } from '../types';
import colors from '../../../../../theme/colors';
import { getSpeedColor } from '../../../utils/speed-utils';
import { ActivityType, type Location } from '../../../../../global/types';
import { isTimestampWithinRange } from '../../../utils/timestamp-range-utils';
import { Timestamp } from '../../../utils/timestamp';
import { type BaseActivity } from '../../../types';
import { isUnderOrdersActivity } from '../../../utils/isUnderOrdersActivity';

interface VesselTrack extends VesselTrackViewModel {
  distanceDelta: number;
}

interface VesselTrackRange {
  tracks: VesselTrack[];
  color: string;
}

type GradientComponents = Array<string | number>;

export const mapTracksToGradient: (
  tracks: VesselTrackViewModel[],
  activities: BaseActivity[]
) => ExpressionSpecification = (tracks: VesselTrackViewModel[], activities) => {
  if (!tracks?.length) {
    return generateGradient(getDefaultGradient());
  }

  const gradientComponents: GradientComponents = [];
  const orderedTracks = getTrackData(tracks);
  const trackRanges = aggregateTracksInRanges(orderedTracks, activities);
  const totalDistance = calculateDistanceFromStart(orderedTracks, last(orderedTracks));
  orderBy(trackRanges, r => first(r.tracks).timestamp).forEach(range => {
    extendGradientForRange(range, orderedTracks, totalDistance, gradientComponents);
  });

  return generateGradient(gradientComponents);
};

const getDefaultGradient = (): GradientComponents => {
  return [0, 'transparent', 1, 'transparent'];
};

const generateGradient = (components: GradientComponents): ExpressionSpecification => {
  return ['interpolate', ['linear'], ['line-progress'], ...components];
};

const getTrackData = (tracks: VesselTrackViewModel[]): VesselTrack[] => {
  return orderBy(tracks, t => t.timestamp).map((v, i, a) => {
    const previous = i === 0 ? null : a[i - 1];
    return {
      ...v,
      distanceDelta: previous ? getDistance(previous.location, v.location) : 0,
    } as VesselTrack;
  });
};

const extendGradientForRange = (
  range: VesselTrackRange,
  allTracks: VesselTrack[],
  totalDistance: number,
  gradientComponents: GradientComponents
): void => {
  const orderedRange = orderBy(range.tracks, t => t.timestamp);

  const firstTrack = first(orderedRange);
  extendGradientForTrack(firstTrack, range.color, allTracks, totalDistance, gradientComponents);

  const lastTrack = last(orderedRange);
  extendGradientForTrack(lastTrack, range.color, allTracks, totalDistance, gradientComponents);
};

const extendGradientForTrack = (
  track: VesselTrack,
  trackColor: string,
  allTracks: VesselTrack[],
  totalDistance: number,
  gradient: GradientComponents
): void => {
  const trackDistance = calculateDistanceFromStart(allTracks, track);
  const trackWeight = trackDistance / totalDistance;

  if (!gradient.some(v => v === trackWeight)) {
    gradient.push(trackWeight, trackColor);
  }
};

const aggregateTracksInRanges = (trackData: VesselTrack[], activities: BaseActivity[]): VesselTrackRange[] => {
  return trackData.reduce<VesselTrackRange[]>((accumulator, currentEntry) => {
    const currentActivity = find(activities, a =>
      isTimestampWithinRange(Timestamp.fromSeconds(currentEntry.timestamp), a.timestampRange)
    );

    const currentColor = isUnderOrdersActivity(currentActivity?.type ?? ActivityType.Uncertain)
      ? colors.gray[400]
      : getSpeedColor(currentEntry.speed);

    if (!accumulator.length) {
      accumulator.push({
        tracks: [currentEntry],
        color: currentColor,
      });
      return accumulator;
    }

    const lastRange = last(accumulator);

    if (lastRange.color === currentColor) {
      const rangeIndex = accumulator.indexOf(lastRange);
      lastRange.tracks.push(currentEntry);
      accumulator[rangeIndex] = lastRange;
    } else {
      accumulator.push({
        tracks: [currentEntry],
        color: currentColor,
      });
    }

    return accumulator;
  }, []);
};

const calculateDistanceFromStart = (tracks: VesselTrack[], track: VesselTrack): number => {
  const trackIndex = tracks.findIndex(t => t.timestamp === track.timestamp);
  return tracks
    .filter((t, i) => i <= trackIndex)
    .reduce((accumulator, current) => {
      return accumulator + Math.abs(current.distanceDelta);
    }, 0);
};

const getDistance = (location1: Location, location2: Location): number => {
  const point1 = getPoint(location1);
  const point2 = getPoint(location2);
  return distance(point1, point2);
};

const getPoint = (location: Location): Feature<Point> => {
  const {
    coordinates: { lon, lat },
  } = location;
  return point([lon, lat]);
};
