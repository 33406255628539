import { useQuery, type UseQueryResult } from 'react-query';
import { ApiClient } from '../../../api/api-client';
import { getAuthClient } from '../../../global/utils/authUtils';
import { type TideDataViewModel } from '../components/Map/types';
import { Timestamp } from '../utils/timestamp';
import STATUS from '../../../global/statusCodes';
import { useMapContext } from '../../../global/state/GlobalProvider';

export const useTidalData = (operation: string, timestamp: Timestamp): UseQueryResult<TideDataViewModel> => {
  const dateTimeKey = timestamp?.toDateKey();
  const { settings } = useMapContext();

  const callEndpoint = async (): Promise<TideDataViewModel> => {
    if (!dateTimeKey) {
      return null;
    }

    const response = await ApiClient.createAuthenticatedInstance(getAuthClient()).call<TideDataViewModel>(
      `api/tides?operation=${operation}&dateTimeKey=${dateTimeKey}`
    );
    return response.status === STATUS.OK ? response.data : null;
  };

  return useQuery<TideDataViewModel, Error>([operation, dateTimeKey], callEndpoint, {
    staleTime: Timestamp.fromHours(2).toMilliseconds(),
    enabled: settings?.showQuiverPlot ?? false,
  });
};
