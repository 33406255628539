import { useEffect } from 'react';
import { type QueryClient, useQueryClient } from 'react-query';
import { LiveviewDataQueryKeyName } from './usePollLiveData';
import { Mode } from '../types';
import { PlaybackDataQueryKeyName } from '../utils/data-fetching-utils';

export const useClearCachedData = (mode: Mode): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    if (mode === Mode.Playback) {
      removeQueriesIncluding(queryClient, LiveviewDataQueryKeyName);
    } else {
      removeQueriesIncluding(queryClient, PlaybackDataQueryKeyName);
    }
  }, [mode]);
};

const removeQueriesIncluding = (queryClient: QueryClient, hashKeyword: string): void => {
  const queriesToRemove = queryClient
    .getQueryCache()
    .findAll()
    .filter(q => q.queryHash.toLowerCase().includes(hashKeyword));

  queriesToRemove.forEach(q => {
    void queryClient.resetQueries(q.queryKey);
  });
};
