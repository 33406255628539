import { type FC } from 'react';
import { Badge, Box, Text } from '@chakra-ui/react';
import { type TooltipProps } from './types';
import { translate, translateAndFormat } from '../../../../../../global/translation';
import { type VesselFeatureProperties, type ShipType } from '../../types';
import { type Speed, SpeedType } from '../../../../../../global/types';
import { getVesselColor } from '../../utils/layer-utils';

const VesselTooltip: FC<TooltipProps<VesselFeatureProperties>> = ({ properties }) => {
  const {
    mmsi,
    heading,
    name: shipName,
    type: shipType,
    speed: speedJson,
    tidalSpeed,
    tidalDirection,
    positionAgeInMinutes,
  } = properties;

  const formatValue = (value: number): string | 0 => {
    if (!value || value === 0) {
      return 0;
    }
    return value.toFixed(1);
  };

  const tidalDirectionFormatted = formatValue(tidalDirection);
  const tidalSpeedFormatted = formatValue(tidalSpeed);
  const tidalTranslate = [tidalDirectionFormatted.toString(), tidalSpeedFormatted.toString()];
  const speed: Speed = JSON.parse(speedJson.toString());

  return (
    <Box>
      <Box className="tooltip-row" marginBottom="8px">
        <Text className="tooltip-header-name">{shipName || 'Unknown'}</Text>
        <Badge
          className="tooltip-badge"
          backgroundColor={getVesselColor(shipType as ShipType, positionAgeInMinutes, false)}
          unselectable="on"
        >
          {shipType}
        </Badge>
      </Box>

      <Box className="tooltip-row">
        <Text className="tooltip-property-key">{translate('MMSI')}</Text>
        <Text className="tooltip-property-value">{mmsi}</Text>
      </Box>
      <Box className="tooltip-row">
        <Text className="tooltip-property-key">{translate('HEADING')}</Text>
        <Text className="tooltip-property-value">{heading}</Text>
      </Box>
      <Box className="tooltip-row">
        <Text className="tooltip-property-key">
          {speed.type === SpeedType.SpeedThroughWater ? translate('TOOLTIP_STW') : translate('TOOLTIP_SOG')}
        </Text>
        <Text className="tooltip-property-value">{`${speed.value} ${translate('KNOTS_SHORT')}`}</Text>
      </Box>
      <Box className="tooltip-row">
        <Text className="tooltip-property-key">{translate('TIDAL_SET_AND_RATE')}</Text>
        <Text className="tooltip-property-value">{`${translateAndFormat(
          'TOOLTIP_DIRECTION_SPEED',
          ...tidalTranslate
        )}`}</Text>
      </Box>
      <Box className="tooltip-row">
        <Text className="tooltip-property-key">{translate('LAST_POSITION_RECEIVED')}</Text>
        <Text className="tooltip-property-value">
          {positionAgeInMinutes === 0 ? translate('JUST_NOW') : `${positionAgeInMinutes}${translate('MINUTES_AGO')}`}
        </Text>
      </Box>
    </Box>
  );
};

export default VesselTooltip;
