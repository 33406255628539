import { useQuery } from 'react-query';
import { ApiClient } from '../api/api-client';
import { getAuthClient } from '../global/utils/authUtils';

import STATUS from '../global/statusCodes';
import { type secretNames } from '../global/constants';

type SecretName = (typeof secretNames)[keyof typeof secretNames];

export const useFetchSecret = (secretName: SecretName): string => {
  const getSecret = async (): Promise<string> => {
    const response = await ApiClient.createAuthenticatedInstance(getAuthClient()).call<string>(
      `api/secrets/${secretName}`
    );
    if (response && response.status === STATUS.OK) {
      const { data } = response;

      return data;
    }

    return null;
  };

  const result = useQuery<string, Error>(['secret', secretName], getSecret, {
    staleTime: Infinity,
  });

  return result.data;
};
