/* eslint-disable @typescript-eslint/prefer-optional-chain */
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { type AccountInfo } from '@azure/msal-browser';
import { type ITelemetryItem } from '@microsoft/applicationinsights-core-js';
import { AppInsightsPlugin } from './global/AppInsightsPlugin';

export const reactPlugin = new AppInsightsPlugin();

export function InitializeTelemetryForAuthenticatedUser(account: AccountInfo, instrumentationKey: string): void {
  const { name, homeAccountId } = account;
  const config = {
    connectionString: instrumentationKey,
    enableAutoRouteTracking: false,
    extensions: [reactPlugin],
  };
  const appInsights = new ApplicationInsights({
    config,
  });

  appInsights.loadAppInsights();
  appInsights.setAuthenticatedUserContext(name, homeAccountId);

  const telemetryInitializer = (item: ITelemetryItem): void => {
    item.tags['ai.user.id'] = name;
    item.tags['ai.user.name'] = name;
    item.tags['ai.user.authUserId'] = name;
    item.tags['ai.user.authenticatedId'] = name;
    appInsights.context.user.accountId = name;
    appInsights.context.user.authenticatedId = name;
    appInsights.context.user.id = name;

    appInsights.addTelemetryInitializer(env => {
      const { tags } = env;
      if (tags?.['ai.user.accountId']) {
        tags['ai.user.id'] = name;
      }
    });
  };
  appInsights.addTelemetryInitializer(telemetryInitializer);
}
