import { Mode } from '../../pages/Playback/types';

export enum RateAppStatus {
  NotActive = 'NotActive',
  ShowRateApp = 'ShowRateApp',
}

export interface AppState {
  rateAppStatus: RateAppStatus;
  mode: Mode;
}

export const initialAppState: AppState = {
  rateAppStatus: RateAppStatus.NotActive,
  mode: Mode.Liveview,
};

export type AppAction =
  | {
      type: AppActionType.TOGGLE_RATE_APP_SURVEY;
      payload: RateAppStatus;
    }
  | { type: AppActionType.SET_MODE; payload: { mode: Mode } };
export enum AppActionType {
  TOGGLE_RATE_APP_SURVEY,
  SET_MODE,
}

export const appReducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case AppActionType.TOGGLE_RATE_APP_SURVEY:
      return {
        ...state,
        rateAppStatus: action.payload,
      };
    case AppActionType.SET_MODE:
      return {
        ...state,
        mode: action.payload.mode,
      };
    default:
      return state;
  }
};
