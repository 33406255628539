import { useRef, useState } from 'react';
import { type PaddingOptions, type MapRef, type LngLat } from 'react-map-gl';
import {
  defaultLiveviewZoom,
  defaultPlaybackZoom,
  vesselRecenterZoom,
  worldViewMapZoomLevel as worldViewMapDesktopZoomLevel,
  worldViewMapMobileZoomLevel,
} from '../components/Map/constants';
import { Mode, type VesselPosition } from '../types';
import { useDetectMobileScreenWidth } from '../../../hooks/useDetectMobileScreenWidth';
import { useDebouncing } from '../../../hooks/useDebouncing';
import { getEventFactory } from '../../../global/utils/get-event-factory';
import { useAppInsights } from '../../../hooks/useAppInsights';
import { Timestamp } from '../utils/timestamp';
import { Page, Event } from '../../../global/types';

interface ZoomChangeEventData {
  page: Page;
  targetVesselMmsi?: string;
  newZoom: number;
}

interface ZoomChangeData {
  zoomLevel: number;
  page: Page;
  targetVesselMmsi: string;
  center?: LngLat;
  padding?: PaddingOptions;
}

export interface MapOrchestrator {
  map: React.MutableRefObject<MapRef>;
  zoomLevel: number;
  getInitialZoomLevel: (pageMode: Mode, isWorldView: boolean) => number;
  onTransitionToPlayback: (
    isWorldView: boolean,
    vesselPositions: VesselPosition[],
    targetVesselMmsi: string,
    center: LngLat,
    padding: PaddingOptions
  ) => void;
  onTransitionToLiveview: (
    isWorldView: boolean,
    targetVesselMmsi: string,
    center: LngLat,
    padding: PaddingOptions
  ) => void;
  onVesselRecenter: (page: Page, targetVesselMmsi: string, center: LngLat, padding: PaddingOptions) => void;
  onMapCenter: (center: LngLat, padding: PaddingOptions) => void;
  onMapZoomAdjustment: (zoomLevel: number, page: Page, targetVesselMmsi: string) => void;
}

export const useZoomOrchestration = (): MapOrchestrator => {
  const mapRef = useRef<MapRef>();
  const isMobileScreenWidth = useDetectMobileScreenWidth();
  const [zoomLevel, setZoomLevel] = useState(0);
  const zoomedOnPlayback = useRef(false);
  const appInsights = useAppInsights();

  const debouncedEvent = useDebouncing((zoomChange: ZoomChangeEventData) => {
    const { newZoom, page, targetVesselMmsi } = zoomChange;
    const event = getEventFactory(Event.ZoomMap)(
      page,
      zoomLevel,
      newZoom,
      window.location.href,
      targetVesselMmsi,
      Timestamp.now()
    );
    appInsights.trackAnalyticsEvent(event);
  }, Timestamp.fromSeconds(5));

  const sendEvent = (zoomChange: ZoomChangeEventData): void => {
    debouncedEvent(zoomChange);
  };

  const updateZoomLevel = (zoomLevel: number, page: Page, targetVesselMmsi: string): void => {
    sendEvent({
      newZoom: zoomLevel,
      page,
      targetVesselMmsi,
    });
    setZoomLevel(zoomLevel);
  };

  const getInitialZoomLevel = (pageMode: Mode, isWorldView: boolean): number => {
    if (isWorldView) {
      return indicateWorldviewZoom();
    }

    if (pageMode === Mode.Playback) {
      return defaultPlaybackZoom;
    }

    return defaultLiveviewZoom;
  };

  const easing = (t: number): number => 1 - (1 - t) ** 5;

  const setZoomLevelDebounced = useDebouncing((data: ZoomChangeData): void => {
    const { padding, page, targetVesselMmsi, zoomLevel, center } = data;
    mapRef.current?.easeTo({
      center,
      easing,
      padding,
      zoom: zoomLevel,
    });
    updateZoomLevel(zoomLevel, page, targetVesselMmsi);
  }, Timestamp.fromMilliseconds(100));

  const handleSetZoomLevel = (data: ZoomChangeData): void => {
    setZoomLevelDebounced(data);
  };

  const indicateLiveviewZoom = (): number => {
    const newZoom = (mapRef.current?.getZoom() ?? 0) * 0.9;
    return newZoom !== 0 ? newZoom : defaultLiveviewZoom;
  };

  const indicateWorldviewZoom = (): number => {
    return isMobileScreenWidth ? worldViewMapMobileZoomLevel : worldViewMapDesktopZoomLevel;
  };

  const indicateZoomOnPageModeTransition = (pageMode: Mode, isWorldView: boolean): number => {
    if (isWorldView) {
      return indicateWorldviewZoom();
    }

    return pageMode === Mode.Liveview ? indicateLiveviewZoom() : defaultPlaybackZoom;
  };

  const handleTransitionToPlayback = (
    isWorldView: boolean,
    vesselPositions: VesselPosition[],
    targetVesselMmsi: string,
    center: LngLat
  ): void => {
    if (vesselPositions?.length && !zoomedOnPlayback.current) {
      const newZoomLevel = indicateZoomOnPageModeTransition(Mode.Playback, isWorldView);
      handleSetZoomLevel({
        page: Page.Playback,
        targetVesselMmsi,
        zoomLevel: newZoomLevel,
        center,
      });
      zoomedOnPlayback.current = true;
    }
  };

  const handleTransitionToLiveview = (isWorldView: boolean, targetVesselMmsi: string, center: LngLat): void => {
    const newZoomLevel = indicateZoomOnPageModeTransition(Mode.Liveview, isWorldView);
    zoomedOnPlayback.current = false;
    handleSetZoomLevel({
      page: Page.Liveview,
      targetVesselMmsi,
      zoomLevel: newZoomLevel,
      center,
    });
  };

  const handleVesselRecenter = (
    page: Page,
    targetVesselMmsi: string,
    center: LngLat,
    padding: PaddingOptions
  ): void => {
    handleSetZoomLevel({
      page,
      targetVesselMmsi,
      zoomLevel: vesselRecenterZoom,
      center,
      padding,
    });
  };

  const handleMapCenter = (center: LngLat, padding: PaddingOptions): void => {
    mapRef.current?.easeTo({
      center,
      easing,
      padding,
      zoom: zoomLevel,
    });
  };

  return {
    map: mapRef,
    getInitialZoomLevel,
    zoomLevel,
    onTransitionToPlayback: handleTransitionToPlayback,
    onTransitionToLiveview: handleTransitionToLiveview,
    onVesselRecenter: handleVesselRecenter,
    onMapCenter: handleMapCenter,
    onMapZoomAdjustment: updateZoomLevel,
  };
};
