import { Box, Spinner, Text } from '@chakra-ui/react';
import { type FC } from 'react';
import styles from '../../styles/map.module.scss';
import { translate } from '../../../../global/translation';

const BlurMap: FC = () => {
  return (
    <div className={styles.mapBlur}>
      <Box height="100%" width="100%" position="absolute">
        <div className={styles.centerText}>
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
          <Text>{translate('MAP_FETCH_DATA')}</Text>
        </div>
      </Box>
    </div>
  );
};

export default BlurMap;
