import { type FunctionComponent, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import cx from 'classnames';
import { ActivityTypeColor } from './constants';
import { Timestamp } from '../../utils/timestamp';
import { type ActivityViewModel } from '../../types';
import { ActivityType, type Range } from '../../../../global/types';
import { activityTypeDisplayStrings } from '../../../../global/constants';

interface Props {
  secondInPixels: number;
  activities: ActivityViewModel[];
  timelineRange: Range<Timestamp>;
}

const ScrubberActivity: FunctionComponent<Props> = ({ secondInPixels, activities, timelineRange }) => {
  const scrubberActivities = useMemo(() => {
    return activities
      .filter(
        a =>
          a.type !== ActivityType.Resting &&
          a.timestampRange.to.valueInMilliseconds >= timelineRange.from.valueInMilliseconds &&
          a.timestampRange.from.valueInMilliseconds <= timelineRange.to.valueInMilliseconds
      )
      .map(activity => {
        const width =
          Timestamp.fromMilliseconds(
            activity.timestampRange.to.valueInMilliseconds - activity.timestampRange.from.valueInMilliseconds
          ).toSeconds() * secondInPixels;
        const activityStart = Timestamp.fromMilliseconds(activity.timestampRange.from.valueInMilliseconds);
        const activityOffset = activityStart.subtract(timelineRange.from).toSeconds() * secondInPixels;
        const isLoading = activity.type === ActivityType.Loading;

        return (
          <Box
            className={cx('scrubber-activity text-xs-medium', { loading: isLoading })}
            key={activity.activityId}
            width={`${width}px`}
            left={`${activityOffset}px`}
            background={`${ActivityTypeColor[activity.type]}`}
          >
            <span>{activityTypeDisplayStrings[activity.type]}</span>
          </Box>
        );
      });
  }, [activities, secondInPixels, timelineRange]);

  return <>{scrubberActivities}</>;
};

export default ScrubberActivity;
