/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable spellcheck/spell-checker */
import Utm, { LatLon } from 'geodesy/utm';
import { type VesselDimensions } from '../../../types';
import { type Location } from '../../../../../global/types';
import { type Point } from '../types';

// Note: All of this code comes from Python code that Lars and Nicolai have written, and then translated to JavaScript by Stefan.

const calculatePoint = (easting: number, northing: number, angle_rad: number, distance: number): Point => {
  const x1 = easting + distance * Math.cos(angle_rad);
  const y1 = northing + distance * Math.sin(angle_rad);
  return [x1, y1];
};

const calculateEastNorthRelation = (
  point_x: number,
  point_y: number,
  easting: number,
  northing: number,
  angle_rad: number
): Point => {
  const distance = Math.sqrt(point_x ** 2 + point_y ** 2);
  const [easting_bow, northing_bow] = calculatePoint(easting, northing, angle_rad, distance);
  return [easting_bow, northing_bow];
};

const calculateVesselOutline = (
  headingAngleRad: number,
  to_bow: number,
  to_starboard: number,
  to_stern: number,
  to_port: number,
  easting: number,
  northing: number,
  pointy_prct: number
): Point[] => {
  let angle_rad = headingAngleRad;

  const midle_point = (to_starboard + to_port) / 2;

  let pointy_end: number;
  let easting_bow_starboard: number;
  let northing_bow_starboard: number;
  let easting_bow_port: number;
  let northing_bow_port: number;

  // If transmitter is in port side of the vessel or starboard side to find angle towards midle point
  if (to_port >= to_starboard) {
    pointy_end = to_port - midle_point;

    angle_rad += to_bow !== 0 ? Math.atan(pointy_end / to_bow) : Math.PI / 2;
  } else if (to_port < to_starboard) {
    angle_rad += (3 * Math.PI) / 2;
    pointy_end = to_starboard - midle_point;
    if (pointy_end !== 0) {
      angle_rad += Math.atan(to_bow / pointy_end);
    }
  }
  const [easting_pointy, northing_pointy] = calculateEastNorthRelation(
    to_bow,
    pointy_end,
    easting,
    northing,
    angle_rad
  );

  // Correcting to_bow to pull back sides of the vessel to create pointy appeareance
  const length_correction = (to_bow + to_stern) * pointy_prct;
  to_bow -= length_correction;

  angle_rad = headingAngleRad + (3 * Math.PI) / 2;

  angle_rad += to_starboard !== 0 ? Math.atan(to_bow / to_starboard) : Math.PI / 2;

  [easting_bow_starboard, northing_bow_starboard] = calculateEastNorthRelation(
    to_bow,
    to_starboard,
    easting,
    northing,
    angle_rad
  );

  angle_rad = headingAngleRad + Math.PI;

  angle_rad += to_stern !== 0 ? Math.atan(to_starboard / to_stern) : Math.PI / 2;

  const [easting_stern_starboard, northing_stern_starboard] = calculateEastNorthRelation(
    to_starboard,
    to_stern,
    easting,
    northing,
    angle_rad
  );

  angle_rad = headingAngleRad + Math.PI / 2;

  angle_rad += to_port !== 0 ? Math.atan(to_stern / to_port) : Math.PI / 2;

  const [easting_stern_port, northing_stern_port] = calculateEastNorthRelation(
    to_stern,
    to_port,
    easting,
    northing,
    angle_rad
  );

  angle_rad = headingAngleRad;
  angle_rad += to_bow !== 0 ? Math.atan(to_port / to_bow) : Math.PI / 2;

  [easting_bow_port, northing_bow_port] = calculateEastNorthRelation(to_port, to_bow, easting, northing, angle_rad);

  // If the transmitter is in front on the sides of vessel the corner points are calculating by reversing the vessel direction and switching port and starboard and using the vessel correction to find the corner points.
  if (to_bow < 0) {
    angle_rad = headingAngleRad + (3 * Math.PI) / 2 + Math.PI;

    angle_rad += to_port !== 0 ? Math.atan(length_correction / to_port) : Math.PI / 2;

    [easting_bow_port, northing_bow_port] = calculateEastNorthRelation(
      length_correction,
      to_port,
      easting,
      northing,
      angle_rad
    );

    angle_rad = headingAngleRad + Math.PI;

    angle_rad += to_bow !== 0 ? Math.atan(to_starboard / length_correction) : Math.PI / 2;

    [easting_bow_starboard, northing_bow_starboard] = calculateEastNorthRelation(
      to_starboard,
      length_correction,
      easting,
      northing,
      angle_rad
    );
  }

  // Creating polygon for vessel outline
  return [
    [easting_bow_starboard, northing_bow_starboard],
    [easting_stern_starboard, northing_stern_starboard],
    [easting_stern_port, northing_stern_port],
    [easting_bow_port, northing_bow_port],
    [easting_pointy, northing_pointy],
    [easting_bow_starboard, northing_bow_starboard],
  ];
};

export const convertHeadingToDegrees = (heading: number): number => {
  let convertedHeading = -heading + 360 + 90;

  while (convertedHeading >= 360) {
    convertedHeading -= 360;
  }

  while (convertedHeading < 0) {
    convertedHeading += 360;
  }

  return convertedHeading;
};

export const getVesselDimensions = (
  location: Location,
  heading: number,
  dimensions: VesselDimensions
): Point | Point[] => {
  const {
    coordinates: { lon: longitude, lat: latitude },
  } = location;
  const latLong = new LatLon(latitude, longitude);
  const { easting, northing, zone, hemisphere } = latLong.toUtm();
  const { toBow, toStarboard, toStern, toPort } = dimensions;
  const angleDeg = convertHeadingToDegrees(heading);
  const angleRad = angleDeg * (Math.PI / 180);

  const length = toBow + toStern;
  // How pointy the bow is as percentage of length
  const pointy_prct = length < 50 ? 0.22 : 0.1;

  const polygonDimensions = calculateVesselOutline(
    angleRad,
    toBow,
    toStarboard,
    toStern,
    toPort,
    easting,
    northing,
    pointy_prct
  );

  return convertToGeometryData(location, polygonDimensions, zone, hemisphere);
};

const convertToGeometryData = (
  location: Location,
  polygonDimensions: Point[],
  zone: number,
  hemisphere: 'N' | 'S'
): Point[] | Point => {
  try {
    return getPolygonPoints(polygonDimensions, zone, hemisphere);
  } catch (e) {
    return getSinglePoint(location);
  }
};

const getPolygonPoints = (polygonDimensions: Point[], zone: number, hemisphere: 'N' | 'S'): Point[] => {
  return polygonDimensions.map(dimension => {
    const utm = new Utm(zone, hemisphere, dimension[0], dimension[1]);
    const { lon, lat } = utm.toLatLon();
    return [lon, lat] as Point;
  });
};

const getSinglePoint = (location: Location): Point => {
  const {
    coordinates: { lon: longitude, lat: latitude },
  } = location;
  return [longitude, latitude];
};
