import { minBy } from 'lodash';
import { type LiveDataViewModel, OperationLiveStatus } from '../types';

const indicateOperationStatus = (data: LiveDataViewModel): OperationLiveStatus => {
  const minPositionDelay = minBy(data?.vesselPositions, p => p.positionAgeInMinutes)?.positionAgeInMinutes;
  if (minPositionDelay <= 10) {
    return OperationLiveStatus.Live;
  }
  if (minPositionDelay <= 60) {
    return OperationLiveStatus.Delayed;
  }

  return OperationLiveStatus.Unavailable;
};

export default indicateOperationStatus;
