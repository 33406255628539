/* eslint-disable */
import { translate } from '../../translation';
import { ApiClient } from '../../../api/api-client';
import { getAuthClient } from '../../utils/authUtils';
import STATUS_CODES from '../../statusCodes';
import { type AppAction, AppActionType, RateAppStatus } from '../app-reducer';
import type React from 'react';

interface RateText {
  header: string;
  subHeader: string;
}

export const ratings = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export function getRateText(rate: number): RateText {
  if (rate >= 0 && rate <= 6) {
    return {
      header: translate('RATEAPPCOMMENTMODAL_00_HEADING'),
      subHeader: translate('RATEAPPCOMMENTMODAL_00_SUB_HEADING'),
    };
  }
  if (rate >= 7 && rate <= 8) {
    return {
      header: translate('RATEAPPCOMMENTMODAL_01_HEADING'),
      subHeader: translate('RATEAPPCOMMENTMODAL_01_SUB_HEADING'),
    };
  }
  if (rate >= 9 && rate <= 10) {
    return {
      header: translate('RATEAPPCOMMENTMODAL_02_HEADING'),
      subHeader: translate('RATEAPPCOMMENTMODAL_02_SUB_HEADING'),
    };
  }
}

export const trackEvent = (appDispatch: React.Dispatch<AppAction>): void => {
  const client = ApiClient.createAuthenticatedInstance(getAuthClient());
  void client
    .call('api/rateApp/addEventCount', {
      method: 'POST',
    })
    .then(response => {
      if (response.status === STATUS_CODES.OK) {
        toggleSurvey(appDispatch, response.data);
      }
    });
};

export const trackLogin = (appDispatch: React.Dispatch<AppAction>): void => {
  const client = ApiClient.createAuthenticatedInstance(getAuthClient());
  void client
    .call('api/rateApp/userLogin', {
      method: 'POST',
    })
    .then(response => {
      if (response.status === STATUS_CODES.OK) {
        toggleSurvey(appDispatch, response.data);
      }
    });
};

const toggleSurvey = (appDispatch: React.Dispatch<AppAction>, rateAppStatus: RateAppStatus): void => {
  appDispatch({ type: AppActionType.TOGGLE_RATE_APP_SURVEY, payload: rateAppStatus });
};

export const resetRating = (appDispatch: React.Dispatch<AppAction>): void => {
  const client = ApiClient.createAuthenticatedInstance(getAuthClient());
  void client.call('api/rateApp/resetRating', {
    method: 'POST',
  });
  appDispatch({ type: AppActionType.TOGGLE_RATE_APP_SURVEY, payload: RateAppStatus.NotActive });
};
