import { useState, useEffect } from 'react';
import { type Timestamp } from '../../pages/Playback/utils/timestamp';
import type React from 'react';

interface Props {
  children: React.ReactElement;
  wait: Timestamp;
  shouldClearTimeout: boolean;
}

const Delayed = ({ children, wait, shouldClearTimeout }: Props): React.ReactElement => {
  const [isDisplayed, setIsDisplayed] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsDisplayed(true);
    }, wait.toMilliseconds());
    if (shouldClearTimeout) {
      return () => {
        clearTimeout(timer);
      };
    }
  }, [wait]);

  return isDisplayed && children;
};

export default Delayed;
