import { type FC, useMemo, type Dispatch, type SetStateAction, useState } from 'react';
import ClusterLayer from './ClusterLayer';
import VesselsLayer from './VesselsLayer';
import QuickAccessPanel from './QuickAccess';
import FutureVesselTracksLayer from './FutureVesselTracksLayer';
import PastVesselTracksLayer from './PastVesselTracksLayer';
import JobTracksLayer from './JobTracksLayer';
import OperationsLayer from './OperationsLayer';
import TargetVesselLayer from './TargetVesselLayer';
import ActivityMarkersLayer from './ActivityMarkersLayer';
import QuiverPlotLayer from './QuiverPlotLayer';
import TidalDiamondLayer from './TidalDiamondLayer';
import NauticalLayer from './NauticalLayer';
import Map from '../index';
import { getTargetedVesselCoordinates } from '../utils/layer-utils';
import { type Page, type Location, type MmsiKey } from '../../../../../global/types';
import { type Mode, type VesselPosition, type VesselViewModel } from '../../../types';
import { type Timestamp } from '../../../utils/timestamp';
import { type OperationViewModel, type VesselTracksViewModel } from '../types';
import { useZoomOrchestration } from '../../../hooks/useMapOrchestration';
import { type Job } from '../../../../Trips/types';
import { useTidalData } from '../../../hooks/useTidalData';
import { useMapContext } from '../../../../../global/state/GlobalProvider';

export interface Props {
  vesselPositions: VesselPosition[];
  vesselData: Record<MmsiKey, VesselViewModel>;
  mapCenter: Location;
  operation: string;
  targetVesselMmsi: string;
  targetVesselTracks: VesselTracksViewModel;
  targetVessel: VesselViewModel;
  onTargetVesselChange: (mmsi: string) => void;
  dateString: string;
  toggleTripsPanel: () => void;
  timestamp: Timestamp;
  isLockedOnTarget: boolean;
  setLockedOnTarget: Dispatch<SetStateAction<boolean>>;
  page: Page;
  operations: OperationViewModel[];
  onOperationSelect: (value: string) => void;
  setTimestamp: (timestamp: Timestamp) => void;
  startTimestamp: Timestamp;
  setStartTimestamp: (timestamp: Timestamp) => void;
  isWorldView: boolean;
  isTripsPanelOpen: boolean;
  pageMode: Mode;
  hoveredJob: Job;
  timezone: string;
}

const MapMode: FC<Props> = ({
  vesselPositions,
  vesselData,
  mapCenter,
  targetVesselMmsi,
  targetVesselTracks,
  targetVessel,
  onTargetVesselChange,
  operation,
  dateString,
  toggleTripsPanel,
  timestamp,
  isLockedOnTarget,
  setLockedOnTarget,
  page,
  operations,
  onOperationSelect,
  setTimestamp,
  startTimestamp,
  setStartTimestamp,
  isWorldView,
  isTripsPanelOpen,
  pageMode,
  hoveredJob,
  timezone,
}) => {
  const zoomOrchestrator = useZoomOrchestration();
  const { zoomLevel } = zoomOrchestrator;
  const {
    settings: { showQuiverPlot, showNauticalMap },
  } = useMapContext();
  const { data: tidalData } = useTidalData(operation, timestamp);
  const [activeTidalDiamond, setActiveTidalDiamond] = useState<string>(null);

  const targetVesselCoordinates = useMemo(
    () => getTargetedVesselCoordinates(vesselPositions, targetVesselMmsi),
    [vesselPositions, targetVesselMmsi]
  );

  const vesselSearchModels = useMemo(() => vesselData && Object.entries(vesselData).map(([, v]) => v), [vesselData]);

  return (
    <Map
      vesselPositions={vesselPositions}
      onTargetVesselChange={onTargetVesselChange}
      mapCenter={mapCenter}
      isLockedOnTarget={isLockedOnTarget}
      setLockedOnTarget={setLockedOnTarget}
      targetVessel={targetVessel}
      timestamp={timestamp}
      operation={operation}
      page={page}
      onOperationSelect={onOperationSelect}
      isWorldView={isWorldView}
      isTripsPanelOpen={isTripsPanelOpen}
      pageMode={pageMode}
      zoomOrchestrator={zoomOrchestrator}
      setActiveTidalDiamond={setActiveTidalDiamond}
      timezone={timezone}
    >
      {/* Layer order affects rendering (i.e. last layer is rendered on top of previous) */}
      {!isWorldView && (
        <>
          {showNauticalMap && <NauticalLayer />}
          <FutureVesselTracksLayer
            vesselTracks={targetVesselTracks?.futureTracks}
            activities={targetVesselTracks?.activities}
          />
          <PastVesselTracksLayer
            vesselTracks={targetVesselTracks?.pastTracks}
            activities={targetVesselTracks?.activities}
          />
          <ActivityMarkersLayer activities={targetVesselTracks?.activities} />
          <JobTracksLayer job={hoveredJob} />
          <VesselsLayer
            zoomLevel={zoomLevel}
            vesselData={vesselData}
            vesselPositions={vesselPositions}
            targetVesselCoordinates={targetVesselCoordinates}
            targetVesselMmsi={targetVesselMmsi}
          />
          <TargetVesselLayer
            zoomLevel={zoomLevel}
            vesselData={vesselData}
            vesselPositions={vesselPositions}
            targetVesselCoordinates={targetVesselCoordinates}
            targetVesselMmsi={targetVesselMmsi}
          />
          {showQuiverPlot && (
            <>
              <QuiverPlotLayer data={tidalData?.quiverPlotArrow} />
              <TidalDiamondLayer data={tidalData?.tidalDiamond} activeTidalDiamond={activeTidalDiamond} />
            </>
          )}
          <QuickAccessPanel
            operation={operation}
            timestamp={timestamp}
            vesselData={vesselSearchModels}
            selectedVesselMmsi={targetVesselMmsi}
            onTargetVesselChange={onTargetVesselChange}
            toggleTripsPanel={toggleTripsPanel}
            targetVessel={targetVessel}
            vesselPositions={vesselPositions}
            setTimestamp={setTimestamp}
            setStartTimestamp={setStartTimestamp}
            page={page}
            targetVesselMmsi={targetVesselMmsi}
            isLockedOnTarget={isLockedOnTarget}
            timezone={timezone}
            dateString={dateString}
            startTimestamp={startTimestamp}
          />
        </>
      )}
      <ClusterLayer zoomLevel={zoomLevel} vesselData={vesselData} vesselPositions={vesselPositions} />
      <OperationsLayer operations={operations} currentOperation={operation} />
    </Map>
  );
};

export default MapMode;
