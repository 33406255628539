/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createContext, useContext, useReducer } from 'react';
import { initialTripsState, globalReducer, type Action } from './reducer';

const GlobalContext = createContext(initialTripsState);
const GlobalDispatchContext = createContext(null);
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalReducer, initialTripsState);

  return (
    <GlobalContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}> {children} </GlobalDispatchContext.Provider>
    </GlobalContext.Provider>
  );
};

export const useTripsContext = () => useContext(GlobalContext).trips;
export const useMapContext = () => useContext(GlobalContext).map;
export const useDispatch = () => useContext(GlobalDispatchContext) as React.Dispatch<Action>;
