import { useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import { type DateRange } from '../../../../../global/types';
import { formatDateRange, getDateRangeFromDate } from '../utils/bar-chart-utils';

export interface DateRangeKey {
  key: string;
  range: DateRange;
}

export interface AdjacentDateRanges {
  previous: DateRangeKey;
  current: DateRangeKey;
  next: DateRangeKey;
}

export const useAdjacentDateRanges = (
  selectedDate: Date,
  rangeSizeInDays: number
): [AdjacentDateRanges, (range: DateRange) => void] => {
  const [currentRange, setCurrentRange] = useState<DateRange>(getDateRangeFromDate(selectedDate, rangeSizeInDays));

  useEffect(() => {
    const isWithinCurrentRange = moment
      .utc(selectedDate)
      .isBetween(moment.utc(currentRange.from), moment.utc(currentRange.to));

    if (selectedDate && !isWithinCurrentRange) {
      setCurrentRange(getDateRangeFromDate(selectedDate, rangeSizeInDays));
    }
  }, [selectedDate]);

  useEffect(() => {
    setCurrentRange(getDateRangeFromDate(currentRange.to, rangeSizeInDays));
  }, [rangeSizeInDays]);

  const getPreviousRange = (range: DateRange, rangeSize: number): DateRange => {
    const from = moment.utc(range.from).subtract(rangeSize, 'days');
    const to = moment.utc(range.to).subtract(rangeSize, 'days');
    return { from: from.toDate(), to: to.toDate() };
  };

  const getNextRange = (range: DateRange, rangeSize: number): DateRange => {
    const from = moment.utc(range.from).add(rangeSize, 'days');
    const to = moment.utc(range.to).add(rangeSize, 'days');

    return {
      from: from.toDate(),
      to: to.toDate(),
    };
  };

  const ranges = useMemo(() => {
    const previousRange = getPreviousRange(currentRange, rangeSizeInDays);
    const nextRange = getNextRange(currentRange, rangeSizeInDays);
    return {
      previous: { key: formatDateRange(previousRange), range: previousRange },
      current: { key: formatDateRange(currentRange), range: currentRange },
      next: { key: formatDateRange(nextRange), range: nextRange },
    } as AdjacentDateRanges;
  }, [currentRange, rangeSizeInDays]);

  return [ranges, setCurrentRange];
};
