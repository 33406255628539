import { type FC } from 'react';
import { Box, ScaleFade, Text } from '@chakra-ui/react';
import { ReactComponent as NoDataIcon } from '../../../static/svg/NoData.svg';
import { useDetectMobileScreenWidth } from '../../../hooks/useDetectMobileScreenWidth';
import { translate } from '../../../global/translation';

interface Props {
  isShown: boolean;
}

const NoDataMessage: FC<Props> = ({ isShown }) => {
  const isMobileView = useDetectMobileScreenWidth();
  return (
    <Box className="no-data-message-container">
      <ScaleFade className="no-data-message" initialScale={0.8} in={isShown}>
        <NoDataIcon />
        {isMobileView || <Text className="text-sm-semibold">{translate('MAP_NO_DATA')}</Text>}
      </ScaleFade>
    </Box>
  );
};

export default NoDataMessage;
