import { type FC } from 'react';
import { Box, IconButton, Tooltip } from '@chakra-ui/react';
import { Event } from '../../../global/types';
import { useAppInsights } from '../../../hooks/useAppInsights';
import { getEventFactory } from '../../../global/utils/get-event-factory';
import { type Timestamp } from '../utils/timestamp';
import { type VesselViewModel } from '../types';
import { ReactComponent as CloseIcon } from '../../../static/svg/Close.svg';
import { translate } from '../../../global/translation';

export interface Props {
  vessel: VesselViewModel;
  timestamp: Timestamp;
  switchToLiveview: () => void;
}

const LiveViewButton: FC<Props> = ({ vessel, timestamp, switchToLiveview }) => {
  const appInsights = useAppInsights();
  const navigateToContacts = (): void => {
    const event = getEventFactory(Event.ReplayClosed)(
      window.location.href,
      vessel?.mmsi ?? null,
      vessel?.name ?? null,
      timestamp
    );
    appInsights.trackAnalyticsEvent(event);
    switchToLiveview();
  };

  return (
    <Box>
      <Tooltip label={translate('LIVE_VIEW')}>
        <IconButton variant="close" aria-label="Live View" icon={<CloseIcon />} onClick={navigateToContacts} />
      </Tooltip>
    </Box>
  );
};

export default LiveViewButton;
