import { type FC } from 'react';
import { IconButton, ButtonGroup } from '@chakra-ui/react';
import { maxScrubberZoomLevel, minScrubberZoomLevel } from './constants';
import { ReactComponent as PlusIcon } from '../../../../static/svg/PlusIcon.svg';
import { ReactComponent as MinusIcon } from '../../../../static/svg/MinusIcon.svg';
import { useAppInsights } from '../../../../hooks/useAppInsights';
import { getEventFactory } from '../../../../global/utils/get-event-factory';
import { Event } from '../../../../global/types';
import { type Timestamp } from '../../utils/timestamp';
import { type VesselViewModel } from '../../types';

interface Props {
  zoomLevel: number;
  vessel: VesselViewModel;
  timestamp: Timestamp;
  setZoomLevel: (zoomLevel: number) => void;
}

const ZoomButtons: FC<Props> = ({ zoomLevel, vessel, timestamp, setZoomLevel }) => {
  const appInsights = useAppInsights();

  const handleZoomIn = (): void => {
    if (zoomLevel === maxScrubberZoomLevel) {
      return;
    }
    const event = getEventFactory(Event.TimelineZoomed)(
      zoomLevel,
      zoomLevel + 1,
      window.location.href,
      vessel?.mmsi,
      vessel?.name,
      timestamp
    );
    setZoomLevel(zoomLevel + 1);
    appInsights.trackAnalyticsEvent(event);
  };

  const handleZoomOut = (): void => {
    if (zoomLevel === minScrubberZoomLevel) {
      return;
    }
    const event = getEventFactory(Event.TimelineZoomed)(
      zoomLevel,
      zoomLevel - 1,
      window.location.href,
      vessel?.mmsi,
      vessel?.name,
      timestamp
    );
    setZoomLevel(zoomLevel - 1);
    appInsights.trackAnalyticsEvent(event);
  };

  return (
    <div>
      <ButtonGroup isAttached>
        <IconButton
          className="zoom-button"
          aria-label="zoom out"
          variant="secondary"
          onClick={handleZoomOut}
          icon={<MinusIcon />}
          isDisabled={zoomLevel === minScrubberZoomLevel}
        />
        <IconButton
          className="zoom-button"
          aria-label="zoom in"
          variant="secondary"
          onClick={handleZoomIn}
          icon={<PlusIcon />}
          isDisabled={zoomLevel === maxScrubberZoomLevel}
        />
      </ButtonGroup>
    </div>
  );
};

export default ZoomButtons;
