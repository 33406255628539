import { type FC, useEffect, useState } from 'react';
import RateCard from './RateCard';
import { RateAppStatus } from './app-reducer';
import { useAppContext, useAppDispatch } from './AppContextProvider';
import { resetRating } from './utils/rate-app-utils';
import Delayed from './Delayed';
import RateAppCommentCard from './RateAppCommentCard';
import ConfirmFeedbackCard, { ConfirmCardSource } from './ConfirmFeedbackCard';
import { Timestamp } from '../../pages/Playback/utils/timestamp';
import { Event, type Feature, NPSSurveyEvent, type Page } from '../types';
import { getEventFactory } from '../utils/get-event-factory';
import { useAppInsights } from '../../hooks/useAppInsights';

const rateAppDelayInSeconds = 10;

interface RateAppFlowProps {
  parent: Feature;
  page: Page;
}

const RateAppFlow: FC<RateAppFlowProps> = ({ parent, page }) => {
  const [rateAppFlowState, setRateAppFlowState] = useState<RateAppFlowState>(RateAppFlowState.Closed);
  const [rateValue, setRateValue] = useState<number>(null);
  const { rateAppStatus } = useAppContext();
  const appDispatch = useAppDispatch();
  const appInsights = useAppInsights();

  useEffect(() => {
    if (rateAppStatus === RateAppStatus.ShowRateApp) {
      setRateAppFlowState(RateAppFlowState.Rate);
    }
    if (rateAppStatus === RateAppStatus.NotActive && rateAppFlowState !== RateAppFlowState.Feedback) {
      setRateAppFlowState(RateAppFlowState.Closed);
    }
  }, [rateAppStatus]);

  const addAppInsightsEvent = (nPSSurveyEvent: NPSSurveyEvent, comment: string = null): void => {
    const event = getEventFactory(Event.NPSSurvey)(parent, page, nPSSurveyEvent, rateValue, comment);
    appInsights.trackAnalyticsEvent(event);
  };

  return rateAppFlowState !== RateAppFlowState.Closed ? (
    <Delayed wait={Timestamp.fromSeconds(rateAppDelayInSeconds)} shouldClearTimeout={false}>
      <>
        <RateCard
          isOpen={rateAppFlowState === RateAppFlowState.Rate}
          onClose={() => {
            addAppInsightsEvent(NPSSurveyEvent.Close);
            setRateAppFlowState(RateAppFlowState.Closed);
            resetRating(appDispatch);
          }}
          onRateClick={rate => {
            setRateAppFlowState(RateAppFlowState.Comment);
            setRateValue(rate);
          }}
        />
        <RateAppCommentCard
          isOpen={rateAppFlowState === RateAppFlowState.Comment}
          rate={rateValue}
          onClose={() => {
            addAppInsightsEvent(NPSSurveyEvent.ClickNumberAndClose);
            setRateAppFlowState(RateAppFlowState.Feedback);
            resetRating(appDispatch);
          }}
          onSubmit={comment => {
            addAppInsightsEvent(NPSSurveyEvent.Submit, comment);
            setRateAppFlowState(RateAppFlowState.Feedback);
          }}
        />
        <ConfirmFeedbackCard
          isOpen={rateAppFlowState === RateAppFlowState.Feedback}
          source={ConfirmCardSource.NpsSurvey}
          onClose={() => {
            setRateAppFlowState(RateAppFlowState.Closed);
            resetRating(appDispatch);
          }}
        />
      </>
    </Delayed>
  ) : null;
};

export enum RateAppFlowState {
  Closed,
  Rate,
  Comment,
  Feedback,
}

export default RateAppFlow;
