import {
  useRef,
  type FunctionComponent,
  type MouseEvent as ReactMouseEvent,
  type TouchEvent,
  type Touch,
  useState,
  useMemo,
} from 'react';
import { VStack } from '@chakra-ui/react';
import cx from 'classnames';
import { first } from 'lodash';
import ScrubberTimeline from './ScrubberTimeline';
import ScrubberControls from './ScrubberControls';
import ScrubberHeaderSection from './ScrubberHeaderSection';
import { ScrubberZoomLevelResolutionMap, zoomLevelTimeDeltaMultiplierMap } from './constants';

import { Timestamp } from '../../utils/timestamp';
import { useScrubberData } from '../../hooks/useScrubberData';
import { getEventFactory } from '../../../../global/utils/get-event-factory';
import { Event } from '../../../../global/types';
import { useAppInsights } from '../../../../hooks/useAppInsights';
import type { VesselPosition, VesselViewModel } from '../../types';

interface Props {
  vessel: VesselViewModel;
  setTimestamp: (timestamp: Timestamp) => void;
  timestamp: Timestamp;
  isTripsPanelOpen: boolean;
  isDraggingScrubber: boolean;
  setIsDraggingScrubber: (isDragging: boolean) => void;
  vesselPositions: VesselPosition[];
  timezone: string;
  switchToLiveview: () => void;
  isLockedOnTarget: boolean;
  onTargetLock: (mmsi: string) => void;
}

const Scrubber: FunctionComponent<Props> = ({
  isTripsPanelOpen,
  timestamp,
  setTimestamp,
  isDraggingScrubber,
  setIsDraggingScrubber,
  vessel,
  vesselPositions,
  timezone,
  switchToLiveview,
  isLockedOnTarget,
  onTargetLock,
}) => {
  const scrubberData = useScrubberData(vessel?.mmsi);
  const [scrubberZoomLevel, setScrubberZoomLevel] = useState(3);
  const [isPlaying, setIsPlaying] = useState(false);
  const initialScrubTime = useRef(timestamp);
  const previousTouch = useRef<Touch>(null);
  const previousMouseX = useRef(0);
  const appInsights = useAppInsights();

  const secondInPixels = useMemo(() => {
    return ScrubberZoomLevelResolutionMap[scrubberZoomLevel];
  }, [scrubberZoomLevel]);

  const zoomTimeDeltaMultiplier = useMemo(() => {
    return zoomLevelTimeDeltaMultiplierMap[scrubberZoomLevel];
  }, [scrubberZoomLevel]);

  const isMouseEvent = (e: any): boolean => {
    const event = e as ReactMouseEvent;
    return event && typeof event.clientX === 'number';
  };

  const handleMouseDragStart = (e: ReactMouseEvent): void => {
    previousMouseX.current = e.clientX;
  };

  const handleTouchDragStart = (e: TouchEvent): void => {
    previousTouch.current = first(e.touches);
  };

  const handleDragStart = (e: ReactMouseEvent | TouchEvent): void => {
    if (isMouseEvent(e)) {
      handleMouseDragStart(e as ReactMouseEvent);
    } else {
      handleTouchDragStart(e as TouchEvent);
    }
    initialScrubTime.current = timestamp;
    setIsDraggingScrubber(true);
  };

  const getMouseMovementDelta = (e: ReactMouseEvent): number => {
    if (!previousMouseX.current) {
      return 0;
    }

    return previousMouseX.current - e.clientX;
  };

  const getTouchMovementDelta = (e: TouchEvent): number => {
    if (!previousTouch.current) {
      return 0;
    }

    const touch = first(e.touches);
    return previousTouch.current.pageX - touch.pageX;
  };

  const handleMove = (e: ReactMouseEvent | TouchEvent): void => {
    if (!isDraggingScrubber) {
      return;
    }

    const movementDelta = isMouseEvent(e)
      ? getMouseMovementDelta(e as ReactMouseEvent)
      : getTouchMovementDelta(e as TouchEvent);

    if (movementDelta === 0) {
      return;
    }

    const timeDelta = (movementDelta / secondInPixels) * zoomTimeDeltaMultiplier;
    const newScrubTimestamp = initialScrubTime.current.toSeconds() + timeDelta;
    setTimestamp(Timestamp.fromSeconds(newScrubTimestamp));
  };

  const handleDragEnd = (): void => {
    setIsDraggingScrubber(false);
    const event = getEventFactory(Event.TimelineScrubbed)(
      initialScrubTime.current,
      timestamp,
      window.location.href,
      vessel?.mmsi,
      vessel?.name
    );
    appInsights.trackAnalyticsEvent(event);
  };

  const speed = useMemo(() => {
    return vesselPositions?.find(x => x.mmsi === vessel?.mmsi)?.speed;
  }, [vesselPositions, vessel]);

  const scrubberClasses = cx('scrubber-container', {
    shrunk: isTripsPanelOpen,
  });

  return (
    <VStack className={scrubberClasses}>
      <ScrubberControls
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        vessel={vessel}
        timestamp={timestamp}
        setTimestamp={setTimestamp}
        zoomLevel={scrubberZoomLevel}
        setZoomLevel={setScrubberZoomLevel}
        switchToLiveview={switchToLiveview}
        isLockedOnTarget={isLockedOnTarget}
        onTargetLock={onTargetLock}
      />
      <ScrubberHeaderSection timestamp={timestamp} vessel={vessel} speed={speed} timezone={timezone} />
      <ScrubberTimeline
        secondInPixels={secondInPixels}
        currentTimestamp={timestamp}
        isTripsPanelOpen={isTripsPanelOpen}
        data={scrubberData}
        isDraggingScrubber={isDraggingScrubber}
        timezone={timezone}
        vessel={vessel}
        handleDragEnd={handleDragEnd}
        handleDragStart={handleDragStart}
        handleMove={handleMove}
      />
    </VStack>
  );
};

export default Scrubber;
