import { subDays } from 'date-fns';
import { JobTypes, type TripsPanelState, type Filters } from '../types';
import localStorageService from '../../../global/services/localStorageService';
import { translate } from '../../../global/translation';
import {
  defaultBarChartRangeSize,
  twoWeeksBarChartRangeSize,
} from '../../Playback/components/BarChartDatePicker/constants';

const startDate = subDays(new Date(), 7);
startDate.setHours(1, 0, 0, 0);
const endDate = subDays(new Date(), 1);
endDate.setHours(23, 59, 59, 0);

export const tripsStoreLocalStorageKey = 'tripsStore_cache';
export const tripsPanelLocalStorageKey = 'tripsPanelState';
export const barChartRangeSizeStorageKey = 'barChartRangeSize';

const savedFilter = localStorageService.getItem<Filters>(tripsStoreLocalStorageKey);
const savedTripsPanelState = localStorageService.getItem<TripsPanelState>(tripsPanelLocalStorageKey);

export const defaultFilters: Filters = {
  port: '',
  startDate: startDate.toISOString(),
  endDate: endDate.toISOString(),
  tripType: [translate('TRIP_TYPE_ALL')],
  vesselName: '',
  jobType: JobTypes.all,
};

export const initStateFilters: Filters = {
  port: savedFilter?.port ?? defaultFilters.port,
  startDate: startDate.toISOString(),
  endDate: endDate.toISOString(),
  vesselName: defaultFilters.vesselName,
  tripType: savedFilter?.tripType ?? defaultFilters.tripType,
  jobType: savedFilter?.jobType ?? defaultFilters.jobType,
};

export const initialTripsPanelState = savedTripsPanelState;
export const getInitialBarChartRangeInDays = (isMobileView: boolean): number => {
  const cachedValue = localStorageService.getItem<number>(barChartRangeSizeStorageKey);
  if (cachedValue) {
    return cachedValue;
  }

  return isMobileView ? defaultBarChartRangeSize : twoWeeksBarChartRangeSize;
};
