import { type FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Flex, Spinner, Text, useBoolean } from '@chakra-ui/react';
import { forEach, groupBy } from 'lodash';
import cx from 'classnames';
import TripsPanelHeaderSection from './components/TripsPanelHeaderSection';
import TripCard from './components/TripCard';
import NoTripsFound from './components/NoTripsFound';
import { useDispatch, useTripsContext } from '../../../../global/state/GlobalProvider';
import { TripsPanelState, type Job, type Trip, type TripGroup } from '../../../Trips/types';
import { Timestamp } from '../../utils/timestamp';
import { useDetectScrolledToBottom } from '../../hooks/useDetectScrolledToBottom';
import { getEventFactory } from '../../../../global/utils/get-event-factory';
import { Event, Feature, type MmsiKey, type Page } from '../../../../global/types';
import { useAppInsights } from '../../../../hooks/useAppInsights';
import { useGenericQuery } from '../../../Trips/hooks/useGenericQuery';
import { ActionType } from '../../../../global/state/reducer';
import { useTripsInfiniteQuery } from '../../hooks/useTripsInfiniteQuery';
import { type VesselViewModel } from '../../types';
import BarChartDatePicker from '../BarChartDatePicker';
import { initStateFilters } from '../../../Trips/utils/initial-trips-state';
import { useOperationData } from '../../hooks/useOperationData';
import { getLocalTimestampFromDate } from '../../../../global/utils/date-utils';
import { useDetectMobileScreenWidth } from '../../../../hooks/useDetectMobileScreenWidth';
import { useEcoRatingTooltip } from '../../hooks/useEcoRatingTooltip';

const defaultSelectedDateValue = null;

export interface Props {
  isOpen: boolean;
  vesselData: Record<MmsiKey, VesselViewModel>;
  page: Page;
  onClose: () => void;
  setStartTimestamp: (timestamp: Timestamp) => void;
  setTimestamp: (timestamp: Timestamp) => void;
  onTargetVesselChange: (mmsi: string) => void;
  setHoveredJob: (job: Job) => void;
  timezone: string;
}

const TripsPanel: FC<Props> = ({
  isOpen,
  vesselData,
  page,
  onClose,
  setStartTimestamp,
  setTimestamp,
  onTargetVesselChange,
  setHoveredJob,
  timezone,
}) => {
  const [displayMoreFilters, setDisplayMoreFilters] = useBoolean();
  const { pagination, filters, operations, tripsPanelState } = useTripsContext();
  const tripsDispatch = useDispatch();
  const appInsights = useAppInsights();
  const timeoutId = useRef<NodeJS.Timeout>(null);
  const [selectedDate, setSelectedDate] = useState<Date>(defaultSelectedDateValue);
  const { data: operation } = useOperationData(filters.port);
  const isMobileView = useDetectMobileScreenWidth();

  const { currentPage, limit } = pagination;

  const scrollRef = useRef<HTMLDivElement>();
  const { isBottom } = useDetectScrolledToBottom(scrollRef);
  useGenericQuery({
    actionType: ActionType.SET_VESSELNAMES,
    endpoint: `/trips/vessel-names/`,
    queryKey: 'useVesselNames',
  });

  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } = useTripsInfiniteQuery(
    filters,
    limit,
    currentPage,
    page
  );

  useEffect(() => {
    if (isBottom && hasNextPage && !isFetching && trips.length) {
      const event = getEventFactory(Event.TripsPanelScrolledToEnd)(page, filters.port);
      appInsights.trackAnalyticsEvent(event);
      void fetchNextPage();
    }
  }, [isBottom]);

  const trips = useMemo(() => {
    return (data?.pages ?? []).reduce<Trip[]>((result, current) => {
      return [...result, ...current.trips];
    }, []);
  }, [data]);

  useEffect(() => {
    if (isFetchingNextPage) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [isFetchingNextPage]);

  useEffect(() => {
    setSelectedDate(null);
  }, [operation]);

  const setDateFilter = useCallback(
    (date: Date): void => {
      if (date) {
        date.setHours(0, 0, 0);
        const startDate = getLocalTimestampFromDate(date, operation?.timezone).toDate().toISOString();
        date.setHours(23, 59, 59);
        const endDate = getLocalTimestampFromDate(date, operation?.timezone).toDate().toISOString();
        const newFilter = {
          ...filters,
          startDate,
          endDate,
        };
        tripsDispatch({ type: ActionType.UPDATE_FILTER, payload: newFilter });
        setSelectedDate(date);
        appInsights.trackAnalyticsEvent(
          getEventFactory(Event.FilteredDate)(Feature.TripsHistoryPanel, page, filters, newFilter, startDate)
        );
      } else {
        const newFilter = {
          ...filters,
          startDate: initStateFilters.startDate,
          endDate: initStateFilters.endDate,
        };
        tripsDispatch({ type: ActionType.UPDATE_FILTER, payload: newFilter });
        setSelectedDate(null);
        appInsights.trackAnalyticsEvent(
          getEventFactory(Event.FilteredDate)(Feature.TripsHistoryPanel, page, filters, newFilter, newFilter.startDate)
        );
      }
    },
    [operation, tripsDispatch, appInsights, filters]
  );

  const resetFilters = useCallback((): void => {
    setSelectedDate(null);
    tripsDispatch({ type: ActionType.RESET_FILTER });
    const event = getEventFactory(Event.FiltersReset)(Feature.TripsHistoryPanel, page, filters);
    appInsights.trackAnalyticsEvent(event);
  }, []);

  const onJobHovered = useCallback(
    (tripId: string, jobId: string): void => {
      const job = trips.find(t => t.tripId === tripId)?.jobs?.find(j => j.jobId === jobId);

      if (!job) {
        return;
      }

      timeoutId.current = setTimeout(() => {
        setHoveredJob(job);
      }, Timestamp.fromMilliseconds(300).toMilliseconds());
    },
    [timeoutId.current, trips]
  );

  const onJobHoverOut = useCallback((): void => {
    clearTimeout(timeoutId.current);
    timeoutId.current = null;
    setHoveredJob(null);
  }, [timeoutId.current]);

  const ecoRatingTooltip = useEcoRatingTooltip(tripsPanelState === TripsPanelState.Open);

  const handleCloseTripsPanel = (): void => {
    onClose();
    ecoRatingTooltip.onClose();
  };

  const tripsNodes = useMemo(() => {
    const dictionary = groupBy(trips, t =>
      Timestamp.fromSeconds(t.timestampStart).toLocationDateTimeOffset(timezone).format('ddd MMM DD YYYY')
    );
    const groupedTrips: TripGroup[] = [];
    forEach(dictionary, (trips, date) => groupedTrips.push({ date, trips }));

    const onJobSelected = (): void => {
      setDisplayMoreFilters.off();
      if (isMobileView) {
        handleCloseTripsPanel();
      }
    };

    return groupedTrips.map(group => (
      <div key={group.date}>
        <Text as="p" className="trip-group-header">
          {group.date}
        </Text>
        {group.trips.length > 0 &&
          group.trips.map(trip => (
            <TripCard
              key={trip.id}
              trip={trip}
              vesselData={vesselData}
              onJobSelected={onJobSelected}
              onJobHover={onJobHovered}
              onJobHoverOut={onJobHoverOut}
              vesselNameSearchPhrase={filters.vesselName}
              setStartTimestamp={setStartTimestamp}
              setTimestamp={setTimestamp}
              onTargetVesselChange={onTargetVesselChange}
              operations={operations}
              page={page}
              timezone={timezone}
            />
          ))}
      </div>
    ));
  }, [trips, vesselData]);

  const renderTripsPanelJobsContent = (): JSX.Element => {
    if (isFetching && !isFetchingNextPage) {
      return (
        <Flex justifyContent="center">
          <Spinner />
        </Flex>
      );
    }

    if (tripsNodes.length) {
      return (
        <>
          {tripsNodes}
          <Flex className="bottom-spinner" justifyContent="center" hidden={!isFetchingNextPage}>
            <Spinner />
          </Flex>
        </>
      );
    }

    return <NoTripsFound page={page} />;
  };

  return (
    <Flex className={cx('trips-panel-container', { expanded: isOpen })} flexDirection="column">
      <Box>
        <TripsPanelHeaderSection
          onClose={handleCloseTripsPanel}
          displayMoreFilters={displayMoreFilters}
          toggleMoreFilters={setDisplayMoreFilters.toggle}
          page={page}
          selectedDate={selectedDate}
          setDateFilter={setDateFilter}
          resetFilters={resetFilters}
        />
      </Box>
      <Box className="trips-panel-jobs-list-container" ref={scrollRef} onScroll={setDisplayMoreFilters.off}>
        <BarChartDatePicker
          setDateFilter={setDateFilter}
          selectedDate={selectedDate}
          height={150}
          operation={operation}
          ecoRatingTooltip={ecoRatingTooltip}
        />
        {renderTripsPanelJobsContent()}
      </Box>
    </Flex>
  );
};

export default TripsPanel;
