import { Point, type Anchor, Popup, type Map, type PopupOptions } from 'mapbox-gl';
import { createRoot } from 'react-dom/client';
import { type Location } from '../../../../../global/types';
import {
  type TidalDiamondFeatureProperties,
  type TidalArrowFeatureProperties,
  type VesselFeatureProperties,
  type VesselTrackFeatureProperties,
  type ActivityMarkerFeatureProperties,
  type TooltipProperties,
} from '../types';
import { type TooltipRef } from '../../../types';
import { TidalDiamondTooltip } from '../components/tooltips/TidalDiamondTooltip';
import VesselTooltip from '../components/tooltips/VesselTooltip';
import { VesselTrackTooltip } from '../components/tooltips/VesselTrackTooltip';
import { ActivityMarkerTooltip } from '../components/tooltips/ActivityMarkerTooltip';
import { TidalArrowTooltip } from '../components/tooltips/TidalArrowTooltip';

interface AnchorSquare {
  anchor: Anchor;
  topLeft: Point;
  bottomRight: Point;
}

const generateMobileAnchorSquares = (maxX: number, maxY: number): AnchorSquare[] => {
  const oneThirdX = window.innerWidth / 3;
  const twoThirdsX = oneThirdX * 2;
  const halfY = maxY / 2;
  return [
    {
      anchor: 'top-left',
      topLeft: new Point(0, 0),
      bottomRight: new Point(oneThirdX, halfY),
    },
    {
      anchor: 'top',
      topLeft: new Point(oneThirdX, 0),
      bottomRight: new Point(twoThirdsX, halfY),
    },
    {
      anchor: 'top-right',
      topLeft: new Point(twoThirdsX, 0),
      bottomRight: new Point(maxX, halfY),
    },
    {
      anchor: 'bottom-left',
      topLeft: new Point(0, halfY),
      bottomRight: new Point(oneThirdX, maxY),
    },
    {
      anchor: 'bottom',
      topLeft: new Point(oneThirdX, halfY),
      bottomRight: new Point(twoThirdsX, maxY),
    },
    {
      anchor: 'bottom-right',
      topLeft: new Point(twoThirdsX, halfY),
      bottomRight: new Point(maxX, maxY),
    },
  ];
};

const generateDesktopAnchorSquares = (maxX: number, maxY: number): AnchorSquare[] => {
  const tooltipX = 300;
  const tooltipY = 135;
  return [
    {
      anchor: 'top-left',
      topLeft: new Point(0, 0),
      bottomRight: new Point(tooltipX, tooltipX),
    },
    {
      anchor: 'top',
      topLeft: new Point(tooltipX, 0),
      bottomRight: new Point(maxX - tooltipX, tooltipY),
    },
    {
      anchor: 'top-right',
      topLeft: new Point(maxX - tooltipX, 0),
      bottomRight: new Point(maxX, tooltipY),
    },
    {
      anchor: 'left',
      topLeft: new Point(0, tooltipY),
      bottomRight: new Point(tooltipX, maxY - tooltipY),
    },
    {
      anchor: 'bottom-left',
      topLeft: new Point(0, maxY - tooltipY),
      bottomRight: new Point(tooltipX, maxY),
    },
    {
      anchor: 'bottom',
      topLeft: new Point(tooltipX, maxY - tooltipY),
      bottomRight: new Point(maxX - tooltipX, maxY),
    },
    {
      anchor: 'bottom-right',
      topLeft: new Point(maxX - tooltipX, maxY - tooltipY),
      bottomRight: new Point(maxX, maxY),
    },
    {
      anchor: 'right',
      topLeft: new Point(maxX - tooltipX, tooltipY),
      bottomRight: new Point(maxX, maxY - tooltipY),
    },
  ];
};

const generateAnchorSquares = (): AnchorSquare[] => {
  const isMobileScreenWidth = window.innerWidth <= 768;
  const maxX = window.innerWidth;
  const maxY = window.innerHeight;

  return isMobileScreenWidth ? generateMobileAnchorSquares(maxX, maxY) : generateDesktopAnchorSquares(maxX, maxY);
};

const indicateTooltipAnchor = (mouseLocation: Point): Anchor => {
  const matchingSquare = generateAnchorSquares().find(s => isInsideSquare(s, mouseLocation));
  if (matchingSquare) {
    return matchingSquare.anchor;
  }

  return 'bottom';
};

const isInsideSquare = (square: AnchorSquare, point: Point): boolean => {
  return (
    square.topLeft.x < point.x &&
    square.bottomRight.x > point.x &&
    square.topLeft.y < point.y &&
    square.bottomRight.y > point.y
  );
};

const showTooltip = (
  tooltipProperties: TooltipProperties,
  tooltipHtml: React.ReactNode,
  popupOptions: PopupOptions,
  tooltipRef: React.MutableRefObject<TooltipRef>,
  map: Map
): void => {
  const popupNode = document.createElement('div');
  const root = createRoot(popupNode);
  const { location: locationString, tooltipId } = tooltipProperties;
  const location: Location = JSON.parse(locationString.toString());
  const {
    coordinates: { lon: longitude, lat: latitude },
  } = location;

  root.render(tooltipHtml);
  const popup = new Popup(popupOptions);
  popup.setLngLat([longitude, latitude]).setDOMContent(popupNode).addTo(map);
  popup.getElement().setAttribute('id', tooltipId);
  tooltipRef.current.popup = popup;
};

export const showVesselTooltip = (
  properties: VesselFeatureProperties,
  tooltipRef: React.MutableRefObject<TooltipRef>,
  map: Map,
  point: Point
): void => {
  const anchor = indicateTooltipAnchor(point);
  showTooltip(
    properties,
    <VesselTooltip properties={properties} />,
    {
      anchor,
      ...tooltipRef.current,
      closeButton: false,
      className: 'map-tooltip',
    },
    tooltipRef,
    map
  );
};

export const showVesselTrackTooltip = (
  trackProperties: VesselTrackFeatureProperties,
  tooltipRef: React.MutableRefObject<TooltipRef>,
  map: Map,
  timezone: string
): void => {
  showTooltip(
    trackProperties,
    <VesselTrackTooltip properties={trackProperties} timezone={timezone} />,
    {
      ...tooltipRef.current,
      closeButton: false,
      className: 'track-tooltip',
      anchor: null,
    },
    tooltipRef,
    map
  );
};

export const showActivityMarkerTooltip = (
  properties: ActivityMarkerFeatureProperties,
  tooltipRef: React.MutableRefObject<TooltipRef>,
  map: Map,
  timezone: string
): void => {
  showTooltip(
    properties,
    <ActivityMarkerTooltip properties={properties} timezone={timezone} />,
    {
      ...tooltipRef.current,
      closeButton: false,
      className: 'activity-marker-tooltip',
      anchor: null,
    },
    tooltipRef,
    map
  );
};

export const showQuiverPlotTooltip = (
  tidalArrowProperties: TidalArrowFeatureProperties,
  tooltipRef: React.MutableRefObject<TooltipRef>,
  map: Map
): void => {
  showTooltip(
    tidalArrowProperties,
    <TidalArrowTooltip properties={tidalArrowProperties} />,
    {
      ...tooltipRef.current,
      className: 'quiver-plot-tooltip',
      closeButton: true,
      anchor: null,
      closeOnClick: true,
      closeOnMove: false,
      focusAfterOpen: true,
    },
    tooltipRef,
    map
  );
};

export const showTidalDiamondTooltip = (
  tidalDiamondProperties: TidalDiamondFeatureProperties,
  tooltipRef: React.MutableRefObject<TooltipRef>,
  map: Map
): void => {
  showTooltip(
    tidalDiamondProperties,
    <TidalDiamondTooltip properties={tidalDiamondProperties} />,
    {
      ...tooltipRef.current,
      closeButton: false,
      className: 'tidal-diamond-tooltip',
      anchor: null,
    },
    tooltipRef,
    map
  );
};
