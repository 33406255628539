import { useCallback, useEffect, useState } from 'react';

export const useDetectScrolledToBottom = (node: React.MutableRefObject<HTMLDivElement>): { isBottom: boolean } => {
  const [isBottom, setIsBottom] = useState(false);

  const handleScroll = useCallback(() => {
    const { scrollTop, scrollHeight, clientHeight } = node.current;
    if (Math.round(scrollTop + clientHeight) === scrollHeight) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  }, [node]);

  useEffect(() => {
    if (node.current) {
      node?.current?.addEventListener('scroll', handleScroll);
      return () => {
        node?.current?.removeEventListener('scroll', handleScroll);
      };
    }
  }, [node, handleScroll]);
  return { isBottom };
};
