// eslint-disable-next-line @typescript-eslint/naming-convention
enum STATUS_CODES {
  OK = 200,
  CREATED = 201,
  ACCEPTED_FOR_PROCESSING = 202,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  SERVER_ERROR = 500,
}

export default STATUS_CODES;
