import { Tooltip, type PlacementWithLogical } from '@chakra-ui/react';
import { useState } from 'react';

interface MobileTooltipProps {
  label: string;
  children: React.ReactNode;
  placement?: PlacementWithLogical;
}

const MobileTooltip: React.FC<MobileTooltipProps> = ({ label, children, placement = 'bottom' }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleTooltip = (): void => {
    setIsOpen(!isOpen);
  };

  const closeTooltip = (): void => {
    setIsOpen(false);
  };

  return (
    <Tooltip aria-label={label} label={label} isOpen={isOpen} placement={placement}>
      <button type="button" onClick={toggleTooltip} onBlur={closeTooltip} aria-expanded={isOpen}>
        {children}
      </button>
    </Tooltip>
  );
};

export default MobileTooltip;
