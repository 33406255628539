/* eslint-disable */
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { selectAnatomy } from '@chakra-ui/anatomy';
import colors from '../colors';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyleSelect = definePartsStyle({
  field: {
    display: 'flex',
    padding: '10px 14px',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    borderRadius: '8px',
    border: `1px solid ${colors.gray[300]}`,
    bg: colors.base.white,
  },
  icon: {
    color: colors.gray[500],
    width: '20px',
    height: '20px',
  },
});

export const selectTheme = defineMultiStyleConfig({ baseStyle: baseStyleSelect });
