import { Tooltip, type PlacementWithLogical } from '@chakra-ui/react';
import MobileTooltip from './MobileTooltip';
import { useDetectMobileScreenWidth } from '../../../hooks/useDetectMobileScreenWidth';

interface Props {
  label: string;
  children: React.ReactNode;
  placement?: PlacementWithLogical;
}

const TooltipResolver: React.FC<Props> = ({ label, children, placement = 'bottom' }): JSX.Element => {
  const isMobileView = useDetectMobileScreenWidth();

  if (isMobileView) {
    return (
      <MobileTooltip label={label} placement={placement}>
        {children}
      </MobileTooltip>
    );
  }

  return (
    <Tooltip aria-label={label} label={label} placement={placement}>
      {children}
    </Tooltip>
  );
};

export default TooltipResolver;
