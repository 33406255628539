import { useMemo } from 'react';
import { orderBy, first } from 'lodash';
import { type HelmEvent, type HelmEventMarkerData } from '../types';
import { Timestamp } from '../utils/timestamp';
import { markerSizeInPixels } from '../components/Scrubber/constants';
import { type Range } from '../../../global/types';

export const useHelmEventMarkersData = (
  helmEvents: HelmEvent[],
  secondInPixels: number,
  timelineRange: Range<Timestamp>
): HelmEventMarkerData[] => {
  const noOverlapMinSecondsDistance = useMemo(() => {
    return Math.ceil(markerSizeInPixels / secondInPixels);
  }, [secondInPixels]);

  const calculateTimeDelta = (earlier: HelmEvent, later: HelmEvent): Timestamp => {
    const earlierTimestamp = Timestamp.fromMilliseconds(earlier.timestamp.valueInMilliseconds);
    const laterTimestamp = Timestamp.fromMilliseconds(later.timestamp.valueInMilliseconds);
    return laterTimestamp.subtract(earlierTimestamp);
  };

  const hasOverlappingNeighbor = (index: number, event: HelmEvent, collection: HelmEvent[]): boolean => {
    const isNeighborOverlapping = (earlierEvent: HelmEvent, laterEvent: HelmEvent): boolean => {
      if (!earlierEvent || !laterEvent) {
        return false;
      }

      const timeDelta = calculateTimeDelta(earlierEvent, laterEvent);
      return Math.abs(timeDelta.toSeconds()) <= noOverlapMinSecondsDistance;
    };

    const leftNeighbor = index <= 0 ? null : collection[index - 1];
    const rightNeighbor = index >= collection.length - 1 ? null : collection[index + 1];

    return isNeighborOverlapping(leftNeighbor, event) || isNeighborOverlapping(event, rightNeighbor);
  };

  const markerHalfWidth = markerSizeInPixels / 2;
  const calculateOffset = (event: HelmEvent): number => {
    const eventTimestamp = Timestamp.fromMilliseconds(event.timestamp.valueInMilliseconds);
    return eventTimestamp.subtract(timelineRange.from).toSeconds() * secondInPixels - markerHalfWidth;
  };

  const classifiedEvents = useMemo(() => {
    const orderedEvents = orderBy(helmEvents, e => e.timestamp.valueInMilliseconds);

    const notOverlapping: HelmEvent[] = [];
    const currentOverlappingGroup: HelmEvent[] = [];
    const overlappingGroups: HelmEvent[][] = [];
    orderedEvents.forEach((event, index) => {
      if (hasOverlappingNeighbor(index, event, orderedEvents)) {
        currentOverlappingGroup.push(event);
      } else {
        notOverlapping.push(event);
        if (currentOverlappingGroup.length) {
          overlappingGroups.push([...currentOverlappingGroup]);
        }
        currentOverlappingGroup.splice(0, currentOverlappingGroup.length);
      }
    });

    if (currentOverlappingGroup.length) {
      overlappingGroups.push([...currentOverlappingGroup]);
    }

    return { notOverlapping, overlappingGroups };
  }, [helmEvents, secondInPixels]);

  return useMemo(() => {
    const { notOverlapping, overlappingGroups } = classifiedEvents;

    const result: HelmEventMarkerData[] = notOverlapping.map(e => {
      return {
        ...e,
        offset: calculateOffset(e),
      };
    });

    overlappingGroups.forEach(group => {
      const initialOffset = calculateOffset(first(group));
      group.forEach((event, index) => {
        result.push({
          ...event,
          offset: initialOffset + index * markerSizeInPixels,
        });
      });
    });

    return result;
  }, [classifiedEvents, timelineRange]);
};
