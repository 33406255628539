import { createContext, useContext, useReducer } from 'react';
import { initialAppState, appReducer, type AppState, type AppAction } from './app-reducer';

const AppContext = createContext(initialAppState);
const AppDispatchContext = createContext(null);
export const AppContextProvider = ({ children }): JSX.Element => {
  const [state, dispatch] = useReducer(appReducer, initialAppState);

  return (
    <AppContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}> {children} </AppDispatchContext.Provider>
    </AppContext.Provider>
  );
};

export const useAppContext = (): AppState => useContext(AppContext);
export const useAppDispatch = (): React.Dispatch<AppAction> => useContext(AppDispatchContext);
