import { type FC } from 'react';
import { Box, Flex, Spacer, Text } from '@chakra-ui/react';
import { type Timestamp } from '../../../utils/timestamp';

const timeFormat = 'h:mm A';

interface Props {
  tripStart: Timestamp;
  tripEnd: Timestamp;
  timezone: string;
}

const JobDates: FC<Props> = ({ tripStart, tripEnd, timezone }) => {
  const localTripStart = timezone ? tripStart.toLocationDateTimeOffset(timezone) : tripStart.toBrowserDateTimeOffset();
  const localTripEnd = timezone ? tripEnd.toLocationDateTimeOffset(timezone) : tripEnd.toBrowserDateTimeOffset();

  return (
    <Flex direction="column">
      <Flex className="time-container time-text">
        <Box>
          <Text>{localTripStart.format(timeFormat)}</Text>
        </Box>
        <Spacer />
        <Box>
          <Text>→</Text>
        </Box>
        <Spacer />
        <Box>
          <Text>{localTripEnd.format(timeFormat)}</Text>
        </Box>
      </Flex>
    </Flex>
  );
};

export default JobDates;
