import { type FC } from 'react';
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useAppInsights } from '../../../../../hooks/useAppInsights';
import { useDispatch, useTripsContext } from '../../../../../global/state/GlobalProvider';
import { ActionType } from '../../../../../global/state/reducer';
import { getEventFactory } from '../../../../../global/utils/get-event-factory';
import { Event, Feature, type Page, TripType } from '../../../../../global/types';
import { tripTypeDisplayStrings } from '../../../../../global/constants';
import { translate } from '../../../../../global/translation';
import type { Filters } from '../../../../Trips/types';

interface Props {
  page: Page;
}

const TripTypeDropDown: FC<Props> = ({ page }) => {
  const appInsights = useAppInsights();
  const { filters } = useTripsContext();
  const tripsDispatch = useDispatch();

  const tripTypeAll = translate('TRIP_TYPE_ALL');
  const tripTypes = [
    tripTypeAll,
    tripTypeDisplayStrings[TripType.Berthing],
    tripTypeDisplayStrings[TripType.Repositioning],
    tripTypeDisplayStrings[TripType.Sailing],
    tripTypeDisplayStrings[TripType.Shifting],
    tripTypeDisplayStrings[TripType.Uncertain],
  ];

  const applyFilterHandler = (event): void => {
    const value = event.target.innerText as string;
    let newFilter: Filters;

    if (value === tripTypeAll) {
      newFilter = {
        ...filters,
        tripType: [value],
      };
      tripsDispatch({ type: ActionType.UPDATE_FILTER, payload: newFilter });
      appInsights.trackAnalyticsEvent(
        getEventFactory(Event.FilteredTripType)(Feature.TripsHistoryPanel, page, filters, newFilter)
      );
      return;
    }

    if (filters.tripType.includes(value)) {
      newFilter = {
        ...filters,
        tripType: filters.tripType.filter(type => type !== value),
      };
    } else {
      newFilter = {
        ...filters,
        tripType: [...filters.tripType.filter(type => type !== tripTypeAll), value],
      };
    }

    if (newFilter.tripType.length === 0) {
      newFilter = {
        ...filters,
        tripType: [tripTypeAll],
      };
    }
    tripsDispatch({ type: ActionType.UPDATE_FILTER, payload: newFilter });
    appInsights.trackAnalyticsEvent(
      getEventFactory(Event.FilteredTripType)(Feature.TripsHistoryPanel, page, filters, newFilter)
    );
  };

  return (
    <Box className="trip-type-drop-down">
      <Menu closeOnSelect={false}>
        <MenuButton as={Button} className="menu-button" rightIcon={<ChevronDownIcon />}>
          <VStack alignItems="flex-start" spacing={0}>
            <Text textOverflow="ellipsis" className="filter-field-value">
              {filters.tripType.join(', ')}
            </Text>
          </VStack>
        </MenuButton>
        <MenuList className="chakra-menu__menu-list">
          <MenuOptionGroup title="Trip type" value={filters.tripType} type="checkbox" as={UnorderedList}>
            {tripTypes.map(tripType => (
              <MenuItemOption
                key={tripType}
                value={tripType}
                onClick={applyFilterHandler}
                as="li"
                className="filter-menu-list-item"
                position="relative"
              >
                {tripType}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default TripTypeDropDown;
