import {
  type CopyLinkEventFactory,
  type ShareLinkEventFactory,
  type NPSSurveyEventFactory,
  type EnterExit3DViewEventFactory,
  type EventFactory,
  type FilterChangeEventFactory,
  type FilteredDateEventFactory,
  type FilteredVesselEventFactory,
  type FiltersEventFactory,
  type LiveviewOnPlaybackEventFactory,
  type ExceptionEventFactory,
  type PanMapEventFactory,
  type ScrubberPlaybackEventFactory,
  type TimelineScrubbedEventFactory,
  type TimelineZoomedEventFactory,
  type VesselHoverEventFactory,
  type VesselTargettedEventFactory,
  type WrongInformationEventFactory,
  type ZoomEventFactory,
  type TimelineSpeedChangedEventFactory,
  type TimelineMarkerHoveredEventFactory,
  type VesselSelectedEventFactory,
  type VesselSearchResultsEventFactory,
  type VesselTrackHoverEventFactory,
  type VesselNamesToggleEventFactory,
  type VesselTracksSettingsToggleEventFactory,
  type QuickAccessPanelToggleEventFactory,
  type RedirectedToPlaybackEventFactory,
  type OperationSelectedEventFactory,
  type TripsPanelToggleEventFactory,
  type TripsPanelJobInteractionEventFactory,
  type TripsPanelScrolledToEndEventFactory,
  type VesselUntargetedEventFactory,
  type QuiverPlotToggleEventFactory,
  type TidalDiamondHoverEventFactory,
  type TidalArrowClickEventFactory,
  type NauticalMapToggleEventFactory,
} from './event-factories-definitions';
import { eventFactories } from './event-factories';
import { type Event } from '../types';

export function getEventFactory(event: Event.CopyLink): CopyLinkEventFactory;
export function getEventFactory(event: Event.ShareLink): ShareLinkEventFactory;
export function getEventFactory(event: Event.NPSSurvey): NPSSurveyEventFactory;
export function getEventFactory(event: Event.FiltersReset): FiltersEventFactory;
export function getEventFactory(event: Event.ZoomMap): ZoomEventFactory;
export function getEventFactory(event: Event.VesselTargetted): VesselTargettedEventFactory;
export function getEventFactory(event: Event.VesselUntargeted): VesselUntargetedEventFactory;
export function getEventFactory(event: Event.PlaybackStarted | Event.PlaybackPaused): ScrubberPlaybackEventFactory;
export function getEventFactory(event: Event.FilteredVessel): FilteredVesselEventFactory;
export function getEventFactory(
  event: Event.NoTripsFound | Event.FilteredTripType | Event.FilteredCompetitors
): FilterChangeEventFactory;
export function getEventFactory(event: Event.FilteredDate): FilteredDateEventFactory;
export function getEventFactory(event: Event.Entered3dView | Event.Exited3dView): EnterExit3DViewEventFactory;
export function getEventFactory(event: Event.PanMap): PanMapEventFactory;
export function getEventFactory(event: Event.VesselHovered): VesselHoverEventFactory;
export function getEventFactory(event: Event.ReplayClosed): LiveviewOnPlaybackEventFactory;
export function getEventFactory(event: Event.TimelineScrubbed): TimelineScrubbedEventFactory;
export function getEventFactory(event: Event.TimelineZoomed): TimelineZoomedEventFactory;
export function getEventFactory(event: Event.WrongInformation): WrongInformationEventFactory;
export function getEventFactory(event: Event.Exception): ExceptionEventFactory;
export function getEventFactory(event: Event.TimelineSpeedChanged): TimelineSpeedChangedEventFactory;
export function getEventFactory(event: Event.TimelineMarkerHovered): TimelineMarkerHoveredEventFactory;
export function getEventFactory(event: Event.VesselFoundAndSelected): VesselSelectedEventFactory;
export function getEventFactory(
  event: Event.VesselTrackHovered | Event.ActivityMarkerHovered
): VesselTrackHoverEventFactory;
export function getEventFactory(
  event: Event.VesselTracksEnabled | Event.VesselTracksDisabled
): VesselTracksSettingsToggleEventFactory;
export function getEventFactory(
  event: Event.VesselSearchGotResults | Event.VesselSearchGotNoResults
): VesselSearchResultsEventFactory;
export function getEventFactory(
  event: Event.EnableVesselNames | Event.DisableVesselNames
): VesselNamesToggleEventFactory;
export function getEventFactory(event: Event.EnableQuiverPlot | Event.DisableQuiverPlot): QuiverPlotToggleEventFactory;
export function getEventFactory(
  event: Event.EnableNauticalMap | Event.DisableNauticalMap
): NauticalMapToggleEventFactory;
export function getEventFactory(
  event:
    | Event.QuickAccessPanelClosed
    | Event.QuickAccessPanelOpened
    | Event.PickTimeOpened
    | Event.BackFromPickTimeClicked
    | Event.BackFromVesselSearchClicked
    | Event.VesselSearchOpened
    | Event.VesselSearchClosed
): QuickAccessPanelToggleEventFactory;
export function getEventFactory(event: Event.RedirectedToPlaybackFromPickTime): RedirectedToPlaybackEventFactory;
export function getEventFactory(event: Event.OperationSelected): OperationSelectedEventFactory;
export function getEventFactory(
  event: Event.TripsHistoryPanelOpened | Event.TripsHistoryPanelClosed
): TripsPanelToggleEventFactory;
export function getEventFactory(
  event: Event.TripsPanelTripClicked | Event.TripsPanelTripHovered
): TripsPanelJobInteractionEventFactory;
export function getEventFactory(event: Event.TripsPanelScrolledToEnd): TripsPanelScrolledToEndEventFactory;
export function getEventFactory(event: Event.TidalDiamondHovered): TidalDiamondHoverEventFactory;
export function getEventFactory(event: Event.TidalArrowClicked): TidalArrowClickEventFactory;
export function getEventFactory(event: Event): EventFactory {
  return eventFactories[event];
}
