import { type FC } from 'react';
import { Grid, GridItem, Text } from '@chakra-ui/react';
import formatcoords from 'formatcoords';
import { type TooltipProps } from './types';
import { type TidalArrowFeatureProperties } from '../../types';
import { type Location } from '../../../../../../global/types';
import { translate, translateAndFormat } from '../../../../../../global/translation';

export const TidalArrowTooltip: FC<TooltipProps<TidalArrowFeatureProperties>> = ({ properties }) => {
  const { set, rate } = properties;

  const location: Location = JSON.parse(properties.location.toString());
  const {
    coordinates: { lon: longitude, lat: latitude },
  } = location;
  const coordinatesFormatted = formatcoords([latitude, longitude]).format('Ff', { decimalPlaces: 1 });

  return (
    <Grid className="popup-container" templateColumns="1fr 1fr">
      <GridItem className="text-left" colSpan={2}>
        <Text className="text-header">{coordinatesFormatted}</Text>
      </GridItem>
      <GridItem className="text-left" colSpan={2}>
        <Text className="text-xs-regular">{translate('INFORMATION_ABOUT_LOCATION')}</Text>
      </GridItem>
      <GridItem className="text-left" colSpan={1}>
        <Text className="text-sm-regular">{translate('TIDAL_SET_AND_RATE')}</Text>
      </GridItem>
      <GridItem className="text-right" colSpan={1}>
        <Text className="text-sm-regular">
          {translateAndFormat('TOOLTIP_DIRECTION_SPEED', set.toFixed(0), rate.toFixed(1))}
        </Text>
      </GridItem>
    </Grid>
  );
};
