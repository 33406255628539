import { type Pagination, type Filters, type TripsData, type TripsPanelState } from '../../pages/Trips/types';
import {
  defaultFilters,
  initialTripsPanelState,
  initStateFilters,
  tripsPanelLocalStorageKey,
  tripsStoreLocalStorageKey,
} from '../../pages/Trips/utils/initial-trips-state';
import { initStatePagination } from '../../pages/Trips/utils/initStatePagination';
import localStorageService from '../services/localStorageService';
import { MapLayer, type Settings, type OperationViewModel } from '../../pages/Playback/components/Map/types';
import { type GlobalContext } from '../types';

export const initialTripsState: GlobalContext = {
  trips: {
    filters: initStateFilters,
    previousFilters: initStateFilters,
    pagination: initStatePagination,
    tripsData: { isLoading: false, trips: [] },
    operations: [],
    vesselNames: [],
    tripsPanelState: initialTripsPanelState,
  },
  map: {
    settings: {
      layer: MapLayer.Default,
      showVesselTracks: true,
      showVesselNames: true,
      showQuiverPlot: false,
      showNauticalMap: true,
    },
  },
};

export type Action =
  | { type: ActionType.UPDATE_FILTER; payload: Filters }
  | { type: ActionType.RESET_FILTER }
  | { type: ActionType.SET_PAGINATION; payload: Pagination }
  | { type: ActionType.SET_TRIPS; payload: TripsData }
  | { type: ActionType.SET_OPERATIONS; payload: OperationViewModel[] }
  | { type: ActionType.SET_VESSELNAMES; payload: string[] }
  | { type: ActionType.UPDATE_SETTINGS; payload: Settings }
  | { type: ActionType.SET_TRIPS_PANEL_STATE; payload: { tripsPanelState: TripsPanelState } };

export enum ActionType {
  UPDATE_FILTER,
  RESET_FILTER,
  SET_PAGINATION,
  SET_TRIPS,
  SET_OPERATIONS,
  SET_VESSELNAMES,
  UPDATE_SETTINGS,
  SET_TRIPS_PANEL_STATE,
}

export const globalReducer = (state: GlobalContext, action: Action): GlobalContext => {
  switch (action.type) {
    case ActionType.UPDATE_FILTER:
      localStorageService.setItem(tripsStoreLocalStorageKey, action.payload);
      return {
        ...state,
        trips: {
          ...state.trips,
          filters: action.payload,
          pagination: initStatePagination,
          previousFilters: state.trips.filters,
        },
      };
    case ActionType.RESET_FILTER: {
      const resetFilter = { ...defaultFilters, port: state.trips.filters.port };
      localStorageService.setItem(tripsStoreLocalStorageKey, resetFilter);
      return {
        ...state,
        trips: {
          ...state.trips,
          previousFilters: state.trips.filters,
          filters: resetFilter,
          pagination: initStatePagination,
        },
      };
    }
    case ActionType.SET_PAGINATION:
      return {
        ...state,
        trips: {
          ...state.trips,
          pagination: action.payload,
        },
      };
    case ActionType.SET_TRIPS:
      return {
        ...state,
        trips: {
          ...state.trips,
          tripsData: action.payload,
        },
      };
    case ActionType.SET_OPERATIONS:
      return {
        ...state,
        trips: {
          ...state.trips,
          operations: action.payload,
        },
      };
    case ActionType.SET_VESSELNAMES:
      return {
        ...state,
        trips: {
          ...state.trips,
          vesselNames: action.payload,
        },
      };
    case ActionType.UPDATE_SETTINGS:
      return {
        ...state,
        map: {
          ...state.map,
          settings: action.payload,
        },
      };
    case ActionType.SET_TRIPS_PANEL_STATE:
      localStorageService.setItem(tripsPanelLocalStorageKey, action.payload.tripsPanelState);

      return {
        ...state,
        trips: {
          ...state.trips,
          tripsPanelState: action.payload.tripsPanelState,
        },
      };
    default:
      return state;
  }
};
