import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { menuAnatomy } from '@chakra-ui/anatomy';
import colors from '../colors';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle({
  list: {
    borderRadius: '6px',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '24px',
  },
  item: {
    paddingTop: '12px',
    paddingBottom: '12px',
    color: colors.gray[900],
    _hover: {
      bg: colors.gray[50],
    },
    _focus: {
      bg: colors.gray[50],
    },
  },
  groupTitle: {
    lineHeight: '24px',
  },
});
export const menuTheme = defineMultiStyleConfig({ baseStyle });
