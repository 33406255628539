import { type FC } from 'react';
import { Button, Stack, Text } from '@chakra-ui/react';
import { translate } from '../../../../../global/translation';
import { ActionType } from '../../../../../global/state/reducer';
import { useDispatch, useTripsContext } from '../../../../../global/state/GlobalProvider';
import { useAppInsights } from '../../../../../hooks/useAppInsights';
import { getEventFactory } from '../../../../../global/utils/get-event-factory';
import { Event, Feature, type Page } from '../../../../../global/types';

interface Props {
  page: Page;
}

const NoTripsFound: FC<Props> = ({ page }) => {
  const tripsDispatch = useDispatch();
  const { filters } = useTripsContext();
  const appInsights = useAppInsights();

  const resetFilters = (): void => {
    tripsDispatch({ type: ActionType.RESET_FILTER });
    const event = getEventFactory(Event.FiltersReset)(Feature.TripsHistoryPanel, page, filters);
    appInsights.trackAnalyticsEvent(event);
  };

  return (
    <Stack className="no-trips-container" spacing="3">
      <Text className="text-header">{translate('NO_TRIPS')}</Text>
      <Text textAlign="center">{translate('NO_MATCH_ON_SEARCH')}</Text>
      <Button variant="secondary" onClick={resetFilters}>
        {translate('CLEAR_SEARCH')}
      </Button>
    </Stack>
  );
};

export default NoTripsFound;
