import { type FunctionComponent, type ReactNode, useEffect } from 'react';
import { MsalProvider } from '@azure/msal-react';
import { type EventMessage, EventType } from '@azure/msal-browser';
import { useAppDispatch } from './AppContextProvider';
import { trackLogin } from './utils/rate-app-utils';
import { getAuthClient } from '../utils/authUtils';

interface Props {
  children: ReactNode;
}

const AuthProvider: FunctionComponent<Props> = ({ children }) => {
  const appDispatch = useAppDispatch();
  const authClient = getAuthClient();

  useEffect(() => {
    authClient.addEventCallback((message: EventMessage) => {
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        trackLogin(appDispatch);
      }
    });
  }, []);

  return <MsalProvider instance={authClient}> {children} </MsalProvider>;
};

export default AuthProvider;
