/* eslint-disable */
import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import colors from '../colors';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    color: colors.gray[900],
    fontWeight: '500',
    _placeholder: {
      color: colors.gray[500],
    },
  },
});

export const inputTheme = defineMultiStyleConfig({ baseStyle });
