import { defineStyleConfig } from '@chakra-ui/react';
import colors from '../colors';

const primaryDisabledStyles = {
  bg: colors.primary[200],
  border: `1px solid ${colors.primary[200]}`,
  opacity: 'unset',
};

const secondaryDisabledStyles = {
  bg: colors.base.white,
  border: `1px solid ${colors.gray[200]}`,
  color: colors.gray[300],
  opacity: 'unset',
};

const closeDisabledStyles = {
  bg: colors.base.white,
  border: `1px solid ${colors.gray[200]}`,
  color: colors.gray[300],
  opacity: 'unset',
};

export const buttonTheme = defineStyleConfig({
  sizes: {
    sm: {
      fontSize: '14px',
      padding: '8px 14px',
    },
    md: {
      fontSize: '14px',
      padding: '10px 16px',
    },
    lg: {
      fontSize: '16px',
      padding: '10px 18px',
    },
    xl: {
      fontSize: '16px',
      padding: '12px 20px',
    },
    '2xl': {
      fontSize: '18px',
      padding: '16px 28px',
    },
  },
  baseStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    _focus: {
      border: `1px solid ${colors.primary[300]}`,
      borderColor: colors.primary[300],
    },
  },
  variants: {
    primary: {
      bg: colors.primary[600],
      color: colors.base.white,
      border: `1px solid ${colors.primary[600]}`,
      _disabled: {
        ...primaryDisabledStyles,
      },
      _hover: {
        bg: colors.primary[700],
        border: `1px solid ${colors.primary[700]}`,
        _disabled: {
          ...primaryDisabledStyles,
        },
      },
    },
    secondary: {
      bg: colors.base.white,
      color: colors.gray[700],
      border: `1px solid ${colors.gray[300]}`,
      _disabled: {
        ...secondaryDisabledStyles,
      },
      _hover: {
        bg: colors.gray[50],
        color: colors.gray[800],
        _disabled: {
          ...secondaryDisabledStyles,
        },
      },
    },
    close: {
      bg: colors.error[400],
      color: colors.gray[700],
      border: `1px solid ${colors.error[400]}`,
      _disabled: {
        ...closeDisabledStyles,
      },
      _hover: {
        bg: colors.error[500],
        color: colors.gray[700],
        _disabled: {
          ...closeDisabledStyles,
        },
      },
    },
    icon: {
      bg: colors.base.white,
      color: colors.gray[700],
      border: 'none',
      boxShadow: 'none',
      _disabled: {
        ...secondaryDisabledStyles,
      },
      _hover: {
        bg: colors.gray[50],
        color: colors.gray[800],
        _disabled: {
          ...secondaryDisabledStyles,
        },
      },
    },
  },
  defaultProps: {
    variant: 'primary',
  },
});
