import { type Def, patternLinesDef } from '@nivo/core';
import { type Speed, type HelmEventContext } from './types';
import { ActivityType } from '../../../../global/types';
import colors from '../../../../theme/colors';
import { HelmEventType } from '../../types';
import { ReactComponent as CrewLog } from '../../../../static/svg/CrewLog.svg';
import { ReactComponent as DemobilisationStarted } from '../../../../static/svg/DemobilisationStarted.svg';
import { ReactComponent as MobilisationStarted } from '../../../../static/svg/MobilisationStarted.svg';
import { ReactComponent as RequestedTime } from '../../../../static/svg/RequestedTime.svg';
import { ReactComponent as SlowSpeedIcon } from '../../../../static/svg/SlowSpeed.svg';
import { ReactComponent as DefaultSpeedIcon } from '../../../../static/svg/DefaultSpeed.svg';
import { ReactComponent as FasterSpeedIcon } from '../../../../static/svg/FasterSpeed.svg';
import { ReactComponent as VeryFastSpeedIcon } from '../../../../static/svg/VeryFastSpeed.svg';
import { ReactComponent as JobStarted } from '../../../../static/svg/JobStarted.svg';
import { translate, translateAndFormat } from '../../../../global/translation';

export const MAX_SPEED_BASELINE = 8;
export const NO_GRADIENT_ID = 'nogradient';
export const SPEEDING_GRADIENT_ID = 'speeding-gradient';
export const LOST_SIGNAL_GRADIENT_ID = 'lost-signal-gradient-id';

export const redGradientSpeed = 10;
export const yellowGradientSpeed = MAX_SPEED_BASELINE;

export const maxScrubberZoomLevel = 3;
export const minScrubberZoomLevel = 1;

export const oneSecondInPixelsForZoomLevel1 = 0.05;
export const oneSecondInPixelsForZoomLevel2 = 0.1;
export const oneSecondInPixelsForZoomLevel3 = 0.2;

export const markerSizeInPixels = 24;
export const speedChartMaxValue = 15;
export const speedChartMinValue = 0;

export const zoomLevelTimeDeltaMultiplierMap: Record<number, number> = {
  1: 4,
  2: 2,
  3: 1,
};

export const ScrubberZoomLevelResolutionMap: Record<number, number> = {
  1: oneSecondInPixelsForZoomLevel1,
  2: oneSecondInPixelsForZoomLevel2,
  3: oneSecondInPixelsForZoomLevel3,
};

export const ActivityTypeColor: Record<ActivityType, string> = {
  [ActivityType.Uncertain]: colors.gray[100],
  [ActivityType.Berthing]: colors.gray[100],
  [ActivityType.Shifting]: colors.gray[100],
  [ActivityType.Sailing]: colors.gray[100],
  [ActivityType.Demobilization]: colors.primary[100],
  [ActivityType.Reposition]: colors.primary[100],
  [ActivityType.Mobilization]: colors.primary[100],
  [ActivityType.Redeployment]: colors.primary[100],
  [ActivityType.Waiting]: colors.primary[100],
  [ActivityType.Resting]: colors.primary[100],
  [ActivityType.SignalLost]: colors.error[100],
  [ActivityType.Loading]: colors.gray[100],
};

export const HelmEventTypeMap: Record<HelmEventType, HelmEventContext> = {
  [HelmEventType.Berthing]: {
    icon: JobStarted,
    tooltipHeaderFactory: e => translateAndFormat('CREW_LOGGED_ACTIVITY', e.type.toString()),
  },
  [HelmEventType.Demobilisation]: {
    icon: DemobilisationStarted,
    tooltipHeaderFactory: e => translateAndFormat('CREW_LOGGED_ACTIVITY', e.type.toString()),
  },
  [HelmEventType.Escorting]: {
    icon: JobStarted,
    tooltipHeaderFactory: e => translateAndFormat('CREW_LOGGED_ACTIVITY', e.type.toString()),
  },
  [HelmEventType.Mobilization]: {
    icon: MobilisationStarted,
    tooltipHeaderFactory: e => translateAndFormat('CREW_LOGGED_ACTIVITY', e.type.toString()),
  },
  [HelmEventType.RequestedTime]: {
    icon: RequestedTime,
    tooltipHeaderFactory: () => translate('TRIP_REQUESTED_TIME'),
  },
  [HelmEventType.Sailing]: {
    icon: JobStarted,
    tooltipHeaderFactory: e => translateAndFormat('CREW_LOGGED_ACTIVITY', e.type.toString()),
  },
  [HelmEventType.Shifting]: {
    icon: JobStarted,
    tooltipHeaderFactory: e => translateAndFormat('CREW_LOGGED_ACTIVITY', e.type.toString()),
  },
  [HelmEventType.Waiting]: {
    icon: CrewLog,
    tooltipHeaderFactory: e => translateAndFormat('CREW_LOGGED_ACTIVITY', e.type.toString()),
  },
  [HelmEventType.Working]: {
    icon: JobStarted,
    tooltipHeaderFactory: e => translateAndFormat('CREW_LOGGED_ACTIVITY', e.type.toString()),
  },
};

export const speeds: Speed[] = [
  {
    displayName: 'Real-time',
    refreshInterval: 15,
    nextSpeed: 'Default',
    icon: SlowSpeedIcon,
    timeoutInMilliseconds: 14800,
  },
  {
    displayName: 'Default',
    refreshInterval: 15,
    nextSpeed: 'Faster',
    icon: DefaultSpeedIcon,
    timeoutInMilliseconds: 250,
  },
  {
    displayName: 'Faster',
    refreshInterval: 60,
    nextSpeed: 'Very Fast',
    icon: FasterSpeedIcon,
    timeoutInMilliseconds: 250,
  },
  {
    displayName: 'Very Fast',
    refreshInterval: 90,
    nextSpeed: 'Real-time',
    icon: VeryFastSpeedIcon,
    timeoutInMilliseconds: 200,
  },
];

export const svgDefs = [
  patternLinesDef(LOST_SIGNAL_GRADIENT_ID, {
    spacing: 12,
    rotation: 135,
    lineWidth: 6,
    background: colors.error[100],
    color: colors.error[300],
  }),
] as Def[];
