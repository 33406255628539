import { ActivityType, TripType } from './types';
import { translate } from './translation';

export const activityTypeDisplayStrings = {
  [ActivityType.Uncertain]: translate('UNCERTAIN_ACTIVITY'),
  [ActivityType.Reposition]: translate('REPOSITION_ACTIVITY'),
  [ActivityType.Sailing]: translate('SAILING_ACTIVITY'),
  [ActivityType.Shifting]: translate('SHIFTING_ACTIVITY'),
  [ActivityType.Berthing]: translate('BERTHING_ACTIVITY'),
  [ActivityType.Demobilization]: translate('DEMOBILIZATION_ACTIVITY'),
  [ActivityType.Mobilization]: translate('MOBILIZATION_ACTIVITY'),
  [ActivityType.Redeployment]: translate('REDEPLOYMENT_ACTIVITY'),
  [ActivityType.Resting]: translate('RESTING_ACTIVITY'),
  [ActivityType.Waiting]: translate('WAITING_ACTIVITY'),
  [ActivityType.SignalLost]: translate('SIGNAL_LOST'),
  [ActivityType.Loading]: translate('LOADING_ACTIVITY'),
};

export const tripTypeDisplayStrings = {
  [TripType.Uncertain]: translate('UNCERTAIN'),
  [TripType.Berthing]: translate('BERTHING'),
  [TripType.Repositioning]: translate('REPOSITIONING'),
  [TripType.Sailing]: translate('SAILING'),
  [TripType.Shifting]: translate('SHIFTING'),
  [TripType.Resting]: translate('RESTING'),
};

export const secretNames = {
  appInsightsInstrumentationKey: 'frontend-instrumentationKey',
  mapboxToken: 'frontend-mapboxToken',
};
