import { Timestamp } from './timestamp';

export class Timer {
  private timestampStart: Timestamp = undefined;

  private timestampEnd: Timestamp = undefined;

  start(): void {
    this.timestampStart = Timestamp.now();
  }

  stop(): void {
    this.timestampEnd = Timestamp.now();
  }

  reset(): void {
    this.timestampStart = undefined;
    this.timestampEnd = undefined;
  }

  restart(): void {
    this.timestampStart = Timestamp.now();
    this.timestampEnd = undefined;
  }

  get elapsed(): Timestamp {
    const startTime = !this.timestampStart ? Timestamp.now() : this.timestampStart;
    const endTime = !this.timestampEnd ? Timestamp.now() : this.timestampEnd;
    return endTime.subtract(startTime);
  }
}
