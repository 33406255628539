import { type FC, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { pastVesselTrackLayer, pastVesselTrackMarkerLayer } from '../layers-config';
import { getVesselTrackFeatures, getVesselTrackLayerWithGradient } from '../utils/layer-utils';
import { layerSources } from '../constants';
import { type PositionedActivity, type VesselTrackViewModel } from '../types';

interface Props {
  vesselTracks: VesselTrackViewModel[];
  activities: PositionedActivity[];
}

const PastVesselTracksLayer: FC<Props> = ({ vesselTracks, activities }) => {
  const [features, vesselTrackLayer] = useMemo(
    () => [
      getVesselTrackFeatures(vesselTracks),
      getVesselTrackLayerWithGradient(pastVesselTrackLayer, vesselTracks, activities),
    ],
    [vesselTracks]
  );

  return (
    <Source
      id={layerSources.pastVesselTrack}
      type="geojson"
      data={{
        type: 'FeatureCollection',
        features,
      }}
      lineMetrics
    >
      <>
        <Layer {...vesselTrackLayer} />
        <Layer {...pastVesselTrackMarkerLayer} />
      </>
    </Source>
  );
};

export default PastVesselTracksLayer;
