import { type FC, useMemo, useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';
import { first, some } from 'lodash';
import MapMode from './components/Map/components/MapMode';
import BlurMap from './components/mapFeedback/BlurMap';
import Scrubber from './components/Scrubber';
import TripsPanel from './components/TripsPanel';
import { type VesselPosition, type VesselViewModel, Mode } from './types';
import { useOperationData } from './hooks/useOperationData';
import { Timestamp } from './utils/timestamp';
import { usePollLiveData } from './hooks/usePollLiveData';
import { useFetchPlaybackData } from './hooks/useFetchPlaybackData';
import { usePrefetchPlaybackData } from './hooks/usePrefetchPlaybackData';
import useQueryParams from './hooks/useQueryParams';
import { approximateTimestamp } from './utils/approximateTimestamp';
import { useClearCachedData } from './hooks/useClearCachedData';
import { useTargetVesselTracks } from './hooks/useTargetVesselTracks';
import { LeftSideControls } from './components/LeftSideControls';
import { useTripsPanelStateManager } from './hooks/useTripsPanelStateManager';
import NoDataMessage from './components/NoDataMessage';
import { useTargetVessel } from './hooks/useTargetVessel';
import RateAppFlow from '../../global/components/RateAppFlow';
import { Event, Feature, Page, type Location, VesselTargetSource } from '../../global/types';
import { useDispatch, useMapContext, useTripsContext } from '../../global/state/GlobalProvider';
import { useAppInsights } from '../../hooks/useAppInsights';
import { getEventFactory } from '../../global/utils/get-event-factory';
import { useShallowNavigate } from '../../hooks/useShallowNavigate';
import areEqual from '../../global/utils/locationUtils';
import { useDetectMobileScreenWidth } from '../../hooks/useDetectMobileScreenWidth';
import { TripsPanelState, type Filters, type Job } from '../Trips/types';
import { useAppContext, useAppDispatch } from '../../global/components/AppContextProvider';
import { AppActionType } from '../../global/components/app-reducer';
import { defaultFilters } from '../Trips/utils/initial-trips-state';
import GlobalExceptionHandlerModal from '../../global/components/GlobalExceptionHandlerModal';
import { translate, type TranslationKey } from '../../global/translation';
import { ActionType } from '../../global/state/reducer';

const App: FC = () => {
  const { mmsi, timestamp } = useQueryParams();
  const { port } = useParams();
  const { mode } = useAppContext();
  const { filters, operations, tripsPanelState } = useTripsContext();
  const { settings } = useMapContext();
  const [scrubberTimestamp, setScrubberTimestamp] = useState(
    approximateTimestamp(Timestamp.fromSeconds(Number(timestamp)))
  );
  const [portParam, setPortParam] = useState<string>(port);
  const [startTimestamp, setStartTimestamp] = useState<Timestamp>(null);
  const [isLockedOnTarget, setIsLockedOnTarget] = useState(!!mmsi);
  const [targetVesselMmsi, setTargetVesselMmsi] = useState<string>(mmsi);
  const [initialMapPosition, setInitialMapPosition] = useState<Location>();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const shallowNavigate = useShallowNavigate();
  useClearCachedData(mode);
  const appInsights = useAppInsights();

  const operationKey = useMemo<string>(() => {
    if (operations?.length && portParam) {
      return (
        operations.find(
          o => o.key.toLowerCase() === portParam.toLowerCase() || o.name.toLowerCase() === portParam.toLowerCase()
        )?.key ?? portParam
      );
    }

    return portParam || filters.port;
  }, [portParam, operations, filters?.port]);

  const isWorldView = useMemo(() => {
    if (!operations?.length) {
      return !operationKey;
    }
    return !some(operations, o => o.key === operationKey);
  }, [operationKey, operations]);

  const { data: operation, isLoading: isOperationLoading } = useOperationData(operationKey);
  const [hoveredJob, setHoveredJob] = useState<Job>(null);
  const [isDraggingScrubber, setIsDraggingScrubber] = useState(false);
  const isMobileView = useDetectMobileScreenWidth();

  const getPageTranslationKey = (): TranslationKey => {
    if (isWorldView) {
      return 'WORLD_VIEW';
    }

    if (!operation) {
      return null;
    }

    if (mode === Mode.Playback) {
      return 'PLAYBACK';
    }
    return 'LIVE_VIEW';
  };

  useEffect(() => {
    const pageTranslationKey = getPageTranslationKey();
    if (!pageTranslationKey) {
      return;
    }

    appInsights.trackPageView({
      name: translate(pageTranslationKey),
      uri: globalThis.location.pathname,
      properties: {
        operation: operationKey,
        chartLayer: settings.layer,
        showVesselTracks: settings.showVesselTracks,
        showVesselNames: settings.showVesselNames,
        showTidalData: settings.showQuiverPlot,
      },
    });
  }, [mode, isWorldView, operation]);

  const handleSetTimestamp = (timestamp: Timestamp): void => {
    const approximatedTimestamp = approximateTimestamp(timestamp);
    setScrubberTimestamp(approximatedTimestamp);
  };

  const handleSetStartTimestamp = (timestamp: Timestamp): void => {
    const approximatedTimestamp = approximateTimestamp(timestamp);
    setStartTimestamp(approximatedTimestamp);
  };

  const isTripsPanelOpened = useMemo((): boolean => {
    if (isWorldView) {
      return false;
    }

    return tripsPanelState ? tripsPanelState === TripsPanelState.Open : !isMobileView;
  }, [tripsPanelState, isMobileView, isWorldView]);

  const switchToLiveview = (): void => {
    appDispatch({
      type: AppActionType.SET_MODE,
      payload: {
        mode: Mode.Liveview,
      },
    });
    setScrubberTimestamp(null);

    if (tripsPanelState === TripsPanelState.Reopen) {
      tripsPanelStateManager.open();
    }
  };

  const switchToPlayback = (): void => {
    appDispatch({
      type: AppActionType.SET_MODE,
      payload: {
        mode: Mode.Playback,
      },
    });

    if (isMobileView) {
      tripsPanelStateManager.closeToReopen();
    }
  };

  useEffect(() => {
    if (timestamp && !startTimestamp) {
      setStartTimestamp(approximateTimestamp(Timestamp.fromSeconds(Number(timestamp))));
    }

    if (timestamp && mode !== Mode.Playback) {
      switchToPlayback();
      return;
    }

    if (!timestamp && mode !== Mode.Liveview) {
      switchToLiveview();
    }
  }, [timestamp]);

  useEffect(() => {
    if (!!operationKey && filters.port !== operationKey) {
      dispatch({
        type: ActionType.UPDATE_FILTER,
        payload: {
          ...filters,
          port: operationKey,
        },
      });
    }
  }, [operationKey]);

  const page = useMemo(() => {
    if (mode === Mode.Playback) {
      return Page.Playback;
    }

    return Page.Liveview;
  }, [mode]);

  const tripsPanelStateManager = useTripsPanelStateManager(page, isMobileView, operation?.name ?? operationKey);

  const currentTimestamp = useMemo((): Timestamp => {
    if (mode === Mode.Liveview || !scrubberTimestamp) {
      return Timestamp.now();
    }

    return scrubberTimestamp;
  }, [scrubberTimestamp, mode]);

  const liveData = usePollLiveData(operationKey, mode);
  const queryResult = useFetchPlaybackData(currentTimestamp, operationKey, mode);
  usePrefetchPlaybackData(currentTimestamp, operationKey, queryResult, mode);
  const { data: playbackModel, isError, isLoading: isPlaybackDataLoading } = queryResult;

  const isLoading = useMemo(
    () => (mode === Mode.Liveview && !liveData) || isOperationLoading || !operations?.length,
    [liveData, isOperationLoading, mode, operations]
  );

  const vesselData = useMemo((): Record<string, VesselViewModel> => {
    return (mode === Mode.Liveview ? liveData?.vesselData : playbackModel?.vesselData) ?? {};
  }, [mode, playbackModel, liveData]);

  const targetVessel = useTargetVessel(targetVesselMmsi, vesselData);

  useLayoutEffect(() => {
    shallowNavigate({
      port: operationKey,
      mmsi: targetVesselMmsi,
      timestamp: scrubberTimestamp,
    });
  }, [scrubberTimestamp, operationKey, targetVesselMmsi]);

  const isMapVisible = useMemo(() => {
    return !!operations?.length && (!operationKey || !isLoading);
  }, [isLoading, operationKey, operations]);

  const isScrubberVisible = useMemo(() => {
    return mode === Mode.Playback && operation?.timezone && scrubberTimestamp;
  }, [mode, operation]);

  const dateString = useMemo(() => {
    if (operation?.timezone && currentTimestamp) {
      return currentTimestamp.toLocationDateTimeOffset(operation.timezone).dateString;
    }

    return '';
  }, [currentTimestamp, operation]);

  const updateInitialMapPosition = (position: VesselPosition, anyPosition: VesselPosition): void => {
    if (position && !areEqual(initialMapPosition, position.location)) {
      const { location } = position;
      setInitialMapPosition(location);
    } else if (anyPosition && !areEqual(initialMapPosition, anyPosition.location)) {
      const { location } = anyPosition;
      setInitialMapPosition(location);
    } else if (!areEqual(initialMapPosition, operation?.location)) {
      setInitialMapPosition(operation?.location);
    }
  };

  // Initialize the map using the coordinates of the loaded positions.
  const vesselPositions = useMemo((): VesselPosition[] => {
    if (mode === Mode.Liveview) {
      return liveData?.vesselPositions;
    }

    const playbackPositions = playbackModel?.vesselPositions;
    return playbackPositions ? playbackPositions[currentTimestamp.toSeconds()] : null;
  }, [mode, playbackModel?.vesselPositions, liveData, currentTimestamp]);

  useEffect(() => {
    if (vesselPositions?.length === 0 && !operation) {
      return;
    }

    const position = vesselPositions?.find(x => x.mmsi === targetVessel?.mmsi);
    const anyPosition = first(vesselPositions);
    updateInitialMapPosition(position, anyPosition);
  }, [vesselPositions, vesselData, operation, targetVessel?.mmsi]);

  const targetVesselTracks = useTargetVesselTracks(
    targetVessel,
    currentTimestamp,
    isDraggingScrubber,
    operationKey,
    liveData
  );

  const handleOperationSelect = useCallback(
    (value: string): void => {
      const { endDate, startDate, vesselName } = defaultFilters;
      const newFilter: Filters = {
        ...filters,
        endDate,
        startDate,
        vesselName,
        port: value,
      };
      setPortParam(value);
      dispatch({ type: ActionType.UPDATE_FILTER, payload: newFilter });
      setTargetVesselMmsi(null);
      appInsights.trackAnalyticsEvent(getEventFactory(Event.OperationSelected)(page, value));
    },
    [filters, targetVessel, scrubberTimestamp]
  );

  const handleMapInit = (isError: boolean, page: Page): JSX.Element => {
    if (isError) {
      return <GlobalExceptionHandlerModal errorMessage="An error occurred on map init" page={page} />;
    }
    return <BlurMap />;
  };

  useEffect(() => {
    const vessel = targetVessel ?? vesselData[targetVesselMmsi];
    if (!targetVesselMmsi || !vessel) {
      return;
    }

    const event = isLockedOnTarget
      ? getEventFactory(Event.VesselTargetted)(
          page,
          vessel.name,
          vessel.mmsi,
          vessel.type,
          window.location.href,
          currentTimestamp,
          VesselTargetSource.ClickOnRecenterButton
        )
      : getEventFactory(Event.VesselUntargeted)(
          page,
          vessel.name,
          vessel.mmsi,
          vessel.type,
          window.location.href,
          currentTimestamp
        );
    appInsights.trackAnalyticsEvent(event);
  }, [isLockedOnTarget, targetVesselMmsi]);

  const handleTargetVesselChange = useCallback(
    (mmsi: string = null) => {
      if (mmsi) {
        setIsLockedOnTarget(true);
      } else {
        setIsLockedOnTarget(false);
      }

      if (mmsi !== targetVesselMmsi && mmsi) {
        setTargetVesselMmsi(mmsi);
      }
    },
    [targetVesselMmsi, isLockedOnTarget]
  );

  return (
    <Flex flexDirection="row">
      <RateAppFlow parent={Feature.Map} page={page} />
      {!!operation && (
        <TripsPanel
          isOpen={isTripsPanelOpened}
          onClose={tripsPanelStateManager.close}
          vesselData={vesselData}
          setStartTimestamp={handleSetStartTimestamp}
          setTimestamp={handleSetTimestamp}
          page={page}
          onTargetVesselChange={handleTargetVesselChange}
          setHoveredJob={setHoveredJob}
          timezone={operation.timezone}
        />
      )}
      <Box className="pb-page-background">
        <LeftSideControls
          isLoading={isLoading}
          isTripsPanelOpened={isTripsPanelOpened}
          liveData={liveData}
          mode={mode}
          onOperationSelect={handleOperationSelect}
          operation={operation}
          operationKey={operationKey}
          isWorldView={isWorldView}
          toggleTripsPanel={tripsPanelStateManager.toggle}
        />
        <NoDataMessage isShown={!isWorldView && !(isLoading || isPlaybackDataLoading) && !vesselPositions?.length} />
        {isMapVisible ? (
          <MapMode
            vesselPositions={vesselPositions ?? []}
            vesselData={vesselData}
            isLockedOnTarget={isLockedOnTarget}
            setLockedOnTarget={setIsLockedOnTarget}
            mapCenter={initialMapPosition}
            timestamp={currentTimestamp}
            targetVesselMmsi={targetVessel?.mmsi}
            targetVessel={targetVessel}
            onTargetVesselChange={handleTargetVesselChange}
            operation={operation?.name ?? operationKey}
            dateString={dateString}
            targetVesselTracks={targetVesselTracks}
            toggleTripsPanel={tripsPanelStateManager.toggle}
            page={page}
            operations={operations}
            onOperationSelect={handleOperationSelect}
            setTimestamp={handleSetTimestamp}
            startTimestamp={startTimestamp}
            setStartTimestamp={handleSetStartTimestamp}
            isWorldView={isWorldView}
            isTripsPanelOpen={isTripsPanelOpened}
            pageMode={mode}
            hoveredJob={hoveredJob}
            timezone={operation?.timezone}
          />
        ) : (
          handleMapInit(isError, page)
        )}

        {isScrubberVisible && (
          <Scrubber
            timestamp={scrubberTimestamp}
            setTimestamp={handleSetTimestamp}
            vessel={targetVessel}
            vesselPositions={vesselPositions}
            isTripsPanelOpen={isTripsPanelOpened}
            isDraggingScrubber={isDraggingScrubber}
            setIsDraggingScrubber={setIsDraggingScrubber}
            timezone={operation.timezone}
            switchToLiveview={switchToLiveview}
            isLockedOnTarget={isLockedOnTarget}
            onTargetLock={handleTargetVesselChange}
          />
        )}
      </Box>
    </Flex>
  );
};
export default App;
