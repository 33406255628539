import { type FC, useRef } from 'react';
import { useOutsideClick } from '@chakra-ui/hooks';
import { Input, InputGroup, InputLeftElement, type InputProps } from '@chakra-ui/input';
import { Box, InputRightElement, List, ListItem, useBoolean } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { ReactComponent as SearchIcon } from '../../static/svg/Search.svg';
import type React from 'react';

export interface InputSearchProps extends Omit<InputProps, 'results'> {
  searchValue: string;
  options: string[];
  onResultSelect: (result: string) => void;
  clearSearchValue: () => void;
  onQueryChange: (query: string) => void;
  className: string;
  placeholder: string;
}

const InputSearch: FC<InputSearchProps> = ({
  searchValue,
  options,
  onResultSelect,
  clearSearchValue,
  onQueryChange,
  className,
  placeholder,
}) => {
  const dropDownRef = useRef<HTMLUListElement | null>(null);
  const [isDropDownOpen, setIsDropDownOpen] = useBoolean();

  useOutsideClick({
    ref: dropDownRef,
    handler: setIsDropDownOpen.off,
  });

  const handleResultSelect = (result: string): void => {
    onResultSelect(result);
    setIsDropDownOpen.off();
  };

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newQuery = event.target.value;
    onQueryChange(newQuery);
  };

  const handleListItemKeyDown = (event: React.KeyboardEvent<HTMLLIElement>, option: string, index: number): void => {
    if (event.key === 'Enter') {
      handleResultSelect(option);
    } else if (event.key === 'Tab' && index === options.length - 1) {
      setIsDropDownOpen.off();
    }
  };

  return (
    <Box className={className}>
      <InputGroup>
        <InputLeftElement className="search-icon">
          <SearchIcon color="gray.300" />
        </InputLeftElement>
        {searchValue && (
          <Box onClick={clearSearchValue}>
            <InputRightElement className="close-icon">
              <CloseIcon color="gray.900" />
            </InputRightElement>
          </Box>
        )}
        <Input
          value={searchValue}
          onChange={handleQueryChange}
          onClick={setIsDropDownOpen.toggle}
          aria-autocomplete="list"
          placeholder={placeholder}
        />
      </InputGroup>
      {!!options?.length && (
        <List ref={dropDownRef} visibility={isDropDownOpen ? 'visible' : 'hidden'}>
          {options.map((option: string, index) => (
            <ListItem
              key={option}
              tabIndex={0}
              onClick={() => {
                handleResultSelect(option);
              }}
              onKeyDown={e => {
                handleListItemKeyDown(e, option, index);
              }}
            >
              {option}
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default InputSearch;
