import { type LngLat } from 'mapbox-gl';
import { type EventFactory } from './event-factories-definitions';
import { type Timestamp } from '../../pages/Playback/utils/timestamp';
import { type Filters, JobTypes } from '../../pages/Trips/types';
import {
  Event,
  EventType,
  Feature,
  Page,
  FilteredVesselEvent,
  NPSSurveyEvent,
  type EventFilter,
  type ShareLinkOperation,
  type CopyLinkOperation,
  type Speed,
  type VesselTargetSource,
  type Location,
} from '../types';
import {
  type TidalArrowFeatureProperties,
  type TidalDiamondFeatureProperties,
  type VesselFeatureProperties,
} from '../../pages/Playback/components/Map/types';
import { InformationPanelAction } from '../../pages/Playback/types';

export const eventFactories: Record<Event, EventFactory> = {
  [Event.Exception]: (page: Page, locationUrl: string, errorMessage: string, error?: Error) => {
    return {
      name: Event.Exception,
      parent: page,
      page,
      eventType: EventType.UserAction,
      description: 'Exception',
      url: locationUrl,
      errorMessage,
      stack: error?.stack,
      message: error?.message,
    };
  },
  [Event.CopyLink]: (page: Page, copyLinkData: CopyLinkOperation, url: string) => {
    return {
      name: Event.CopyLink,
      parent: Feature.Map,
      page,
      eventType: EventType.UserAction,
      operation: copyLinkData,
      url,
    };
  },
  [Event.ShareLink]: (page: Page, shareLinkData: ShareLinkOperation) => {
    return {
      name: Event.ShareLink,
      parent: Feature.Map,
      page,
      eventType: EventType.UserAction,
      operation: shareLinkData,
    };
  },
  [Event.NPSSurvey]: (
    parent: Feature,
    page: Page,
    nPSSurveyEvent: NPSSurveyEvent,
    rating?: number,
    comment?: string
  ) => {
    return {
      name: Event.NPSSurvey,
      parent,
      page,
      eventType: EventType.AppEvent,
      description: 'NPS Survey',
      nPSSurveyEvent: NPSSurveyEvent[nPSSurveyEvent],
      rating,
      comment,
    };
  },
  [Event.FiltersReset]: (parent: Feature, page: Page, filters: Filters) => {
    return {
      name: Event.FiltersReset,
      parent,
      page,
      eventType: EventType.UserAction,
      description: 'Reset Button',
      preResetFilters: mapFilterToEventObject(filters),
    };
  },
  [Event.ZoomMap]: (page: Page, oldZoom: number, newZoom: number, url: string, mmsi: string, timestamp: Timestamp) => {
    return {
      name: Event.ZoomMap,
      parent: Feature.Map,
      page,
      eventType: EventType.UserAction,
      description: 'Map Zoom',
      oldZoom,
      newZoom,
      url,
      mmsi,
      timestamp: timestamp.toSeconds(),
    };
  },
  [Event.VesselTargetted]: (
    page: Page,
    vesselName: string,
    vesselMmsi: string,
    vesselType: string,
    url: string,
    timestamp: Timestamp,
    source: VesselTargetSource
  ) => {
    return {
      name: Event.VesselTargetted,
      parent: Feature.Map,
      page,
      eventType: EventType.UserAction,
      description: 'Target Vessel',
      vesselName,
      vesselMmsi,
      vesselType,
      url,
      timestamp: timestamp.toSeconds(),
      source,
    };
  },
  [Event.VesselUntargeted]: (
    page: Page,
    vesselName: string,
    vesselMmsi: string,
    vesselType: string,
    url: string,
    timestamp: Timestamp
  ) => {
    return {
      name: Event.VesselUntargeted,
      parent: Feature.Map,
      page,
      eventType: EventType.UserAction,
      description: 'Untarget Vessel',
      vesselName,
      vesselMmsi,
      vesselType,
      url,
      timestamp: timestamp.toSeconds(),
    };
  },
  [Event.PlaybackStarted]: (vesselMmsi: string, url: string, timestamp: Timestamp) => {
    return {
      name: Event.PlaybackStarted,
      parent: Feature.Timeline,
      page: Page.Playback,
      eventType: EventType.UserAction,
      description: 'Play Button',
      vesselMmsi,
      url,
      timestamp: timestamp.toSeconds(),
    };
  },
  [Event.PlaybackPaused]: (vesselMmsi: string, url: string, timestamp: Timestamp) => {
    return {
      name: Event.PlaybackPaused,
      parent: Feature.Timeline,
      page: Page.Playback,
      eventType: EventType.UserAction,
      description: 'Play Button',
      vesselMmsi,
      url,
      timestamp: timestamp.toSeconds(),
    };
  },
  [Event.Entered3dView]: (page: Page, url: string, mmsi: string, timestamp: Timestamp) => {
    return {
      name: Event.Entered3dView,
      parent: Feature.Map,
      page,
      eventType: EventType.UserAction,
      description: '3D View',
      url,
      mmsi,
      timestamp: timestamp.toSeconds(),
    };
  },
  [Event.Exited3dView]: (page: Page, url: string, mmsi: string, timestamp: Timestamp) => {
    return {
      name: Event.Exited3dView,
      parent: Feature.Map,
      page,
      eventType: EventType.UserAction,
      description: '3D View',
      url,
      mmsi,
      timestamp: timestamp.toSeconds(),
    };
  },
  [Event.PanMap]: (
    page: Page,
    url: string,
    mmsi: string,
    timestamp: Timestamp,
    oldCenter: LngLat,
    newCenter: LngLat
  ) => {
    return {
      name: Event.PanMap,
      parent: Feature.Map,
      page,
      eventType: EventType.UserAction,
      description: 'Map Panning',
      url,
      mmsi,
      timestamp: timestamp.toSeconds(),
      oldCenter,
      newCenter,
    };
  },
  [Event.VesselHovered]: (
    page: Page,
    vesselProperties: VesselFeatureProperties,
    duration: Timestamp,
    url: string,
    timestamp: Timestamp
  ) => {
    return {
      name: Event.VesselHovered,
      parent: Feature.Map,
      page,
      eventType: EventType.UserAction,
      description: 'Vessel Tooltip',
      url,
      duration: duration.toSeconds(),
      mmsi: vesselProperties.mmsi,
      vesselName: vesselProperties.name,
      type: vesselProperties.type,
      timestamp: timestamp.toSeconds(),
    };
  },
  [Event.FilteredVessel]: (
    parent: Feature,
    page: Page,
    previousFilters: Filters,
    filters: Filters,
    filteredVesselEvent: FilteredVesselEvent
  ) => {
    return {
      name: Event.FilteredVessel,
      parent,
      page,
      eventType: EventType.UserAction,
      description: 'Vessel Search Input',
      previousFilters: mapFilterToEventObject(previousFilters),
      filters: mapFilterToEventObject(filters),
      filteredVesselEvent: FilteredVesselEvent[filteredVesselEvent],
    };
  },
  [Event.NoTripsFound]: (parent: Feature, page: Page, previousFilters: Filters, filters: Filters) => {
    return {
      name: Event.NoTripsFound,
      parent,
      page,
      eventType: EventType.AppEvent,
      description: 'No Trips Found message',
      previousFilters: mapFilterToEventObject(previousFilters),
      filters: mapFilterToEventObject(filters),
    };
  },
  [Event.FilteredTripType]: (parent: Feature, page: Page, previousFilters: Filters, filters: Filters) => {
    return {
      name: Event.FilteredTripType,
      parent,
      page,
      eventType: EventType.UserAction,
      description: 'Trip Type Filter',
      previousFilters: mapFilterToEventObject(previousFilters),
      filters: mapFilterToEventObject(filters),
    };
  },
  [Event.FilteredDate]: (
    parent: Feature,
    page: Page,
    previousFilters: Filters,
    filters: Filters,
    filteredDateEvent: string
  ) => {
    return {
      name: Event.FilteredDate,
      parent,
      page,
      eventType: EventType.UserAction,
      description: 'Date Filter',
      previousFilters: mapFilterToEventObject(previousFilters),
      filters: mapFilterToEventObject(filters),
      predefinedRange: filteredDateEvent,
    };
  },
  [Event.FilteredCompetitors]: (parent: Feature, page: Page, previousFilters: Filters, filters: Filters) => {
    return {
      name: Event.FilteredCompetitors,
      parent,
      page,
      eventType: EventType.UserAction,
      description: 'Competitor Filter',
      previousFilters: mapFilterToEventObject(previousFilters),
      filters: mapFilterToEventObject(filters),
    };
  },
  [Event.ReplayClosed]: (url: string, vesselMmsi: string, vesselName: string, timestamp: Timestamp) => {
    return {
      name: Event.ReplayClosed,
      parent: Feature.Map,
      page: Page.Playback,
      eventType: EventType.UserAction,
      description: 'Live View Button',
      url,
      vesselMmsi,
      vesselName,
      timestamp: timestamp.toSeconds(),
    };
  },
  [Event.TimelineScrubbed]: (start: Timestamp, end: Timestamp, url: string, vesselMmsi: string, vesselName: string) => {
    return {
      name: Event.TimelineScrubbed,
      parent: Feature.Timeline,
      page: Page.Playback,
      eventType: EventType.UserAction,
      description: 'Timeline Scrub',
      start: start.toSeconds(),
      end: end.toSeconds(),
      url,
      mmsi: vesselMmsi,
      vesselName,
    };
  },
  [Event.TimelineZoomed]: (
    startZoomLevel: number,
    endZoomLevel: number,
    url: string,
    vesselMmsi: string,
    vesselName: string,
    timestamp: Timestamp
  ) => {
    return {
      name: Event.TimelineZoomed,
      parent: Feature.Timeline,
      page: Page.Playback,
      eventType: EventType.UserAction,
      description: 'Timeline Zoom',
      startZoomLevel,
      endZoomLevel,
      url,
      mmsi: vesselMmsi,
      vesselName,
      timestamp: timestamp.toSeconds(),
    };
  },
  [Event.WrongInformation]: (
    page: Page,
    mmsi: string,
    shipName: string,
    dateString: string,
    selectedButtons: string[],
    textArea: string,
    locationUrl: string,
    operation: InformationPanelAction
  ) => {
    return {
      name: Event.WrongInformation,
      parent: Feature.Map,
      page,
      eventType: EventType.UserAction,
      description: 'See Wrong Information',
      mmsi,
      shipName,
      date: dateString,
      problemCategory: selectedButtons.join(','),
      problemDescription: textArea,
      url: locationUrl,
      operation: InformationPanelAction[operation],
    };
  },

  [Event.TimelineSpeedChanged]: (
    timestamp: Timestamp,
    url: string,
    vesselMmsi: string,
    vesselName: string,
    speed: string
  ) => {
    return {
      name: Event.TimelineScrubbed,
      parent: Feature.Timeline,
      page: Page.Playback,
      eventType: EventType.UserAction,
      description: 'Timeline Speed Changed',
      timestamp: timestamp.toSeconds(),
      url,
      mmsi: vesselMmsi,
      vesselName,
      speed,
    };
  },
  [Event.TimelineMarkerHovered]: (timestamp: Timestamp, url: string, vesselMmsi: string, vesselName: string) => {
    return {
      name: Event.TimelineMarkerHovered,
      parent: Feature.Timeline,
      page: Page.Playback,
      eventType: EventType.UserAction,
      description: 'Timeline Marker Hovered',
      url,
      mmsi: vesselMmsi,
      vesselName,
      timestamp: timestamp.toSeconds(),
    };
  },
  [Event.VesselSearchOpened]: (page: Page, operation: string, timestamp: Timestamp) => {
    return {
      name: Event.VesselSearchOpened,
      parent: Feature.Map,
      page,
      operation,
      eventType: EventType.UserAction,
      description: 'Vessel Search Opened',
      timestamp: timestamp.toSeconds(),
    };
  },
  [Event.VesselSearchClosed]: (page: Page, operation: string, timestamp: Timestamp) => {
    return {
      name: Event.VesselSearchClosed,
      parent: Feature.Map,
      page,
      operation,
      eventType: EventType.UserAction,
      description: 'Vessel Search Closed',
      timestamp: timestamp.toSeconds(),
    };
  },
  [Event.VesselSearchGotResults]: (
    page: Page,
    operation: string,
    timestamp: Timestamp,
    searchPhrase: string,
    resultsQuantity: number
  ) => {
    return {
      name: Event.VesselSearchGotResults,
      parent: Feature.Map,
      page,
      operation,
      eventType: EventType.UserAction,
      description: 'Vessel Search Closed',
      timestamp: timestamp.toSeconds(),
      searchPhrase,
      resultsQuantity,
    };
  },
  [Event.VesselSearchGotNoResults]: (
    page: Page,
    operation: string,
    timestamp: Timestamp,
    searchPhrase: string,
    resultsQuantity: number
  ) => {
    return {
      name: Event.VesselSearchGotNoResults,
      parent: Feature.Map,
      page,
      operation,
      eventType: EventType.UserAction,
      description: 'Vessel Search Closed',
      timestamp: timestamp.toSeconds(),
      searchPhrase,
      resultsQuantity,
    };
  },
  [Event.VesselFoundAndSelected]: (
    page: Page,
    operation: string,
    timestamp: Timestamp,
    searchPhrase: string,
    vesselMmsi: string,
    vesselName: string
  ) => {
    return {
      name: Event.VesselFoundAndSelected,
      parent: Feature.Map,
      page,
      operation,
      eventType: EventType.UserAction,
      description: 'Vessel Search Closed',
      timestamp: timestamp.toSeconds(),
      searchPhrase,
      vesselMmsi,
      vesselName,
    };
  },
  [Event.VesselTracksEnabled]: (page: Page, operation: string) => {
    return {
      name: Event.VesselTracksEnabled,
      parent: Feature.Map,
      page,
      operation,
      eventType: EventType.UserAction,
      description: 'Vessel Tracks Turned On',
    };
  },
  [Event.VesselTracksDisabled]: (page: Page, operation: string) => {
    return {
      name: Event.VesselTracksDisabled,
      parent: Feature.Map,
      page,
      operation,
      eventType: EventType.UserAction,
      description: 'Vessel Tracks Turned Off',
    };
  },
  [Event.VesselTrackHovered]: (
    page: Page,
    operation: string,
    timestamp: Timestamp,
    speed: Speed,
    vesselMmsi: string
  ) => {
    return {
      name: Event.VesselTrackHovered,
      parent: Feature.Map,
      page,
      operation,
      eventType: EventType.UserAction,
      description: 'Vessel Track Hovered',
      timestamp: timestamp.toSeconds(),
      speed: speed?.value ?? 0,
      vesselMmsi,
    };
  },
  [Event.ActivityMarkerHovered]: (
    page: Page,
    operation: string,
    timestamp: Timestamp,
    speed: Speed,
    vesselMmsi: string
  ) => {
    return {
      name: Event.ActivityMarkerHovered,
      parent: Feature.Map,
      page,
      operation,
      eventType: EventType.UserAction,
      description: 'Activity Marker Hovered',
      timestamp: timestamp.toSeconds(),
      speed: speed?.value ?? 0,
      vesselMmsi,
    };
  },
  [Event.EnableVesselNames]: (page: Page, operation: string) => {
    return {
      name: Event.EnableVesselNames,
      parent: Feature.Map,
      page,
      operation,
      eventType: EventType.UserAction,
      description: 'Vessel Names Enabled',
    };
  },
  [Event.DisableVesselNames]: (page: Page, operation: string) => {
    return {
      name: Event.DisableVesselNames,
      parent: Feature.Map,
      page,
      operation,
      eventType: EventType.UserAction,
      description: 'Vessel Names Disabled',
    };
  },
  [Event.QuickAccessPanelClosed]: (page: Page, operation: string, timestamp: Timestamp) => {
    return {
      name: Event.QuickAccessPanelClosed,
      parent: Feature.QuickAccessPanel,
      page,
      operation,
      eventType: EventType.UserAction,
      description: 'Quick Access Panel Closed',
      timestamp: timestamp.toSeconds(),
    };
  },
  [Event.QuickAccessPanelOpened]: (page: Page, operation: string, timestamp: Timestamp) => {
    return {
      name: Event.QuickAccessPanelOpened,
      parent: Feature.QuickAccessPanel,
      page,
      operation,
      eventType: EventType.UserAction,
      description: 'Quick Access Panel Opened',
      timestamp: timestamp.toSeconds(),
    };
  },
  [Event.PickTimeOpened]: (page: Page, operation: string, timestamp: Timestamp) => {
    return {
      name: Event.PickTimeOpened,
      parent: Feature.QuickAccessPanel,
      page,
      operation,
      eventType: EventType.UserAction,
      description: 'Pick Time Opened',
      timestamp: timestamp.toSeconds(),
    };
  },
  [Event.BackFromPickTimeClicked]: (page: Page, operation: string, timestamp: Timestamp) => {
    return {
      name: Event.BackFromPickTimeClicked,
      parent: Feature.QuickAccessPanel,
      page,
      operation,
      eventType: EventType.UserAction,
      description: 'Back From Pick Time Clicked',
      timestamp: timestamp.toSeconds(),
    };
  },
  [Event.BackFromVesselSearchClicked]: (page: Page, operation: string, timestamp: Timestamp) => {
    return {
      name: Event.BackFromVesselSearchClicked,
      parent: Feature.QuickAccessPanel,
      page,
      operation,
      eventType: EventType.UserAction,
      description: 'Back From Vessel Search Clicked',
      timestamp: timestamp.toSeconds(),
    };
  },
  [Event.RedirectedToPlaybackFromPickTime]: (
    page: Page,
    operation: string,
    vesselMmsi: string,
    timestamp: Timestamp
  ) => {
    return {
      name: Event.RedirectedToPlaybackFromPickTime,
      parent: Feature.QuickAccessPanel,
      page,
      operation,
      eventType: EventType.UserAction,
      description: 'Redirected To Playback From Pick Time',
      timestamp: timestamp.toSeconds(),
      vesselMmsi,
    };
  },
  [Event.OperationSelected]: (page: Page, operation: string) => {
    return { name: Event.OperationSelected, parent: Feature.Map, page, operation };
  },
  [Event.TripsHistoryPanelClosed]: (page: Page, operation: string) => {
    return { name: Event.TripsHistoryPanelClosed, parent: Feature.Map, page, operation };
  },
  [Event.TripsHistoryPanelOpened]: (page: Page, operation: string) => {
    return { name: Event.TripsHistoryPanelOpened, parent: Feature.Map, page, operation };
  },
  [Event.TripsPanelTripHovered]: (page: Page, operation: string, tripId: string, jobId: string) => {
    return {
      name: Event.TripsPanelTripHovered,
      parent: Feature.TripsHistoryPanel,
      page,
      operation,
      eventType: EventType.UserAction,
      description: 'Hovered on Job on Trips History Panel',
      tripId,
      jobId,
    };
  },
  [Event.TripsPanelTripClicked]: (page: Page, operation: string, tripId: string, jobId: string, mmsi: string) => {
    return {
      name: Event.TripsPanelTripClicked,
      parent: Feature.TripsHistoryPanel,
      page,
      operation,
      eventType: EventType.UserAction,
      description: 'Clicked on Trip or Job on Trips History Panel',
      tripId,
      jobId,
      mmsi,
    };
  },
  [Event.TripsPanelScrolledToEnd]: (page: Page, operation: string) => {
    return {
      name: Event.TripsPanelScrolledToEnd,
      parent: Feature.TripsHistoryPanel,
      page,
      operation,
      eventType: EventType.UserAction,
      description: 'Scrolled to end of list on Trips History Panel',
    };
  },
  [Event.EnableQuiverPlot]: (page: Page, operation: string) => {
    return {
      name: Event.EnableQuiverPlot,
      parent: Feature.Map,
      page,
      operation,
      eventType: EventType.UserAction,
      description: 'Quiver Plot Enabled',
    };
  },
  [Event.DisableQuiverPlot]: (page: Page, operation: string) => {
    return {
      name: Event.DisableQuiverPlot,
      parent: Feature.Map,
      page,
      operation,
      eventType: EventType.UserAction,
      description: 'Quiver Plot Disabled',
    };
  },
  [Event.EnableNauticalMap]: (page: Page, operation: string) => {
    return {
      name: Event.EnableNauticalMap,
      parent: Feature.Map,
      page,
      operation,
      eventType: EventType.UserAction,
      description: 'Nautical Map Enabled',
    };
  },
  [Event.DisableNauticalMap]: (page: Page, operation: string) => {
    return {
      name: Event.DisableNauticalMap,
      parent: Feature.Map,
      page,
      operation,
      eventType: EventType.UserAction,
      description: 'Nautical Map Disabled',
    };
  },
  [Event.TidalDiamondHovered]: (
    page: Page,
    diamond: TidalDiamondFeatureProperties,
    duration: Timestamp,
    url: string,
    timestamp: Timestamp
  ) => {
    const location: Location = JSON.parse(diamond.location.toString());
    const {
      coordinates: { lat, lon },
    } = location;
    return {
      name: Event.TidalDiamondHovered,
      parent: Feature.Map,
      page,
      eventType: EventType.UserAction,
      description: 'Tidal Diamond Tooltip',
      url,
      duration: duration.toSeconds(),
      latitude: lat,
      longitude: lon,
      speed: diamond.rate,
      direction: diamond.set,
      hoursAfterHighWater: diamond.hoursAfterHighWater,
      timestamp: timestamp.toSeconds(),
    };
  },
  [Event.TidalArrowClicked]: (page: Page, arrow: TidalArrowFeatureProperties, url: string, timestamp: Timestamp) => {
    const location: Location = JSON.parse(arrow.location.toString());
    const {
      coordinates: { lat, lon },
    } = location;
    return {
      name: Event.TidalArrowClicked,
      parent: Feature.Map,
      page,
      eventType: EventType.UserAction,
      description: 'Tidal Arrow Tooltip',
      url,
      latitude: lat,
      longitude: lon,
      speed: arrow.rate,
      direction: arrow.set,
      timestamp: timestamp.toSeconds(),
    };
  },
};

const mapFilterToEventObject = (filters: Filters): EventFilter => {
  return {
    startDate: filters?.startDate ? new Date(filters.startDate).toDateString() : '',
    endDate: filters?.endDate ? new Date(filters.endDate).toDateString() : '',
    vesselName: filters?.vesselName,
    port: filters.port,
    tripType: filters?.tripType,
    jobType: JobTypes[filters.jobType],
  };
};
