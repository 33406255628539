import { type FunctionComponent } from 'react';
import { animated } from '@react-spring/web';

import { type AxisTickProps } from '@nivo/axes/dist/types/types';
import moment from 'moment-timezone';
import { Timestamp } from '../../utils/timestamp';

interface Props {
  tickProps: AxisTickProps<Date>;
  timezone: string;
}

const SpeedChartAxisBottomTick: FunctionComponent<Props> = ({ tickProps, timezone }) => {
  const { animatedProps, textBaseline, textAnchor, value } = tickProps;
  const dateTimeOffset = Timestamp.fromMilliseconds(moment.utc(value).valueOf()).toLocationDateTimeOffset(timezone);
  return (
    <animated.g transform={animatedProps.transform} opacity={animatedProps.opacity}>
      <animated.text
        className="text-xs-regular bottom-axis-label"
        dominantBaseline={textBaseline}
        textAnchor={textAnchor}
        transform={animatedProps.textTransform}
      >
        {dateTimeOffset.format('HH:mm')}
      </animated.text>
    </animated.g>
  );
};

export default SpeedChartAxisBottomTick;
