import { Button } from '@chakra-ui/react';
import { type FC } from 'react';

interface RateBtnProps {
  rate: number;
  onRateClick: (rate: number) => void;
}

const RateBtn: FC<RateBtnProps> = ({ rate, onRateClick }) => {
  return (
    <Button
      onClick={() => {
        onRateClick(rate);
      }}
      className="default-btn"
    >
      {rate}
    </Button>
  );
};

export default RateBtn;
