import { type Configuration, type IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { getConfig } from './getConfig';
import { CONFIG_CODES } from '../types';

// TODO: We probably shouldn't hardcode these scopes, since the app ID will be different in different environments
// eslint-disable-next-line spellcheck/spell-checker
export const scopes: string[] = ['api://0cfa4bcc-9420-4d66-a767-58523179bb9d/User.Read'];

export const authConfig: Configuration = {
  auth: {
    clientId: getConfig(CONFIG_CODES.REACT_APP_AUTH_CLIENT_ID),
    redirectUri: getConfig(CONFIG_CODES.REACT_APP_AUTH_REDIRECT_URI),
    authority: getConfig(CONFIG_CODES.REACT_APP_AUTH_AUTHORITY),
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
};

let authClient: IPublicClientApplication = null;

export function getAuthClient(): IPublicClientApplication {
  if (!authClient) {
    authClient = new PublicClientApplication(authConfig);
  }

  return authClient;
}
