import { Timestamp } from '../../../utils/timestamp';
import { type TimestampRange } from '../../../../../global/types';

export const getTimelineRange = (timestamp: Timestamp, secondInPixels: number): TimestampRange => {
  const timelineSize = Timestamp.fromSeconds(window.innerWidth / secondInPixels);

  return {
    from: timestamp.subtract(timelineSize),
    to: timestamp.add(timelineSize),
  };
};
