import { useMemo, type FC, type MouseEvent, useRef, type JSX } from 'react';
import { Card, CardBody, Grid, GridItem, Text, Progress, Stack, Tooltip } from '@chakra-ui/react';
import cx from 'classnames';
import JobDates from './JobDates';
import JobCardVesselImage from './JobCardVesselImage';
import { Timestamp } from '../../../utils/timestamp';
import { ReactComponent as Activity } from '../../../../../static/svg/Activity.svg';
import { ReactComponent as EcoRatingGreen } from '../../../../../static/svg/EcoRatingGreen.svg';
import { ReactComponent as EcoRatingGrey } from '../../../../../static/svg/EcoRatingGrey.svg';
import { ReactComponent as EcoRatingRed } from '../../../../../static/svg/EcoRatingRed.svg';
import { ReactComponent as EcoRatingYellow } from '../../../../../static/svg/EcoRatingYellow.svg';
import { ReactComponent as AlertCircle } from '../../../../../static/svg/AlertCircle.svg';
import { EcoZone, type Job, type Trip } from '../../../../Trips/types';
import { mapEcoScoreToEcoZone } from '../../../../Trips/utils/mapEcoScoreToEcoZone';
import { translate } from '../../../../../global/translation';
import { Event, type MmsiKey, type Page } from '../../../../../global/types';
import { Timer } from '../../../utils/timer';
import { getEventFactory } from '../../../../../global/utils/get-event-factory';
import { useAppInsights } from '../../../../../hooks/useAppInsights';
import { navigateOrOpenInNewTab } from '../../../../../global/utils/navigation-utils';
import { type VesselViewModel } from '../../../types';

interface Props {
  trip: Trip;
  job: Job;
  vesselData: Record<MmsiKey, VesselViewModel>;
  operationKey: string;
  onSelect: () => void;
  onHover: (tripId: string, jobId: string) => void;
  onHoverOut: () => void;
  setStartTimestamp: (timestamp: Timestamp) => void;
  setTimestamp: (timestamp: Timestamp) => void;
  onTargetVesselChange: (mmsi: string) => void;
  page: Page;
  vesselNameSearchPhrase: string;
  timezone: string;
}

const JobCard: FC<Props> = ({
  trip,
  job,
  vesselData,
  operationKey,
  onSelect,
  onHover,
  onHoverOut,
  setStartTimestamp,
  setTimestamp,
  onTargetVesselChange,
  page,
  vesselNameSearchPhrase,
  timezone,
}) => {
  const ecoZone = useMemo(() => mapEcoScoreToEcoZone(job.fuelEfficiency), [job.fuelEfficiency]);
  const appInsights = useAppInsights();
  const timer = useRef(new Timer());

  const ecoRatingIcon = useMemo((): JSX.Element => {
    switch (ecoZone) {
      case EcoZone.bad:
        return <EcoRatingRed className="eco-rating-icon" />;
      case EcoZone.neutral:
        return <EcoRatingYellow className="eco-rating-icon" />;
      case EcoZone.good:
        return <EcoRatingGreen className="eco-rating-icon" />;
      default:
        return <EcoRatingGrey className="eco-rating-icon" />;
    }
  }, [ecoZone]);

  const ecoRatingTextClasses = cx('eco-rating-text', {
    good: ecoZone === EcoZone.good,
    neutral: ecoZone === EcoZone.neutral,
    bad: ecoZone === EcoZone.bad,
  });

  const progressBarClasses = cx({
    'progress-bar-good': ecoZone === EcoZone.good,
    'progress-bar-neutral': ecoZone === EcoZone.neutral,
    'progress-bar-bad': ecoZone === EcoZone.bad,
  });

  const handleJobClick = (e: MouseEvent): void => {
    onSelect();
    const { vesselMmsi, timestampStart } = job;
    const event = getEventFactory(Event.TripsPanelTripClicked)(
      page,
      trip.port,
      trip.tripId,
      job.jobId,
      vesselMmsi.toString()
    );
    appInsights.trackAnalyticsEvent(event);
    const timestamp = Timestamp.fromSeconds(timestampStart);
    navigateOrOpenInNewTab(
      {
        port: operationKey,
        mmsi: vesselMmsi.toString(),
        timestamp,
      },
      e,
      params => {
        const { timestamp, mmsi } = params;
        setStartTimestamp(timestamp);
        setTimestamp(timestamp);
        onTargetVesselChange(mmsi);
      }
    );
  };

  const canSendEvent = (): boolean => {
    return timer.current.elapsed.toSeconds() >= 2;
  };

  const handleHoverStart = (): void => {
    onHover(trip.tripId, job.jobId);
    timer.current.reset();
    timer.current.start();
  };

  const handleHoverEnd = (): void => {
    onHoverOut();
    timer.current.stop();

    if (canSendEvent()) {
      const event = getEventFactory(Event.TripsPanelTripHovered)(
        page,
        trip.port,
        trip.tripId,
        job.jobId,
        job.vesselMmsi.toString()
      );
      appInsights.trackAnalyticsEvent(event);
    }
    timer.current.reset();
  };

  const getVesselName = (vesselName: string): JSX.Element => {
    if (!vesselName) {
      return <span>{translate('UNKNOWN')}</span>;
    }

    return <span>{vesselName}</span>;
  };

  const getCardBodyClassName = (
    vesselNameSearchPhrase: string,
    vesselName: string,
    assistedVesselName: string
  ): string => {
    return cx('job-card', {
      'dim-search-result':
        assistedVesselName !== vesselNameSearchPhrase &&
        vesselNameSearchPhrase !== null &&
        vesselNameSearchPhrase !== '' &&
        vesselNameSearchPhrase !== vesselName,
    });
  };

  return (
    <Card
      className="job-container"
      onClick={handleJobClick}
      onMouseEnter={handleHoverStart}
      onMouseLeave={handleHoverEnd}
    >
      <CardBody className={getCardBodyClassName(vesselNameSearchPhrase, job.vesselName, trip.assistedVesselName)}>
        <Grid templateRows="repeat(3, 1fr)" templateColumns="repeat(5, 1fr)">
          <GridItem className="job-card-image" rowSpan={3} colSpan={1}>
            <JobCardVesselImage job={job} vesselData={vesselData} />
          </GridItem>
          <GridItem className="job-card-item first-line" colSpan={2}>
            <JobDates
              tripStart={Timestamp.fromSeconds(job.timestampStart)}
              tripEnd={Timestamp.fromSeconds(job.timestampEnd)}
              timezone={timezone}
            />
          </GridItem>
          <GridItem className="job-card-item first-line full-width" colSpan={2}>
            <div className="eco-rating-container">
              {ecoRatingIcon}
              {!!ecoZone && <Text className={ecoRatingTextClasses}>{job.fuelEfficiency?.toFixed(0)}</Text>}
              {!ecoZone && (
                <Tooltip
                  htmlTranslate="yes"
                  borderRadius="8px"
                  padding="12px"
                  label={
                    <Stack>
                      <Text>{translate('ECO_EFFICIENCY_UNAVAILABLE_HEADING')}</Text>
                      <Text>{translate('ECO_EFFICIENCY_UNAVAILABLE_JOB_TEXT')}</Text>
                    </Stack>
                  }
                >
                  <AlertCircle className="job-icon" />
                </Tooltip>
              )}
            </div>
          </GridItem>
          <GridItem className="job-card-item" colSpan={2}>
            <Text className="job-card-text">{getVesselName(job.vesselName)}</Text>
          </GridItem>
          <GridItem className="job-card-item trip-type" colSpan={2}>
            <Activity className="job-icon" />
            <Text className="job-card-text">{trip.type}</Text>
          </GridItem>
          <GridItem colStart={2} colSpan={4}>
            <Progress className={progressBarClasses} size="sm" value={job.fuelEfficiency} />
          </GridItem>
        </Grid>
      </CardBody>
    </Card>
  );
};

export default JobCard;
