import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { indicatePageTypeByUrl } from '../global/utils/navigation-utils';
import { Page } from '../global/types';
import { useAppDispatch } from '../global/components/AppContextProvider';
import { trackEvent } from '../global/components/utils/rate-app-utils';
import { type AppAction } from '../global/components/app-reducer';
import type React from 'react';

const routeActions: Record<Page, (appDispatch: React.Dispatch<AppAction>) => void> = {
  [Page.Playback]: trackEvent,
  [Page.Liveview]: trackEvent,
  [Page.Unknown]: trackEvent,
};

export const useRouteTracking = (): void => {
  const location = useLocation();
  const pathRef = useRef<string>('');
  const appDispatch = useAppDispatch();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (location.pathname === pathRef.current || !isAuthenticated) {
      return;
    }
    pathRef.current = location.pathname;
    const page = indicatePageTypeByUrl(location.pathname);

    routeActions[page](appDispatch);
  }, [location, isAuthenticated]);
};
