import { EcoZone } from '../types';

export function mapEcoScoreToEcoZone(ecoScore: number): EcoZone {
  if (!ecoScore) {
    return null;
  }

  if (ecoScore <= 69) {
    return EcoZone.bad;
  }

  if (ecoScore >= 70 && ecoScore <= 89) {
    return EcoZone.neutral;
  }

  return EcoZone.good;
}
