/* eslint-disable @typescript-eslint/no-explicit-any */
import { type OperationViewModel, type VesselTrackViewModel } from '../Playback/components/Map/types';

export enum JobTypes {
  all,
  svitzerTrip,
  competitorTrip,
}

export enum EcoZone {
  good = 'good',
  neutral = 'neutral',
  bad = 'bad',
}

export enum Source {
  PortMonitor = 'PortMonitor',
  Helm = 'Helm',
  Matched = 'Matched',
}

export enum TripsPanelState {
  Open = 'Open',
  Closed = 'Closed',
  Reopen = 'Reopen',
}

export interface JobActivity {
  activityId: string;
  activity: string;
  timestampStart: number;
  timestampEnd: number;
  fuelEfficiency: number;
  speedEfficiency: number;
}

export interface Job {
  jobId: string;
  timestampStart: number;
  timestampEnd: number;
  speedEfficiency: number;
  fuelEfficiency: number;
  totalFuelConsumption: number;
  totalFuelConsumptionExtrapolated: number;
  vesselMmsi: number;
  vesselName: string;
  activities: JobActivity[];
  source: Source;
  tracks: VesselTrackViewModel[];
}

export interface Trip {
  id: string;
  port: string;
  tripId: string;
  timestampStart: number;
  timestampEnd: number;
  fuelEfficiency: number;
  speedEfficiency: number;
  totalFuelConsumption: number;
  totalFuelConsumptionExtrapolated: number;
  type: string;
  assistedVesselMmsi: number;
  assistedVesselName: string;
  jobs: Job[];
  source: Source;
  customerName: string;
}

export interface TripsPanelViewModel {
  trips: Trip[];
  paging: Paging;
}

export interface TripGroup {
  date: string;
  trips: Trip[];
}

export interface Paging {
  total: number;
  page: number;
  size: number;
  hasNextPage: boolean;
}

export interface Filters {
  port: string | null;
  startDate: string | null;
  endDate: string | null;
  vesselName: string | null;
  tripType: string[] | null;
  jobType: JobTypes | null;
}

export interface Pagination {
  currentPage: number;
  numOfPages: number;
  limit: number;
}

export interface TripsContext {
  filters: Filters;
  previousFilters: Filters;
  pagination: Pagination;
  tripsData: TripsData;
  operations: OperationViewModel[];
  vesselNames: string[];
  tripsPanelState?: TripsPanelState;
}

export interface TripsData {
  trips: Trip[];
  isLoading: boolean;
}

export interface TripsEcoRatingViewModel {
  ecoRating: number;
  estimatedCo2Surplus: number;
  progress: number;
  dailyRatings: DailyEcoRating[];
}

export interface DailyEcoRating {
  day: string;
  ecoRating?: number;
  estimatedCo2Surplus?: number;
  progress?: number;
}
