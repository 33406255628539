import addMinutes from 'date-fns/addMinutes';
import moment from 'moment-timezone';
import { Timestamp } from '../../pages/Playback/utils/timestamp';

export const getLocalTimestampFromDate = (date: Date, timezone: string): Timestamp => {
  const utcDate = addMinutes(date, -date.getTimezoneOffset());
  return getLocalTimestampFromUtcDate(utcDate, timezone);
};

export const getLocalTimestampFromUtcDate = (utcDate: Date, timezone: string): Timestamp => {
  const offset = timezone ? moment.tz.zone(timezone).utcOffset(utcDate.valueOf()) : 0;
  const localTimeOffset = Timestamp.fromMinutes(-offset);
  return Timestamp.fromDate(utcDate).subtract(localTimeOffset);
};
