import { type Def, linearGradientDef } from '@nivo/core';
import {
  MAX_SPEED_BASELINE,
  NO_GRADIENT_ID,
  redGradientSpeed,
  SPEEDING_GRADIENT_ID,
  yellowGradientSpeed,
} from '../constants';
import { Timestamp } from '../../../utils/timestamp';
import colors from '../../../../../theme/colors';
import { isUnderOrdersActivity } from '../../../utils/isUnderOrdersActivity';
import type { ActivitySerie, Fill, Gradients, NumberDatum } from '../types';
import type { ActivityViewModel } from '../../../types';

export function generateLineDataForActivities(activities: ActivityViewModel[]): ActivitySerie[] {
  return activities.map((activity, index) => ({
    id: `${activity.type}-${index}`,
    activityType: activity.type,
    data:
      activity?.speedChartData?.map(entry => {
        return {
          x: Timestamp.fromSeconds(entry.timestamp).toDate(),
          y: entry.speed,
        };
      }) || [],
    color: colors.primary[600],
    lineThickness: 1,
    type: 'solid',
    opacity: isUnderOrdersActivity(activity.type) ? 0.3 : 1,
    duration: activity.timestampRange,
    isSpeedLimited: activity.isSpeedLimited,
  }));
}

function getSpeedingGradientOffsetForSerie(serie: ActivitySerie, speedLimit: number): number {
  const maxYValue = getMaxYValue(serie.data);

  if (maxYValue === 0) {
    return -1;
  }

  const offset = (1 - speedLimit / maxYValue) * 100;
  return Math.max(0, offset);
}

export function generateSpeedingGradients(series: ActivitySerie[]): Gradients {
  const overspeedPhases = series.filter(
    serie => serie.data.some(d => d.y > MAX_SPEED_BASELINE) && !isUnderOrdersActivity(serie.activityType)
  );

  const gradients: Def[] = [
    linearGradientDef(NO_GRADIENT_ID, [{ offset: 0, color: 'none', opacity: 0 }]),
    ...overspeedPhases.map(serie => {
      const yellowOffset = getSpeedingGradientOffsetForSerie(serie, yellowGradientSpeed);
      const redOffset = getSpeedingGradientOffsetForSerie(serie, redGradientSpeed);

      return linearGradientDef(`${SPEEDING_GRADIENT_ID}-${serie.id}`, [
        { offset: 0, color: colors.error[300] },
        { offset: redOffset, color: colors.error[300] },
        {
          offset: yellowOffset,
          color: colors.warning[300],
          opacity: 0,
        },
      ]);
    }),
  ];

  const fill: Fill[] = [
    ...overspeedPhases.map(({ id }) => ({
      match: { id },
      id: `${SPEEDING_GRADIENT_ID}-${id}`,
    })),
    { match: '*' as const, id: NO_GRADIENT_ID },
  ];

  return {
    defs: gradients,
    fill,
  };
}

export function getMaxYValue(data: NumberDatum[]): number {
  return Math.max(...data.map(d => d.y));
}
