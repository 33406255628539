import { forwardRef } from 'react';
import { Box, Grid, GridItem, Text, IconButton, Tooltip } from '@chakra-ui/react';
import { type VesselViewModel } from '../../../../types';
import { translate } from '../../../../../../global/translation';
import { ReactComponent as CollapseIcon } from '../../../../../../static/svg/Collapse.svg';
import { ReactComponent as ExpandIcon } from '../../../../../../static/svg/Expand.svg';
import { ReactComponent as RecenterVesselIconActive } from '../../../../../../static/svg/RecenterVesselActive.svg';
import { ReactComponent as RecenterVesselIconInactive } from '../../../../../../static/svg/RecenterVesselInactive.svg';

interface Props {
  targetVessel: VesselViewModel;
  formattedSpeed: string;
  speedType: string;
  handleQuickOperation: () => void;
  className: string;
  toolTipText: string;
  isCollapsed: boolean;
  targetVesselMmsi: string;
  isLockedOnTarget: boolean;
  onTargetLock: (mmsi: string) => void;
}

const QuickAccessHeaderSelected = forwardRef<HTMLDivElement, Props>(
  (
    {
      targetVessel,
      formattedSpeed,
      speedType,
      handleQuickOperation: handleQuickAccessOpen,
      className,
      toolTipText,
      isCollapsed,
      targetVesselMmsi,
      isLockedOnTarget,
      onTargetLock,
    }: Props,
    ref
  ): JSX.Element => {
    return (
      <Box ref={ref} className={className}>
        <Grid templateColumns="2fr 7fr 3fr 2fr" gap={1}>
          <GridItem colSpan={1} className="recenter-vessel-icon">
            <IconButton
              variant={isLockedOnTarget ? 'primary' : 'icon'}
              onClick={() => {
                onTargetLock(isLockedOnTarget ? null : targetVessel?.mmsi);
              }}
              icon={isLockedOnTarget ? <RecenterVesselIconActive /> : <RecenterVesselIconInactive />}
              aria-label={translate('RECENTER_ARIA_DESCRIPTION')}
            />
          </GridItem>

          <GridItem className="quick-access-header-text" colSpan={1}>
            <Text className="text-sm-regular">
              {targetVessel?.name !== undefined ? targetVessel?.name : translate('UNKNOWN')}
            </Text>
            <Text opacity="0.5" className="text-xs-regular">{`MMSI ${targetVesselMmsi}`}</Text>
          </GridItem>

          <GridItem className="quick-access-header-text" colSpan={1}>
            <Tooltip className="tooltip" placement="left" label={toolTipText}>
              <Box>
                <Text className="text-sm-regular">{`${formattedSpeed} ${translate('KNOTS_SHORT')}`}</Text>
                <Text className="text-xs-regular">{speedType}</Text>
              </Box>
            </Tooltip>
          </GridItem>

          <GridItem colSpan={1} className="quick-access-header-icon">
            <IconButton
              variant="icon"
              onClick={handleQuickAccessOpen}
              icon={isCollapsed ? <CollapseIcon /> : <ExpandIcon />}
              aria-label={translate('COLLAPSE_ARIA_DESCRIPTION')}
            />
          </GridItem>
        </Grid>
      </Box>
    );
  }
);

export default QuickAccessHeaderSelected;
