import { useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { Modal, type ModalTransition, ModalsEvent, type ModalsStateMachine } from './types';

export const useModalsState: (initialState?: Modal) => ModalsStateMachine = (initialState = Modal.QuickAccess) => {
  const [currentModal, setCurrentModal] = useState(initialState);
  const transitions: ModalTransition[] = [
    { from: Modal.None, event: ModalsEvent.OpenQuickAccess, to: Modal.QuickAccess },
    { from: Modal.QuickAccess, event: ModalsEvent.CloseQuickAccess, to: Modal.None },
    { from: Modal.PickTime, event: ModalsEvent.CloseQuickAccess, to: Modal.None },
    { from: Modal.VesselSearch, event: ModalsEvent.CloseQuickAccess, to: Modal.None },
    { from: Modal.QuickAccess, event: ModalsEvent.OpenPickTime, to: Modal.PickTime },
    { from: Modal.QuickAccess, event: ModalsEvent.OpenVesselSearch, to: Modal.VesselSearch },
    { from: Modal.PickTime, event: ModalsEvent.BackFromPickTime, to: Modal.QuickAccess },
    { from: Modal.PickTime, event: ModalsEvent.ClosePickTime, to: Modal.QuickAccess },
    { from: Modal.VesselSearch, event: ModalsEvent.BackFromVesselSearch, to: Modal.QuickAccess },
    { from: Modal.VesselSearch, event: ModalsEvent.CloseVesselSearch, to: Modal.QuickAccess },
  ];

  const modalDisclosures: Record<keyof typeof Modal, any> = {
    None: useDisclosure(),
    PickTime: useDisclosure(),
    QuickAccess: useDisclosure({
      isOpen: currentModal === Modal.QuickAccess,
    }),
    VesselSearch: useDisclosure(),
  };

  const close = (...modals: Modal[]): void => {
    modals.forEach(m => {
      modalDisclosures[m].onClose();
    });
  };

  const open = (...modals: Modal[]): void => {
    modals.forEach(m => {
      modalDisclosures[m].onOpen();
    });
  };

  const eventHandlers: Record<keyof typeof ModalsEvent, () => void> = {
    OpenQuickAccess: () => {
      open(Modal.QuickAccess);
    },
    CloseQuickAccess: () => {
      close(Modal.QuickAccess, Modal.PickTime, Modal.VesselSearch);
    },
    BackFromPickTime: () => {
      close(Modal.PickTime);
      open(Modal.QuickAccess);
    },
    BackFromVesselSearch: () => {
      close(Modal.VesselSearch);
      open(Modal.QuickAccess);
    },
    OpenPickTime: () => {
      close(Modal.QuickAccess);
      open(Modal.PickTime);
    },
    OpenVesselSearch: () => {
      close(Modal.QuickAccess);
      open(Modal.VesselSearch);
    },
    ClosePickTime: () => {
      close(Modal.PickTime);
      open(Modal.QuickAccess);
    },
    CloseVesselSearch: () => {
      close(Modal.VesselSearch);
      open(Modal.QuickAccess);
    },
  };

  const canTransit = (event: ModalsEvent): boolean => {
    return transitions.some(t => t.event === event && t.from === currentModal);
  };

  const transit = (event: ModalsEvent): void => {
    if (!canTransit(event)) {
      return;
    }

    const transition = transitions.find(t => t.event === event);
    const handler = eventHandlers[event];
    handler();
    setCurrentModal(transition.to);
  };

  return {
    modalStates: {
      None: { isOpen: modalDisclosures[Modal.None].isOpen },
      PickTime: { isOpen: modalDisclosures[Modal.PickTime].isOpen },
      QuickAccess: { isOpen: modalDisclosures[Modal.QuickAccess].isOpen },
      VesselSearch: { isOpen: modalDisclosures[Modal.VesselSearch].isOpen },
    },
    dispatch: transit,
  };
};
