export enum ModalsEvent {
  OpenQuickAccess = 'OpenQuickAccess',
  CloseQuickAccess = 'CloseQuickAccess',
  OpenVesselSearch = 'OpenVesselSearch',
  BackFromVesselSearch = 'BackFromVesselSearch',
  CloseVesselSearch = 'CloseVesselSearch',
  OpenPickTime = 'OpenPickTime',
  BackFromPickTime = 'BackFromPickTime',
  ClosePickTime = 'ClosePickTime',
}

export enum Modal {
  None = 'None',
  QuickAccess = 'QuickAccess',
  VesselSearch = 'VesselSearch',
  PickTime = 'PickTime',
}

export interface ModalState {
  isOpen: boolean;
}

export interface ModalTransition {
  from: Modal;
  to: Modal;
  event: ModalsEvent;
}

export interface ModalsStateMachine {
  modalStates: Record<keyof typeof Modal, ModalState>;
  dispatch: (event: ModalsEvent) => void;
}
