import { type MapContext } from '../pages/Playback/components/Map/types';
import { type Timestamp } from '../pages/Playback/utils/timestamp';
import { type TripsContext } from '../pages/Trips/types';

export enum TripType {
  Sailing = 'Sailing',
  Berthing = 'Berthing',
  Repositioning = 'Repositioning',
  Shifting = 'Shifting',
  Resting = 'Resting',
  Uncertain = 'Uncertain job',
}

export enum ActivityType {
  Reposition = 'Reposition',
  Resting = 'Resting',
  Mobilization = 'Mobilization',
  Waiting = 'Waiting',
  Berthing = 'Berthing',
  Shifting = 'Shifting',
  Sailing = 'Sailing',
  Demobilization = 'Demobilization',
  Uncertain = 'Uncertain',
  Redeployment = 'Redeployment',
  SignalLost = 'SignalLost',
  Loading = 'Loading',
}

export enum SpeedType {
  SpeedThroughWater = 'SpeedThroughWater',
  SpeedOverGround = 'SpeedOverGround',
}

export type TimestampKey = number;
export type MmsiKey = string;

export interface Coordinates {
  lat: number;
  lon: number;
}

export interface Location {
  coordinates: Coordinates;
}

export interface TimestampModel {
  valueInMilliseconds: number;
}

export interface Range<T> {
  from: T;
  to: T;
}

export interface TimestampModelRange extends Range<TimestampModel> {}

export interface TimestampRange extends Range<Timestamp> {}

export interface DateRange extends Range<Date> {}

export interface NumberRange extends Range<number> {}

export interface Speed {
  value: number;
  type: SpeedType;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum CONFIG_CODES {
  REACT_APP_MAPBOX_STYLE,
  REACT_APP_MAPBOX_SATELLITE_STYLE,
  REACT_APP_MAPBOX_NAUTICAL_STYLE,
  REACT_APP_API_HOST,
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_AUTHORITY,
  REACT_APP_AUTH_REDIRECT_URI,
  DISABLE_ESLINT_PLUGIN,
  ESLINT_NO_DEV_ERRORS,
  REACT_APP_SCRUBBER,
  MAX_ARROW_DISTANCE_FROM_POINT_IN_PIXELS,
}

export enum Page {
  Playback = 'Playback',
  Liveview = 'Liveview',
  Unknown = 'Unknown',
}

export enum Feature {
  InformationPanel = 'InformationPanel',
  Scrubber = 'Scrubber',
  Map = 'Map',
  FilterPanel = 'FilterPanel',
  Browser = 'Browser',
  Timeline = 'Timeline',
  QuickAccessPanel = 'QuickAccessPanel',
  TripsHistoryPanel = 'TripsHistoryPanel',
}

export enum EventType {
  UserAction = 'UserAction',
  AppEvent = 'AppEvent',
}

export enum Event {
  FilteredVessel = 'FilteredVessel',
  NoTripsFound = 'NoTripsFound',
  FilteredTripType = 'FilteredTripType',
  FilteredDate = 'FilteredDate',
  FilteredCompetitors = 'FilteredCompetitors',
  FiltersReset = 'FiltersReset',
  ReplayClosed = 'ReplayClosed',
  Exception = 'Exception',
  WrongInformation = 'WrongInformation',
  PanMap = 'PanMap',
  ZoomMap = 'ZoomMap',
  VesselHovered = 'VesselHovered',
  VesselTargetted = 'VesselTargetted',
  VesselUntargeted = 'VesselUntargeted',
  PlaybackStarted = 'PlaybackStarted',
  PlaybackPaused = 'PlaybackPaused',
  TimelineScrubbed = 'TimelineScrubbed',
  TimelineZoomed = 'TimelineZoomed',
  TimelineSpeedChanged = 'TimelineSpeedChanged',
  Entered3dView = 'Entered3dView',
  Exited3dView = 'Exited3dView',
  NPSSurvey = 'NPSSurvey',
  TimelineMarkerHovered = 'TimelineMarkerHovered',
  ShareLink = 'ShareLink',
  CopyLink = 'CopyLink',
  VesselSearchOpened = 'VesselSearchOpened',
  VesselSearchClosed = 'VesselSearchClosed',
  VesselSearchGotResults = 'VesselSearchGotResults',
  VesselSearchGotNoResults = 'VesselSearchGotNoResults',
  VesselFoundAndSelected = 'VesselFoundAndSelected',
  VesselTracksEnabled = 'VesselTracksEnabled',
  VesselTracksDisabled = 'VesselTracksDisabled',
  EnableVesselNames = 'EnableVesselNames',
  DisableVesselNames = 'DisableVesselNames',
  VesselTrackHovered = 'VesselTrackHovered',
  ActivityMarkerHovered = 'ActivityMarkerHovered',
  QuickAccessPanelClosed = 'QuickAccessPanelClosed',
  QuickAccessPanelOpened = 'QuickAccessPanelOpened',
  PickTimeOpened = 'PickTimeOpened',
  BackFromPickTimeClicked = 'BackFromPickTimeClicked',
  BackFromVesselSearchClicked = 'BackFromVesselSearchClicked',
  RedirectedToPlaybackFromPickTime = 'RedirectedToPlaybackFromPickTime',
  OperationSelected = 'OperationSelected',
  TripsHistoryPanelOpened = 'TripsHistoryPanelOpened',
  TripsHistoryPanelClosed = 'TripsHistoryPanelClosed',
  TripsPanelTripHovered = 'TripsPanelTripHovered',
  TripsPanelTripClicked = 'TripsPanelTripClicked',
  TripsPanelScrolledToEnd = 'TripsPanelScrolledToEnd',
  DisableQuiverPlot = 'DisableQuiverPlot',
  EnableQuiverPlot = 'EnableQuiverPlot',
  TidalDiamondHovered = 'TidalDiamondHovered',
  TidalArrowClicked = 'TidalArrowClicked',
  DisableNauticalMap = 'DisableNauticalMap',
  EnableNauticalMap = 'EnableNauticalMap',
}

export interface EventFilter {
  startDate: string;
  endDate: string;
  vesselName: string;
  port: string;
  tripType: string[];
  jobType: string;
}

export enum FilteredVesselEvent {
  KeyBoardEnter,
  MouseSelection,
  ClearTextInput,
}

export interface AnalyticsEvent {
  name: string;
  parent: Feature | Page;
  page: Page;
  [key: string]: any;
}

export enum NPSSurveyEvent {
  Close,
  ClickNumberAndClose,
  Submit,
}

export interface GlobalContext {
  trips: TripsContext;
  map: MapContext;
}

export enum VesselTargetSource {
  ClickOnVessel = 'ClickOnVessel',
  ClickOnRecenterButton = 'ClickOnRecenterButton',
}

export enum ToasterVariant {
  Success = 'customSuccess',
  Warning = 'customWarning',
  Error = 'customError',
  Loading = 'customLoading',
}

export enum ShareLinkOperation {
  OpenLink = 'OpenLink',
  CloseLink = 'CloseLink',
}

export enum CopyLinkOperation {
  CopyDefaultUrl = 'CopyDefaultUrl',
  CopyTimeSpecificUrl = 'CopyTimeSpecificUrl',
}

export interface NavigationParams {
  port: string;
  timestamp?: Timestamp;
  mmsi?: string;
}
